import { useCallback } from 'react';
import { addAddressesToAccountBasedHistoryListeners } from 'cb-wallet-data/AddressHistory/utils/addAddressesToAccountBasedHistoryListeners';
import { PossibleAccountBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { createLastSyncedTxHashLookupFn } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { useGetTxHistoryVersion } from 'cb-wallet-data/stores/Transactions/hooks/useGetTxHistoryVersion';
import { ImportableAddress } from 'cb-wallet-data/stores/Wallets/types/ImportableAddress';
import { importAddressByBlockchain } from 'cb-wallet-data/stores/Wallets/utils/importAddressesByBlockchain';

import { useCreateAccountAndWalletGroupForDapp } from './useCreateAccountAndWalletGroupForDapp';

export type ProcessAccountBasedBlockchainAddressParams = {
  provider: string;
  chain: PossibleAccountBlockchainSymbol;
  address: string;
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
  providerUserId?: string;
};

type UseProcessAccountBasedBlockchainAddressProps = { excludeDefiAssets?: boolean };

export function useProcessAccountBasedBlockchainAddress(
  { excludeDefiAssets = false }: UseProcessAccountBasedBlockchainAddressProps = {
    excludeDefiAssets: false,
  },
) {
  const isEthereumNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_eth'),
  );
  const isSolanaNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_sol'),
  );
  const isSolanaDASAPIKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_das_api_blockchain_sol'),
  );
  const getTxHistoryVersion = useGetTxHistoryVersion();
  const isWebsocketNudgeEnabled = useIsFeatureEnabled('solana_websocket_nudges');

  const createAccountAndWalletGroupForDapp = useCreateAccountAndWalletGroupForDapp();

  return useCallback(
    async function processAccountBasedAddress({
      provider,
      chain,
      address,
      skipBalanceRefresh,
      skipTransactionRefresh,
      providerUserId,
    }: ProcessAccountBasedBlockchainAddressParams) {
      const record = await createAccountAndWalletGroupForDapp({
        provider,
        chain,
        address,
        providerUserId,
      });
      const importAddress = importAddressByBlockchain[chain];

      if (!record || !importAddress) {
        cbReportError({
          error: new Error('Failed to create account'),
          context: 'dapp-account-creation',
          metadata: {
            provider,
            chain,
            address,
          },
          isHandled: false,
          severity: 'error',
        });
        return;
      }

      const { account: accountRecord, duplicate } = record;

      if (duplicate) {
        return;
      }

      const importableAddress: ImportableAddress = {
        index: 0n,
        address,
        accountId: accountRecord.id,
      };

      // Create the address and wallet records
      await importAddress(importableAddress);

      const getLastSyncedTxHashByNetwork = await createLastSyncedTxHashLookupFn();

      addAddressesToAccountBasedHistoryListeners({
        walletIndex: 0n,
        accountId: accountRecord.id,
        isEthereumNudgeKilled,
        isSolanaNudgeKilled,
        isSolanaDASAPIKilled,
        isWebsocketNudgeKilled: !isWebsocketNudgeEnabled,
        getTxHistoryVersion,
        getLastSyncedTxHashByNetwork,
        skipBalanceRefresh,
        skipTransactionRefresh,
        excludeDefiAssets,
      });
    },
    [
      createAccountAndWalletGroupForDapp,
      isEthereumNudgeKilled,
      isSolanaNudgeKilled,
      isSolanaDASAPIKilled,
      isWebsocketNudgeEnabled,
      getTxHistoryVersion,
      excludeDefiAssets,
    ],
  );
}
