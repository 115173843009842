export enum DrawerName {
  Swap = 'swap',
  Stake = 'stake',
  Send = 'send',
  Buy = 'buy',
  Receive = 'receive',
  Cashout = 'cashout',
}

export type SwapDrawerParams = {
  assetId?: string;
};

export type StakeDrawerParams = {
  assetId?: string;
  currencyCode?: string;
  targetCurrencyCode?: string;
  chainId?: string;
  primaryAddress?: string;
};

export type SendDrawerParams = unknown;
export type BuyDrawerParams = unknown;

export type OptionalParamsByDrawer = {
  [DrawerName.Swap]: SwapDrawerParams;
  [DrawerName.Stake]: StakeDrawerParams;
  [DrawerName.Send]: SendDrawerParams;
  [DrawerName.Buy]: BuyDrawerParams;
};
