import { memo, ReactNode } from 'react';
import { useTriggerAppStart } from 'cb-wallet-analytics/lifecycle/Lifecycle';
import { useAddressHistory } from 'cb-wallet-data/AddressHistory/hooks/useAddressHistory';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useSyncIsFeatureEnabledStore } from 'cb-wallet-data/FeatureManager/hooks/useSyncIsFeatureEnabledStore';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useSyncProviderLocator } from 'cb-wallet-data/ProviderLocator/hooks/useSyncProviderLocator';
import { useDetectMissingMultiAccountEntities } from 'cb-wallet-data/stores/Accounts/hooks/useDetectMissingMultiAccountEntities';
import { useClearCuratedAssetsTable } from 'cb-wallet-data/stores/AssetManagement/hooks/useClearCuratedAssetsTable';
import { useSyncCuratedAssetSettings } from 'cb-wallet-data/stores/AssetManagement/hooks/useSyncCuratedAssetSettings';
import { useSyncAssetSettings } from 'cb-wallet-data/stores/AssetManagement/hooks/useSyncUserAssetSettings';
import { useRefreshExchangeRates } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { usePaginatedTransactions } from 'cb-wallet-data/stores/Transactions/hooks/usePaginatedTransactions';
import { useResubmitPendingTransactions } from 'cb-wallet-data/stores/Transactions/hooks/useResubmitPendingTransactions';
import { useSyncTransactionHistory } from 'cb-wallet-data/stores/Transactions/hooks/useSyncTransactionHistory';
import { useAddBlockchainAddresses } from 'cb-wallet-data/stores/User/hooks/useAddBlockchainAddresses';
import { useWalletGroupIds } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { useCacheWalletAtoms } from 'cb-wallet-data/stores/Wallets/hooks/useCacheWalletAtoms';
import { useSetWallets } from 'cb-wallet-data/stores/Wallets/hooks/useSetWallets';

import { SprigProvider } from ':dapp/components/Sprig/SprigProvider';
import { useLogPageViewed } from ':dapp/hooks/useLogPageViewed';
import { useSetAmplitudeGlobalEventProperties } from ':dapp/hooks/useSetAmplitudeGlobalEventProperties';

import { AppFallback } from './components/AppFallback/AppFallback';
import { useExternalLinkModal } from './components/ExternalLinkModal/useExternalLinkModal';
import { GlobalDrawer } from './components/GlobalDrawer/GlobalDrawer';
import { UnderMaintenance } from './components/UnderMaintenance/UnderMaintenance';
import { useAutoConnectCoinbaseBrowser } from './connection/hooks/useAutoConnectCoinbaseBrowser';
import { useSyncEthereumEventListeners } from './connection/hooks/useSyncEthereumEventListeners';
import { useBalanceUpdateWithCallbacks } from './hooks/useBalanceUpdateWithCallbacks';
import { useInitializeDatabase } from './hooks/useInitializeDatabase';
import { useSkipTransactionRefresh } from './hooks/useSkipTransactionRefresh';
import { AppLayout } from './AppLayout';

type AppProps = {
  children: ReactNode;
};

/**
 * Hooks which we don't want to suspend but only want to run once
 * we have loaded wallets from the database
 */
const DataCacher = memo(function DataCacher() {
  const walletGroupIds = useWalletGroupIds();
  useCacheWalletAtoms(walletGroupIds);
  return null;
});

// Some of these hooks suspend but we can safely render the logged in app
// while they are updating our data layer in the background
function SuspensefulDataHooks() {
  const walletGroupIds = useWalletGroupIds();
  const skipTransactionRefresh = useSkipTransactionRefresh();

  useRefreshExchangeRates(walletGroupIds);
  // This hook is debounced so we want to use the same instance for both
  // transaction nudges and polling for other chains
  useAddBlockchainAddresses();
  useAddressHistory({
    skipTransactionRefresh,
    excludeDefiAssets: true,
  });
  useSyncTransactionHistory();
  useSyncAssetSettings(false); // dapp provider accounts don't support multiwallet
  useClearCuratedAssetsTable();
  useSyncCuratedAssetSettings();
  useResubmitPendingTransactions();
  useDetectMissingMultiAccountEntities();
  useBalanceUpdateWithCallbacks();
  // dapp specific datahooks
  useSyncProviderLocator();
  return null;
}

// TODO: setup GenericAppFallback with repair script
// https://jira.coinbase-corp.com/browse/WALL-26441
export function App({ children }: AppProps) {
  const { databaseReady } = useInitializeDatabase();
  const shouldKillWalletDapp = !useIsFeatureEnabled('wallet_dapp');
  const { ensureMinimumTxsForActiveWalletGroup } = usePaginatedTransactions();
  useSetWallets();
  useSyncIsFeatureEnabledStore();

  if (shouldKillWalletDapp) {
    return <UnderMaintenance />;
  }

  if (!databaseReady) {
    return <AppFallback source="app" />;
  }

  ensureMinimumTxsForActiveWalletGroup({ activeWalletNotSupported: true });
  return <AppContent>{children}</AppContent>;
}

export function AppContent({ children }: AppProps) {
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();

  useTriggerAppStart();
  useLogPageViewed();

  useSyncEthereumEventListeners();
  useAutoConnectCoinbaseBrowser();
  useSetAmplitudeGlobalEventProperties();

  useExternalLinkModal();

  return (
    <GlobalDrawer>
      <SprigProvider>
        <AppLayout>
          <DataCacher />
          {hasAccountsAndWalletGroups && <SuspensefulDataHooks />}
          {children}
        </AppLayout>
        <GlobalDrawer.Content />
      </SprigProvider>
    </GlobalDrawer>
  );
}
