import { useMemo } from 'react';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { useFetchDefiProtocolPortfolio } from 'cb-wallet-data/stores/DefiPortfolio/api/useFetchDefiProtocolPortfolio';
import { useParseProtocolPortfolioResponse } from 'cb-wallet-data/stores/DefiPortfolio/hooks/useParseProtocolPortfolioResponse/useParseProtocolPortfolioResponse';
import { useTrackRatesForProtocolPositions } from 'cb-wallet-data/stores/DefiPortfolio/hooks/useTrackRatesForProtocolPositions';

import { useFilterDefiProtocolPositions } from './useFilterDefiProtocolPositions';

export type UseDefiProtocolPositionsProps = {
  selectedNetworkFilters?: string[];
  selectedWalletFilters?: string[];
  enabled?: boolean;
};

export function useDefiProtocolPositions({
  selectedNetworkFilters,
  selectedWalletFilters,
  enabled = true,
}: UseDefiProtocolPositionsProps) {
  const accounts = useAccounts();
  const evmAddresses = useMemo(() => {
    return accounts
      .filter((account) => account.primaryAddressChain === ETHEREUM_SYMBOL)
      .map((account) => account.primaryAddress);
  }, [accounts]);

  const { response, error, isLoading } = useFetchDefiProtocolPortfolio({ evmAddresses, enabled });
  const protocolPositions = useParseProtocolPortfolioResponse({ response });

  // We need to add the asset to the exchange rate tracking list
  // or else it will fail to calculate the fiat value
  useTrackRatesForProtocolPositions({ protocolPositions });

  const filteredPositions = useFilterDefiProtocolPositions({
    selectedNetworkFilters,
    selectedWalletFilters,
    protocolPositions,
  });

  return useMemo(() => {
    return {
      protocolPositions: filteredPositions,
      error,
      isLoading,
    };
  }, [error, filteredPositions, isLoading]);
}
