import { ERC20DMO } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/ERC20';
import { EthereumSignedTxDMO } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { SolanaSignedTxDMO } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaSignedTx';
import { SPLDMO } from 'cb-wallet-data/chains/AccountBased/Solana/models/SPL';
import { UTXOSignedTxDMO } from 'cb-wallet-data/chains/UTXO/models/UTXOSignedTx';
import { AccountDMO } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AddressDMO } from 'cb-wallet-data/stores/Addresses/models/Address';
import { CuratedAssetSettingDMO } from 'cb-wallet-data/stores/AssetManagement/models/CuratedAssetSetting';
import { UserCollectibleSettingDMO } from 'cb-wallet-data/stores/AssetManagement/models/UserCollectibleSetting';
import { UserWalletSettingDMO } from 'cb-wallet-data/stores/AssetManagement/models/UserWalletSetting';
import { LastSyncedTxHashDMO } from 'cb-wallet-data/stores/LastSyncedTxHash/LastSyncedTxHash';
import { ConversationPreviewDMO } from 'cb-wallet-data/stores/Messaging/models/ConversationPreview';
import { SecretsDMO } from 'cb-wallet-data/stores/Secrets/models/Secrets';
import { SwapAssetStatsDMO } from 'cb-wallet-data/stores/Swap/models/SwapAssetStats';
import { TxOrUserOpDMO } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { WalletGroupDMO } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { WalletDMO } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { Connection, DataSourceOptions, ObjectLiteral, Repository } from '@cbhq/typeorm';

// shared interface
export type IDatabase = {
  openDatabase: (options?: DataSourceOptions) => Promise<Connection>;
  deleteDatabase: () => Promise<void>;
  tryGetRepository: <Entity extends ObjectLiteral>(name: string) => Repository<Entity>;
};

export type Persistable<T> = {
  asDMO: T;
};

// instance and model config
export const ENTITY_SCHEMAS = [
  AccountDMO,
  AddressDMO,
  CuratedAssetSettingDMO,
  ERC20DMO,
  EthereumSignedTxDMO,
  LastSyncedTxHashDMO,
  SolanaSignedTxDMO,
  SPLDMO,
  ConversationPreviewDMO,
  SecretsDMO,
  SwapAssetStatsDMO,
  TxOrUserOpDMO,
  UserCollectibleSettingDMO,
  UserWalletSettingDMO,
  UTXOSignedTxDMO,
  WalletDMO,
  WalletGroupDMO,
];
