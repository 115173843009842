import { useCallback } from 'react';
import { checksumEthereumAddress } from 'cb-wallet-data/chains/AccountBased/Ethereum/utils/isValidAddress';
import { createNonce, createSession } from 'cb-wallet-data/stores/Authentication/api';
import { useClearAuthTokensAndUnsetUser } from 'cb-wallet-data/stores/Authentication/hooks/useClearAuthTokensAndUnsetUser';
import { generateWeb3Signature } from 'cb-wallet-data/stores/Authentication/signatures/generateWeb3Signature';
import { setAuthTokensImperative } from 'cb-wallet-data/stores/Authentication/tokens/state';
import { useSetAuthTokens } from 'cb-wallet-data/stores/Authentication/tokens/useSetAuthTokens';
import { fetchUserImperatively } from 'cb-wallet-data/stores/User/hooks/useUser';

export const SIGNATURE_HOST = 'api.coinbasewallet.com';
export const CREATE_USER_HEADER = 'Coinbase Wallet Registration';

export function useCreateSessionForDapp() {
  const setAuthTokens = useSetAuthTokens();
  const clearAuthTokenAndUnsetUser = useClearAuthTokensAndUnsetUser();

  return useCallback(
    async (accountId: string, address: string) => {
      try {
        const nonce = await createNonce();

        const { signature } = await generateWeb3Signature({
          address: checksumEthereumAddress(address),
          accountId,
          nonce,
          header: CREATE_USER_HEADER,
        });

        const session = await createSession(nonce, [
          {
            address: checksumEthereumAddress(address),
            signature,
            chain: 'eth',
          },
        ]);

        setAuthTokensImperative(session);
        setAuthTokens(session);

        const user = await fetchUserImperatively();
        if (!user) throw new Error('Unable to find user');

        return session;
      } catch (error) {
        clearAuthTokenAndUnsetUser();
        throw error;
      }
    },
    [clearAuthTokenAndUnsetUser, setAuthTokens],
  );
}
