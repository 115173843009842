import { CB_WALLET_API_DEV_URL } from 'cb-wallet-env/env';
import { getApplicationMetadata } from 'cb-wallet-metadata/metadata';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { PlatformName } from '@cbhq/client-analytics';

import {
  CB_AUTH_APIS,
  WALLET_API_URL_MAP,
  WalletApiEnvironment,
  WalletApiMapKey,
} from './constants';
import { getReleaseStage } from './getReleaseStage';

const StoreKeys_baseUrl = new LocalStorageStoreKey<string>('baseUrl');

export function getBaseUrl(walletApi: WalletApiMapKey, forceDevEnvironment?: boolean) {
  const { platform } = getApplicationMetadata();
  const releaseStage = getReleaseStage();

  /* Use dev environment if 
    - forceDevEnvironment is true
    - or if the wallet service is CB_WALLET_API and the base URL is the dev URL 
  */
  const isApiDevEnv =
    forceDevEnvironment ||
    (walletApi === 'CB_WALLET_API' && Store.get(StoreKeys_baseUrl) === CB_WALLET_API_DEV_URL);

  let environment: WalletApiEnvironment = isApiDevEnv ? 'development' : 'production';

  // Pano & SCW localhost proxy for CB authed APIs
  const isLocalhost = releaseStage === 'local';
  if (isLocalhost && platform === PlatformName.web && CB_AUTH_APIS.includes(walletApi)) {
    environment = 'local';
  }

  const baseUrl = WALLET_API_URL_MAP[walletApi][environment];

  return baseUrl;
}
