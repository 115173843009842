// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React from 'react';
import Bugsnag from '@bugsnag/js';
import type { BrowserConfig, Event } from '@bugsnag/js/types';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createErrorReporter } from 'cb-wallet-data/errors/reportError';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import { location } from '@cbhq/client-analytics';

import {
  APP_VERSION,
  BUGSNAG_API_KEY,
  BUGSNAG_ENDPOINT,
  BUGSNAG_SESSIONS_ENDPOINT,
  RELEASE_ENVIRONMENT,
} from ':dapp/config/env';
import { getDeviceId } from ':dapp/utils/deviceId';

const enabled =
  // eslint-disable-next-line no-process-env
  process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD &&
  Boolean(BUGSNAG_API_KEY) &&
  typeof window !== 'undefined';

const METADATA_KEY = 'Metadata';

const config: BrowserConfig = {
  apiKey: BUGSNAG_API_KEY,
  appVersion: APP_VERSION,
  plugins: [new BugsnagPluginReact(React)],
  endpoints: {
    notify: BUGSNAG_ENDPOINT,
    sessions: BUGSNAG_SESSIONS_ENDPOINT,
  },
  releaseStage: RELEASE_ENVIRONMENT,
  onError: (event) => {
    event.addMetadata(METADATA_KEY, {
      pageKey: location.pageKey,
      pagePath: location.pagePath,
    });
  },
  logger: null,
};

export function initializeBugsnag() {
  if (!enabled) return;
  return Bugsnag.start(config);
}

const bugsnagClient = initializeBugsnag();

export const bugsnagErrorReporter = createErrorReporter((errorData, errorHash) => {
  const { error, errorInfo, metadata, context } = errorData;
  const deviceId = getDeviceId();

  const onError = (bugsnagError: Event) => {
    // eslint-disable-next-line no-param-reassign
    bugsnagError.context = context;
    // eslint-disable-next-line no-param-reassign
    bugsnagError.severity = 'error';
    // Bugsnag filtering by context does not work properly so we add a custom
    // context metadata property
    bugsnagError.addMetadata(METADATA_KEY, {
      ...metadata,
      errorHash,
      context,
      ...errorInfo,
    });
    bugsnagError.setUser(deviceId);
  };
  bugsnagClient?.notify(error, onError);
});
