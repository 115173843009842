import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useProcessWalletConnectionResponse } from 'cb-wallet-data/stores/Accounts/hooks/useProcessWalletConnectionResponse';
import { buildWalletConnectionAccountResponse } from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';
import {
  ProcessWalletConnectionResponseError,
  ProcessWalletConnectionResponseErrorType,
} from 'cb-wallet-data/stores/Accounts/types';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

const messages = defineMessages({
  alreadyImported: {
    defaultMessage: "You've already imported this account with another wallet.",
    description: 'Error message when trying to import an account that is already imported',
  },
});

export function useEthereumAccountChangeListener() {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const processWalletConnectionResponse = useProcessWalletConnectionResponse({
    excludeDefiAssets: true,
  });

  return useCallback(
    (provider: string) => async (result: unknown) => {
      if (
        typeof result === 'object' &&
        result !== null &&
        'accounts' in result &&
        Array.isArray(result.accounts)
      ) {
        try {
          await processWalletConnectionResponse({
            provider,
            account: buildWalletConnectionAccountResponse({
              ethAddresses: result.accounts,
            }),
          });
        } catch (error) {
          if (error instanceof ProcessWalletConnectionResponseError) {
            if (error.name === ProcessWalletConnectionResponseErrorType.ADDRESS_ALREADY_IMPORTED) {
              // If the address is already imported, we don't want to show an error
              toast.show(formatMessage(messages.alreadyImported));
            }
          } else {
            cbReportError({
              error: coerceError(error, 'ethereum account change listener'),
              context: 'dapp-account-creation',
              severity: 'error',
              isHandled: false,
            });
          }
        }
      }
    },
    [formatMessage, processWalletConnectionResponse, toast],
  );
}
