import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useToggler } from '@cbhq/cds-common';
import { CellMedia, ListCell } from '@cbhq/cds-web/cells';
import { NavigationIcon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Modal } from '@cbhq/cds-web/overlays';
import { PressableOpacity, ThemeProvider } from '@cbhq/cds-web/system';

import { DrawerName } from ':dapp/components/GlobalDrawer/constants';
import { useLaunchBuyFlow } from ':dapp/hooks/Buy/useLaunchBuyFlow';
import { useIsFeatureEnabledForScw } from ':dapp/hooks/Scw/useIsFeatureEnabledForScw';
import { useIsGlobalSwapEnabled } from ':dapp/hooks/Swap/useIsGlobalSwapEnabled';
import { useHandleGabItemPress } from ':dapp/hooks/useHandleGabItemPress';

const messages = defineMessages({
  buy: {
    defaultMessage: 'Buy',
    description: 'Global buy entry point button label',
  },
  buyDesciption: {
    defaultMessage: 'Buy more crypto',
    description: 'Global buy entry point button description',
  },
  swap: {
    defaultMessage: 'Swap',
    description: 'Global swap entry point button label',
  },
  swapDescription: {
    defaultMessage: 'Swap one coin for another',
    description: 'Global swap entry point button description',
  },
  send: {
    defaultMessage: 'Send',
    description: 'Global send entry point button label',
  },
  sendDescription: {
    defaultMessage: 'Send crypto to another wallet',
    description: 'Global send entry point button description',
  },
  receiveLabel: {
    defaultMessage: 'Receive',
    description: 'Global send entry point button label',
  },
  receiveDescription: {
    defaultMessage: 'Receive crypto in your wallet',
    description: 'Global receive entry point button description',
  },
  cashoutLabel: {
    defaultMessage: 'Cashout',
    description: 'Global cashout entry point button label',
  },
  cashoutDescription: {
    defaultMessage: 'Cashout assets to fiat',
    description: 'Global cashout entry point button description',
  },
});

export function GlobalActionButtonsMobile() {
  const { formatMessage } = useIntl();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();
  const isSCWSendEnabled = useIsFeatureEnabledForScw('send');
  const [isActionModalVisible, { toggle, toggleOff }] = useToggler();

  const isGlobalSwapEnabled = useIsGlobalSwapEnabled();
  const isGlobalSendEnabled = useIsFeatureEnabled('send_blockchain_all');
  const isCashoutsEnabled = useIsFeatureEnabled('cashout_experience');
  const { launchBuyFlow, isBuyFlowEnabled } = useLaunchBuyFlow();
  const isSendFlowEnabled = isGlobalSendEnabled && isSCWSendEnabled;
  const isReceiveFlowEnabled = useIsFeatureEnabled('receive');

  const handleOpenDrawer = useHandleGabItemPress({ closeModal: toggleOff });

  const handleBuyFlow = useCallback(async () => {
    await launchBuyFlow();
    toggleOff();
  }, [launchBuyFlow, toggleOff]);

  if (!hasAccountsAndWalletGroups) {
    return null;
  }

  return (
    <>
      {(isGlobalSwapEnabled || isBuyFlowEnabled) && (
        <PressableOpacity onPress={toggle} testID="nav-gab-mobile">
          <Box
            width={38}
            height={38}
            alignItems="center"
            justifyContent="center"
            background="primary"
            borderRadius="roundedFull"
          >
            <ThemeProvider spectrum="light">
              <NavigationIcon name="gab" size="m" />
            </ThemeProvider>
          </Box>
        </PressableOpacity>
      )}
      <Modal
        testID="nav-gab-modal"
        dangerouslyDisableResponsiveness
        width={350}
        visible={isActionModalVisible}
        onRequestClose={toggleOff}
        disableOverlayPress={false}
      >
        <VStack borderRadius="roundedLarge">
          {isBuyFlowEnabled && (
            <ListCell
              title={formatMessage(messages.buy)}
              description={formatMessage(messages.buyDesciption)}
              media={<CellMedia type="icon" name="add" />}
              onPress={handleBuyFlow}
              testID="global-buy-button"
            />
          )}
          {isSendFlowEnabled && (
            <ListCell
              title={formatMessage(messages.send)}
              description={formatMessage(messages.sendDescription)}
              media={<CellMedia type="icon" name="arrowUp" />}
              onPress={handleOpenDrawer(DrawerName.Send)}
              testID="global-send-button"
            />
          )}
          {isGlobalSwapEnabled && (
            <ListCell
              title={formatMessage(messages.swap)}
              description={formatMessage(messages.swapDescription)}
              media={<CellMedia type="icon" name="wireTransfer" />}
              onPress={handleOpenDrawer(DrawerName.Swap)}
              testID="global-swap-button"
            />
          )}
          {isReceiveFlowEnabled && (
            <ListCell
              title={formatMessage(messages.receiveLabel)}
              description={formatMessage(messages.receiveDescription)}
              media={<CellMedia type="icon" name="arrowDown" />}
              onPress={handleOpenDrawer(DrawerName.Receive)}
              testID="global-receive-button"
            />
          )}
          {isCashoutsEnabled && (
            <ListCell
              title={formatMessage(messages.cashoutLabel)}
              description={formatMessage(messages.cashoutDescription)}
              media={<CellMedia type="icon" name="cash" />}
              onPress={handleOpenDrawer(DrawerName.Cashout)}
              testID="global-cashout-button"
            />
          )}
        </VStack>
      </Modal>
    </>
  );
}
