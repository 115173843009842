import { RouterPathnameKeys } from ':dapp/utils/RoutesEnum';

// Adding a new page key? See https://github.cbhq.net/wallet/wallet-mobile/blob/master/workspaces/docs/performance/page-keys.md#implementation
// for information on all steps needed.
export const pageKeyRegex: Record<RouterPathnameKeys, RegExp> = {
  assets: /^\/assets$/,
  crypto: /^\/assets\/crypto(.*)/,
  defi: /(^\/assets\/defi|^\/swap$)(.*)/,
  nft: /^\/assets\/nft(.*)/,
  transactions: /^\/assets\/transactions(.*)/,
  creations: /^\/assets\/creations(.*)/,
  testnets: /^\/assets\/testnets(.*)/,
  settings: /^\/settings(.*)/,
  dapps: /^\/apps(.*)/,
  quests: /^\/quests(.*)/,
  ocs: /^\/ocs(.*)/,
  homeroot: /(^\/home(.*)|\/$)/,
  mint: /^\/nft\/mint(.*)/,
};
