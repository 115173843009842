import { logTotalBalanceFetchDuration } from 'cb-wallet-analytics/balances/Balances';
import {
  allDeprecatedBlockchains,
  AllPossibleBlockchainSymbol,
  allPossibleBlockchainSymbols,
  blockchainConfigurations,
  getAllCurrentSupportedBlockchainSymbols,
  isMultiWalletBlockchain,
  StoreKeys_storedBlockchainDeprecationStatus,
} from 'cb-wallet-data/chains/blockchains';
import { isExperimentModeEnabledAtom } from 'cb-wallet-data/hooks/ExperimentMode/state';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { AssetManagementStatus as status } from 'cb-wallet-data/stores/AssetManagement/models/AssetManagementStatus';
import { UserWalletSetting } from 'cb-wallet-data/stores/AssetManagement/models/UserWalletSetting';
import {
  areLowBalanceWalletsHiddenByWalletGroupIdAtom,
  userWalletSettingsAtom,
} from 'cb-wallet-data/stores/AssetManagement/state';
import { exchangeRatesMapSelector } from 'cb-wallet-data/stores/ExchangeRates/state';
import { isVisibleWallet } from 'cb-wallet-data/stores/Wallets/hooks/useGetIsVisibleWallet';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { FiatBalanceByWalletGroup } from 'cb-wallet-data/stores/Wallets/utils/computeFiatBalancesByWalletGroup';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { getTimer } from 'cb-wallet-data/utils/globalTimer';
import { objectAtomLocalStorageEffect } from 'cb-wallet-data/utils/objectAtomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import Decimal from 'decimal.js';
import flatten from 'lodash/flatten';
import { atom, selector, selectorFamily } from 'recoil';

import { Account } from '../Accounts/models/Account';
import { Network } from '../Networks/models/Network';
import {
  areNoQuoteAssetsHiddenExperimentAtom,
  areSmallBalanceWalletsHiddenAtom,
} from '../User/state';
import { walletGroupsAtom } from '../WalletGroups/state';

import { WalletAddress } from './models/WalletAddress';
import { ActiveWalletIndexes } from './types/ActiveWalletIndexes';
import { getAddressesByBlockchainForIds } from './utils/getAddressesByBlockchainForIds';
import {
  WalletIdsToWalletGroupIds,
  WalletsByWalletGroup,
} from './utils/prepareWalletsByWalletGroup';

export type WalletsMap = Record<Wallet['id'], Wallet>;

/**
 * Includes all wallets.
 *
 * Functionally walletsAtom should always be used as the source of truth
 * for the current state of wallets.
 *
 * @see useSetWallets
 */
export const walletsAtom = atom<WalletsMap>({
  key: 'wallets',
  default: {} as WalletsMap,
});

/**
 * Includes all wallets, cached as an array.
 */
export const walletsArraySelector = selector<Wallet[]>({
  key: 'walletsArray',
  get: ({ get }) => Object.values(get(walletsAtom)),
});

export const portfolioWalletGroupIdsAtom = atom<(string | undefined)[]>({
  key: 'portfolioWalletGroupIds',
  default: [],
});

export const walletsByWalletGroupAtom = atom<WalletsByWalletGroup>({
  key: 'walletsByWalletGroupSelector',
  default: {} as WalletsByWalletGroup,
});

export const DEFAULT_FIAT_BALANCE_BY_WALLET_GROUP: FiatBalanceByWalletGroup = {};

export const fiatBalanceByWalletGroupAtom = atom<FiatBalanceByWalletGroup>({
  key: 'fiatBalanceByWalletGroupAtom',
  default: DEFAULT_FIAT_BALANCE_BY_WALLET_GROUP,
});

/**
 * A map of any found wallet to wallet group relationships.
 *
 * Derived in conjunction with walletsByWalletGroup, in order to provide a
 * quick inverse lookup of what wallet group corresponds to a given wallet.
 *
 * This map is iteratively built upon and updated with each computation of
 * walletsByWalletGroup and relationships are never removed during app lifecycle.
 *
 * NOTE: Unlike walletsByWalletGroupAtom, a relationship between a wallet and
 * a wallet group being mapped here doesn't mean a wallet is currently valid or usable.
 *
 * @see useCacheWalletsByWalletGroup
 * @see prepareWalletsByWalletGroup
 */
export const walletIdsToWalletGroupIdsMapAtom = atom<WalletIdsToWalletGroupIds>({
  key: 'walletIdsToWalletGroupIdsMap',
  default: {} as WalletIdsToWalletGroupIds,
});

/**
 * A map of mainnet wallets for supported blockchains, keyed by blockchain symbol.
 */
export const activePrimaryReceiveWalletsByBlockchainAndWalletGroupSelector = selectorFamily<
  Map<AllPossibleBlockchainSymbol, Wallet>,
  string | undefined
>({
  key: 'activePrimaryReceiveWalletsByBlockchain',
  get: (id: string | undefined) =>
    function getActivePrimaryWalletsByBlockchain({ get }) {
      if (!id) {
        return new Map();
      }

      const activeWallets = get(walletsByWalletGroupAtom)?.[id] ?? [];
      const SupportedBlockchain = getAllCurrentSupportedBlockchainSymbols();

      const sortedPrimaryReceiveWalletEntries = activeWallets.reduce<
        [AllPossibleBlockchainSymbol, Wallet][]
      >(function reduceActivePrimaryWalletsByBlockchain(acc, wallet) {
        const symbol = wallet.blockchain.rawValue as AllPossibleBlockchainSymbol;
        const isWalletBlockchainSupported = SupportedBlockchain.includes(symbol);
        const { currencyCode, networkSetting } = blockchainConfigurations[symbol];
        const mainnetNetwork = networkSetting.defaultMainnet.network;

        const isMainnetWallet =
          CurrencyCode.isEqual(wallet.currencyCode, currencyCode) &&
          Network.isEqual(wallet.network, mainnetNetwork) &&
          !wallet.contractAddress;

        if (isWalletBlockchainSupported && isMainnetWallet) {
          const index = SupportedBlockchain.indexOf(symbol); // sorted to match SupportedBlockchain arr
          acc[index] = [symbol, wallet];
        }

        return acc;
      }, []);

      return sortedPrimaryReceiveWalletEntries.reduce<Map<AllPossibleBlockchainSymbol, Wallet>>(
        function reduceSortedPrimaryReceiveWalletEntriesToMap(acc, [symbol, wallet]) {
          acc.set(symbol, wallet);
          return acc;
        },
        new Map(),
      );
    },
});

/**
 * A map of primary addresses for supported blockchains, keyed by blockchain symbol.
 */
export const activePrimaryReceiveAddressByBlockchainAndWalletGroupSelector = selectorFamily<
  Map<AllPossibleBlockchainSymbol, WalletAddress>,
  string | undefined
>({
  key: 'activePrimaryReceiveAddressByBlockchain',
  get: (id: string | undefined) =>
    function getActivePrimaryAddressByBlockchain({ get }) {
      const walletsByBlockchain = get(
        activePrimaryReceiveWalletsByBlockchainAndWalletGroupSelector(id),
      );
      const addressesByBlockchain = new Map();

      for (const [symbol, wallet] of walletsByBlockchain) {
        const address = wallet.addresses.find((a) => a.address === wallet?.primaryAddress);
        addressesByBlockchain.set(symbol, address);
      }

      return addressesByBlockchain;
    },
});

/**
 * A map of primary addresses for supported blockchains, keyed by blockchain symbol.
 */
export const activePrimaryReceiveAddressesByBlockchainAndWalletGroupSelector = selectorFamily<
  Map<string, Map<AllPossibleBlockchainSymbol, WalletAddress>>,
  string
>({
  key: 'activePrimaryReceiveAddressesByBlockchain',
  get: (idsStr: string) =>
    function getActivePrimaryAddressesByBlockchain({ get }) {
      return getAddressesByBlockchainForIds(idsStr, function getWalletsById(id: string) {
        return get(activePrimaryReceiveWalletsByBlockchainAndWalletGroupSelector(id));
      });
    },
});

export const syncableWalletsSelector = selector({
  key: 'syncableWallets',
  get: function getSyncableWallets({ get }) {
    const walletGroupIds = Array.from(get(portfolioWalletsByWalletGroupAtom)?.keys() ?? []);
    if (walletGroupIds.length === 0) {
      return [];
    }

    return Object.values(
      walletGroupIds.reduce(function reducePortfolioWalletsToSyncableWallets(
        acc,
        walletGroupId: string,
      ) {
        const wallets = get(walletsByWalletGroupAtom)?.[walletGroupId] ?? [];
        wallets.forEach(function reduceActiveWalletsToSyncableWallets(wallet: Wallet) {
          if (!wallet.canShowFullTxHistory()) return;

          const networkKey = `${wallet.blockchain.rawValue}/${wallet.network.rawValue}/${walletGroupId}`;
          acc[networkKey] = wallet;
        });
        return acc;
      },
      {} as Record<string, Wallet>),
    );
  },
});

export const isSpamScoreEnabledAtom = atom({
  key: 'spamScoreEnabledAtom',
  default: false,
});

export const visiblePortfolioWalletsByWalletGroupSelector = selector({
  key: 'visiblePortfolioWalletsByWalletGroup',
  get: function getVisiblePortfolioWallets({ get }) {
    const portfolioWalletsByWalletGroup = get(portfolioWalletsByWalletGroupAtom);
    const exchangeRatesMap = get(exchangeRatesMapSelector);
    const isExperimentModeEnabled = get(isExperimentModeEnabledAtom);

    const areLowBalanceWalletsHiddenByWalletGroupId = get(
      areLowBalanceWalletsHiddenByWalletGroupIdAtom,
    );

    const userWalletSettings = get(userWalletSettingsAtom);
    const areSmallBalanceWalletsHidden = get(areSmallBalanceWalletsHiddenAtom);
    const areNoQuoteAssetsHidden =
      get(areNoQuoteAssetsHiddenExperimentAtom) && areSmallBalanceWalletsHidden; // `areSmallBalanceWalletsHidden` is the settings flag set by user, so this must also be enabled too
    const isSpamScoreEnabled = get(isSpamScoreEnabledAtom);

    const visiblePortfolioWalletsByWalletGroupMap = new Map<string, Wallet[]>();

    if (!portfolioWalletsByWalletGroup) {
      return new Map<string, Wallet[]>();
    }

    Array.from(portfolioWalletsByWalletGroup.keys()).forEach(function visibleWalletsByWalletGroup(
      id,
    ) {
      const areLowBalanceWalletsHidden = areLowBalanceWalletsHiddenByWalletGroupId[id];
      const wallets = portfolioWalletsByWalletGroup.get(id) ?? [];

      const filteredWallets = wallets.filter((wallet) =>
        isVisibleWallet(wallet, {
          exchangeRatesMap,
          userWalletSettings,
          isExperimentModeEnabled,
          areLowBalanceWalletsHidden,
          areSmallBalanceWalletsHidden,
          isSpamScoreEnabled,
          areNoQuoteAssetsHidden,
        }),
      );

      visiblePortfolioWalletsByWalletGroupMap.set(id, filteredWallets);
    });

    return visiblePortfolioWalletsByWalletGroupMap;
  },
});

/**
 * SelectorFamily to return the total fiat balance for a given account
 *
 */

export const totalVisibleFiatBalanceSelector = selector({
  key: 'totalVisibleFiatBalanceSelector',
  get: function getTotalVisibleFiatBalance({ get }) {
    const fiatBalancesByWalletGroup = get(fiatBalanceByWalletGroupAtom);
    const walletGroups = get(walletGroupsAtom);

    const totalVisibleFiatBalance = walletGroups.reduce(function totalVisibleFiatBalance(
      acc,
      walletGroup,
    ) {
      if (walletGroup.isHidden) return acc;
      const walletGroupBalance = fiatBalancesByWalletGroup[walletGroup.id];
      return acc.add(walletGroupBalance || new Decimal(0));
    },
    new Decimal(0));

    return totalVisibleFiatBalance;
  },
});

// Count of user-defined spam wallets
export const totalSpamScoreWalletsSelector = selector({
  key: 'totalSpamScoreWallets',
  get: function getSpamWallets({ get }) {
    const portfolioWalletsByWalletGroup = get(portfolioWalletsByWalletGroupAtom);
    const userWalletSettings = get(userWalletSettingsAtom);

    if (!portfolioWalletsByWalletGroup) {
      return 0;
    }

    return Array.from(portfolioWalletsByWalletGroup.keys()).reduce(
      function totalSpamScoreForPortfolioWalletsByWalletGroup(acc, walletGroupId) {
        const portfolioWallets = portfolioWalletsByWalletGroup.get(walletGroupId) ?? [];
        const spamWallets =
          portfolioWallets?.filter(function isWalletSpam(wallet) {
            const userWalletSettingId = UserWalletSetting.generateID(
              wallet.primaryAddress,
              wallet.blockchain,
              wallet.currencyCode,
              wallet.network,
              wallet.contractAddress,
            );
            const userWalletSetting = userWalletSettings[userWalletSettingId];

            return (
              (wallet.isSpam && !userWalletSetting) ||
              (wallet.isSpam && userWalletSetting && userWalletSetting?.status === status.userSpam)
            );
          }) ?? [];

        return acc + spamWallets.length;
      },
      0 as number,
    );
  },
});

// So we do have to sort a potentially large array of wallets each
// time we call usePortfolioWallets
export const portfolioWalletsByWalletGroupAtom = atom<Map<string, Wallet[]> | undefined>({
  key: 'portfolioWallets',
  default: undefined,
});

export type WalletAssetGroup = {
  id: string;
  displayName: string;
  currencyCode: CurrencyCode;
  imageURL?: string;
  decimals: bigint;
  blockchain: Blockchain;
  network: Network;
  contractAddress?: string;
  assetUUID?: string;
  totalBalance: bigint; // aggregated crypto balance, no fiat conversion
  wallets: Wallet[];
};

export function generateWalletAssetGroupId({
  blockchain,
  currencyCode,
  network,
  contractAddress,
}: {
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  network: Network;
  contractAddress?: string;
}): string {
  return [
    blockchain.rawValue,
    encodeURIComponent(currencyCode.rawValue),
    network.rawValue,
    contractAddress,
  ]
    .join('/')
    .replace(/\/$/, ''); // remove possible trailing slash
}

/**
 * Groups all visible portfolio wallets by asset type, with total balances
 * calculated for each grouping.
 *
 * Notes:
 * - Currently only used in Panorama app, where a given asset in a portfolio
 * can include multiple wallets.
 * - Testnet network wallets are excluded because Panorama does not currently
 * support them, and there isn't another current use-case for this selector.
 */
export const walletAssetGroupsMapSelector = selector<Map<string, WalletAssetGroup>>({
  key: 'walletAssetGroups',
  get: function getWalletAssetGroups({ get }) {
    const visiblePortfolioWalletsByWalletGroup = get(visiblePortfolioWalletsByWalletGroupSelector);

    const allVisiblePortfolioWallets = flatten(
      Array.from(visiblePortfolioWalletsByWalletGroup.values()),
    );

    return allVisiblePortfolioWallets.reduce(function reduceWalletsToAssetGroups(acc, wallet) {
      const assetId = generateWalletAssetGroupId({
        blockchain: wallet.blockchain,
        currencyCode: wallet.currencyCode,
        network: wallet.network,
        contractAddress: wallet.contractAddress,
      });

      const walletAssetGroup = acc.get(assetId) ?? {
        id: assetId,
        displayName: wallet.displayName,
        currencyCode: wallet.currencyCode,
        imageURL: wallet.imageURL,
        decimals: wallet.decimals,
        blockchain: wallet.blockchain,
        network: wallet.network,
        contractAddress: wallet.contractAddress,
        assetUUID: wallet.assetUUID,
        totalBalance: 0n,
        wallets: [],
      };

      walletAssetGroup.totalBalance += wallet.balance ?? 0n;
      walletAssetGroup.wallets.push(wallet);
      acc.set(assetId, walletAssetGroup);

      return acc;
    }, new Map());
  },
});

type WalletsByAccountId = Record<string, Wallet[]>;

export const visibleWalletsByAccountIdSelector = selector<WalletsByAccountId>({
  key: 'walletsByAccountIdSelector',
  get: function getWalletsByAccountId({ get }) {
    const visiblePortfolioWalletsByWalletGroup = get(visiblePortfolioWalletsByWalletGroupSelector);
    const allVisiblePortfolioWallets = flatten(
      Array.from(visiblePortfolioWalletsByWalletGroup.values()),
    );
    return allVisiblePortfolioWallets.reduce(function reduceWalletsByAccountId(
      acc: WalletsByAccountId,
      wallet: Wallet,
    ) {
      const hasSpam = wallet.isSpam;
      if (!hasSpam) {
        if (!acc[wallet.accountId]) {
          acc[wallet.accountId] = [];
        }
        acc[wallet.accountId].push(wallet);
      }
      return acc;
    },
    {});
  },
});

export const StoreKeys_activeWalletIndexes = new LocalStorageStoreKey<ActiveWalletIndexes>(
  'activeWalletIndexes',
);

export type RefreshSource = 'pullToRefresh' | 'poll' | 'init';

export const activeWalletIndexesAtom = atom({
  key: 'activeWalletIndexes',
  default: Object.fromEntries(
    allPossibleBlockchainSymbols.map((blockchain) => {
      return isMultiWalletBlockchain(blockchain) ? [blockchain, 0n] : [blockchain, undefined];
    }),
  ) as ActiveWalletIndexes,
  effects: [objectAtomLocalStorageEffect(StoreKeys_activeWalletIndexes)],
});

export const lastBalanceUpdateMetadataAtom = atom({
  key: 'lastBalanceUpdateMetadataAtom',
  default: {
    updatedAt: 0,
    source: undefined,
  } as {
    updatedAt: number;
    source?: RefreshSource;
  },
});

/*
 * A blockchain is considered synced after the first refresh is called and successful.
 * This is persisted in local storage
 */
export const StoreKeys_blockchainIsSyncedMap = new LocalStorageStoreKey<
  Record<AllPossibleBlockchainSymbol, boolean>
>('blockchainIsSyncedMap');

export const cachedBlockchainDeprecationStatusAtom = atom<boolean>({
  key: 'cachedBlockchainsDeprecationStatus',
  default: Store.get(StoreKeys_storedBlockchainDeprecationStatus) ?? false,
  effects: [objectAtomLocalStorageEffect(StoreKeys_storedBlockchainDeprecationStatus)],
});

export const blockchainIsSyncedMapAtom = atom({
  key: 'blockChainIsSyncedMapAtom',
  default: Object.fromEntries(
    getAllCurrentSupportedBlockchainSymbols()
      .filter((symbol) => blockchainConfigurations[symbol].isSyncingRequired)
      .map((symbol) => [symbol, false]),
  ),
  effects: [objectAtomLocalStorageEffect(StoreKeys_blockchainIsSyncedMap)],
});

export const allBlockchainsAreSyncedSelector = selector({
  key: 'allBlockChainsAreSyncedSelector',
  get: function getAllBlockchainsAreSynced({ get }) {
    const blockchainIsSyncedMap = get(blockchainIsSyncedMapAtom);
    const shouldExcludeDeprecatedBlockchains = get(cachedBlockchainDeprecationStatusAtom);
    const blockchainKeys = Object.keys(blockchainIsSyncedMap);
    if (shouldExcludeDeprecatedBlockchains) {
      return blockchainKeys
        .filter((symbol) => !allDeprecatedBlockchains.includes(symbol))
        .every((symbol) => blockchainIsSyncedMap[symbol]);
    }
    return blockchainKeys.every((key) => blockchainIsSyncedMap[key]);
  },
});

/*
 * A blockchain's refreshed state is reset on every refresh call.
 * see useUpdateEthAddressHistory, useUpdateSolAddressHistory and useUTXOAddressHistory
 */
export const blockchainIsRefreshedMapAtom = atom({
  key: 'blockchainIsRefreshedMapAtom',
  default: Object.fromEntries(
    getAllCurrentSupportedBlockchainSymbols().map((symbol) => [symbol, false]),
  ),
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async function logBalancesHaveRefreshedEffect() {
        const allBlockchainsAreRefreshed = await getPromise(allBlockchainsAreRefreshedSelector);
        if (allBlockchainsAreRefreshed) {
          const appStart = getTimer('balance.AppStart');
          logTotalBalanceFetchDuration({ startTime: appStart! });
        }
      });
    },
  ],
});

export const allBlockchainsAreRefreshedSelector = selector({
  key: 'allBlockchainsAreRefreshedSelector',
  get: function getAllBlockchainsAreRefreshed({ get }) {
    const blockchainIsRefreshedMap = get(blockchainIsRefreshedMapAtom);

    const blockchainKeys = Object.keys(blockchainIsRefreshedMap);

    return blockchainKeys.every((key) => blockchainIsRefreshedMap[key]);
  },
});

export const StoreKeys_hasSentBalanceUpdatePerfMark = new LocalStorageStoreKey<boolean>(
  'hasSentBalanceUpdatePerfMark',
);
export const hasSentBalanceUpdatePerfMarkAtom = atom({
  key: 'balanceUpdatePerfMark',
  default: false,
  effects: [objectAtomLocalStorageEffect(StoreKeys_hasSentBalanceUpdatePerfMark)],
});

export function StoreKeys_isZeroBalanceWallet(accountId: Account['id']) {
  return new LocalStorageStoreKey<boolean | undefined>(`${accountId}/isZeroBalanceWallet`);
}

// isNewlyCreatedWallet is set to true on onboarding complete for wallet creation,
// and is set to false on balance update.
// It's used to enable activation features for newly created wallets only,
// excluding $0 balance wallets from import or existing $0 balance wallets.
export const StoreKeys_isNewlyCreatedWallet = new LocalStorageStoreKey<boolean | undefined>(
  'isNewlyCreatedWallet',
);

export const isNewlyCreatedWalletAtom = atom<boolean | undefined>({
  key: 'isNewlyCreatedWalletAtom',
  default: undefined,
  effects: [atomLocalStorageEffect(StoreKeys_isNewlyCreatedWallet)],
});

export const StoreKeys_networkHasBalanceMap = new LocalStorageStoreKey<
  Record<string, undefined | boolean>
>('networkHasBalanceMapWithMultiAccounts');

export const networkHasBalanceMapAtom = atom<Record<string, undefined | boolean>>({
  key: 'networkHasBalanceMapAtom',
  default: {},
  effects: [objectAtomLocalStorageEffect(StoreKeys_networkHasBalanceMap)],
});

export const areAllNetworksBalanceSyncedSelector = selector({
  key: 'areAllNetworksBalanceSyncedSelector',
  get: function getAllNetworksBalanceSynced({ get }) {
    const networkHasBalanceMap = get(networkHasBalanceMapAtom);
    return Object.values(networkHasBalanceMap).every((hasBalance) => hasBalance !== undefined);
  },
});

export function StoreKeys_lastZeroBalanceFireTimeStamp(accountId: Account['id']) {
  return new LocalStorageStoreKey<number | undefined>(`${accountId}/lastZeroBalanceFireTimeStamp`);
}

const StoreKeys_walletsNetwork = new LocalStorageStoreKey<string>('StoreKeys_walletsNetwork');
export const walletsNetworkFilterDefault = { name: 'All networks', value: '' };

export const walletsNetworkFilterAtom = atom<{ name: string; value: string }[]>({
  key: 'wallets/network',
  default: [walletsNetworkFilterDefault],
  effects: [objectAtomLocalStorageEffect(StoreKeys_walletsNetwork)],
});
