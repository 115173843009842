import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  triggerWalletProviderConnectError,
  triggerWalletProviderConnectStart,
  triggerWalletProviderConnectSuccess,
} from 'cb-wallet-analytics/wallet-connection';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

import {
  ConnectWalletOptions,
  WalletConnectionErrorType,
  WalletConnectorConnectResponse,
  WalletProviderInfo,
  WalletProviderNetworkInfo,
} from ':dapp/connection/types';

import { isCoinbaseSmartWallet } from '../utils/isCoinbaseSmartWallet';
import { COINBASE_SMART_WALLET_ID } from '../wallet-providers/coinbase-wallet';

const messages = defineMessages({
  unknownError: {
    defaultMessage: 'Something went wrong. Please try again',
    description: 'error message for unknown error',
  },
  connectorNotReady: {
    defaultMessage: 'Wallet Provider not installed',
    description: 'error message for connector not ready',
  },
  userRejected: {
    defaultMessage: 'User rejected request',
    description: 'error message for user rejected connection request',
  },
  connectionFailed: {
    defaultMessage: 'Connection failed. Please try again',
    description: 'error message for connection failed',
  },
  connectorAlreadyConnected: {
    defaultMessage: 'Wallet is already connected',
    description: 'error message for connector already connected',
  },
  malformedResponse: {
    defaultMessage: 'The wallet returned a malformed address',
    description: 'error message for a malformed address response',
  },
});

const errorMessageMap = {
  [WalletConnectionErrorType.UNKNOWN]: messages.unknownError,
  [WalletConnectionErrorType.CONNECTOR_INVALID]: messages.unknownError,
  [WalletConnectionErrorType.CONNECTOR_NOT_READY]: messages.connectorNotReady,
  [WalletConnectionErrorType.USER_REJECTED]: messages.userRejected,
  [WalletConnectionErrorType.CONNECTION_FAILED]: messages.connectionFailed,
  [WalletConnectionErrorType.CONNECTOR_ALREADY_CONNECTED]: messages.connectorAlreadyConnected,
  [WalletConnectionErrorType.MALFORMED_RESPONSE]: messages.malformedResponse,
  // Ignore these connection errors
  [WalletConnectionErrorType.USER_LOGGED_OUT]: null,
  [WalletConnectionErrorType.WALLETCONNECT_CONNECTION_RESET]: null,
};

export function useConnectWallet() {
  const toast = useToast();
  const { formatMessage } = useIntl();

  return useCallback(
    async ({
      walletProvider,
      network,
      options,
    }: {
      walletProvider: WalletProviderInfo;
      network: WalletProviderNetworkInfo;
      options?: ConnectWalletOptions;
    }): Promise<WalletConnectorConnectResponse> => {
      try {
        triggerWalletProviderConnectStart({
          walletProviderName: walletProvider.name,
          walletProviderID: walletProvider.id,
          networkName: network.name,
          networkID: network.id,
        });
        const response = await walletProvider.connectorManager.connect(network.id, options);
        if (response?.error) {
          const msg = errorMessageMap[response.error];
          triggerWalletProviderConnectError({
            errorName: response.error,
          });
          if (msg) {
            toast.show(formatMessage(msg));
          }
        }
        if (response?.result) {
          // Only trigger response on success
          triggerWalletProviderConnectSuccess({
            walletProviderName: walletProvider.name,
            walletProviderID: walletProvider.id,
          });
        }

        /**
         * This is a temp check if the wallet is a smart wallet and update the provider id
         * In a future PR this will be removed in favor of using separate connectors
         * for all CBW interfaces (i.e. mobile, ext, scw...)
         */
        const coinbaseSmartWallet = await isCoinbaseSmartWallet(
          walletProvider,
          network,
          response?.result?.provider,
        );
        // This is going to be removed in favor of separate connectors for all CBW interfaces
        /* istanbul ignore next */
        if (response.result && coinbaseSmartWallet) {
          response.result.provider = COINBASE_SMART_WALLET_ID;
        }
        return response;
      } catch (err) {
        const error = coerceError(err, 'wallet connection error');
        toast.show(formatMessage(messages.unknownError));
        triggerWalletProviderConnectError({
          errorName: error.name,
          errorMessage: error.message,
        });
        cbReportError({
          error,
          context: 'wallet-connection-error',
          severity: 'error',
          isHandled: false,
        });
        return {
          error: WalletConnectionErrorType.UNKNOWN,
        };
      }
    },
    [formatMessage, toast],
  );
}
