export type TxOrUserOpMetadataValue = string | bigint | boolean | number;

export enum TxOrUserOpMetadataKind {
  string = 'string',
  bigint = 'bigint',
  boolean = 'boolean',
  number = 'number',
}

export class TxOrUserOpMetadataKey {
  constructor(readonly keyName: string, readonly kind: TxOrUserOpMetadataKind) {}

  get rawValue(): string {
    return [this.keyName, this.kind].join('/');
  }

  static create(rawValue: string): TxOrUserOpMetadataKey {
    const pieces = rawValue.split('/', 2);

    if (pieces.length !== 2 || !(pieces[1] in TxOrUserOpMetadataKind)) {
      throw new Error(`Invalid TxOrUserOpMetadataKey: ${rawValue}`);
    }

    return new TxOrUserOpMetadataKey(
      pieces[0],
      TxOrUserOpMetadataKind[pieces[1] as keyof typeof TxOrUserOpMetadataKind],
    );
  }
}

// TxOrUserOpMetadataKey+ConsumerConnect
export const TxOrUserOpMetadataKey_consumerTxID = new TxOrUserOpMetadataKey(
  'consumerTxID',
  TxOrUserOpMetadataKind.string,
);

// TxOrUserOpMetadataKey+UTXOWallets
export const TxOrUserOpMetadataKey_inputCount = new TxOrUserOpMetadataKey(
  'inputCount',
  TxOrUserOpMetadataKind.bigint,
);

// TxOrUserOpMetadataKey+UTXOWallets
export const TxOrUserOpMetadataKey_outputCount = new TxOrUserOpMetadataKey(
  'outputCount',
  TxOrUserOpMetadataKind.bigint,
);

// TxOrUserOpMetadataKey+SponsoredSends
export const TxOrUserOpMetadataKey_sponsoredUuid = new TxOrUserOpMetadataKey(
  'sponsoredUuid',
  TxOrUserOpMetadataKind.string,
);

export const TxOrUserOpMetadataKey_hasRecordedConfirmEvent = new TxOrUserOpMetadataKey(
  'hasRecordedConfirmEvent',
  TxOrUserOpMetadataKind.boolean,
);

// TxOrUserOpMetadataKey+GaslessSwaps
export const TxOrUserOpMetadataKey_gaslessSwapHash = new TxOrUserOpMetadataKey(
  'gaslessSwapHash',
  TxOrUserOpMetadataKind.string,
);

// TxOrUserOpMetadataKey+GaslessTxn
export const TxOrUserOpMetadataKey_isGaslessTxn = new TxOrUserOpMetadataKey(
  'isGasless',
  TxOrUserOpMetadataKind.boolean,
);

// TxOrUserOpMetadataKey+SponsoredTxn
export const TxOrUserOpMetadataKey_isSponsoredTxn = new TxOrUserOpMetadataKey(
  'isSponsored',
  TxOrUserOpMetadataKind.boolean,
);

export const TxOrUserOpMetadataKey_isRealTimeSwapTxn = new TxOrUserOpMetadataKey(
  'isRealTimeSwap',
  TxOrUserOpMetadataKind.boolean,
);

// TxOrUserOpMetadataKey+CBStakeTxn
export const TxOrUserOpMetadataKey_isCBStakeTxn = new TxOrUserOpMetadataKey(
  'isCBStake',
  TxOrUserOpMetadataKind.boolean,
);

// TxOrUserOpMetadataKey+Cashout
export const TxOrUserOpMetadataKey_cashoutIdem = new TxOrUserOpMetadataKey(
  'cashoutIdem',
  TxOrUserOpMetadataKind.string,
);

// TxMetadaKey+cashoutWithdrawalType
export const TxOrUserOpMetadataKey_cashoutWithdrawalType = new TxOrUserOpMetadataKey(
  'cashoutWithdrawalType',
  TxOrUserOpMetadataKind.string,
);

// TxOrUserOpMetadataKey+AssetType
export const AssetTypeNFT = 'NFT';
export const AssetTypeFungibleTkn = 'FUNGIBLE_TKN';
export const TxOrUserOpMetadataKey_assetType = new TxOrUserOpMetadataKey(
  'assetType',
  TxOrUserOpMetadataKind.string,
);

export const TxOrUserOpMetadataKey_toTokenImage = new TxOrUserOpMetadataKey(
  'toTokenImage',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_toTokenName = new TxOrUserOpMetadataKey(
  'toTokenName',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_toTokenDecimal = new TxOrUserOpMetadataKey(
  'toTokenDecimal',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_toTokenCurrencyCode = new TxOrUserOpMetadataKey(
  'toTokenCurrencyCode',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_toTokenAmount = new TxOrUserOpMetadataKey(
  'toTokenAmount',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_isReceiveTronTx = new TxOrUserOpMetadataKey(
  'isReceiveTronTx',
  TxOrUserOpMetadataKind.boolean,
);
export const TxOrUserOpMetadataKey_tronTxStatus = new TxOrUserOpMetadataKey(
  'tronTxStatus',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_tronFromAddress = new TxOrUserOpMetadataKey(
  'tronFromAddress',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_tronReceiptLink = new TxOrUserOpMetadataKey(
  'tronReceiptLink',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_tronTransactionHash = new TxOrUserOpMetadataKey(
  'tronTransactionHash',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_tronDestinationTransactionHash = new TxOrUserOpMetadataKey(
  'tronDestinationTransactionHash',
  TxOrUserOpMetadataKind.string,
);
export const TxOrUserOpMetadataKey_tronTxProviderKycLink = new TxOrUserOpMetadataKey(
  'tronTxProviderKycLink',
  TxOrUserOpMetadataKind.string,
);
