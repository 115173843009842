import { useCallback } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import {
  areNoQuoteAssetsHiddenExperimentAtom,
  areSmallBalanceWalletsHiddenAtom,
} from 'cb-wallet-data/stores/User/state';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { useSetRecoilState } from 'recoil';

/**
 * WALL-39444 is intended to change the following:
 * Control: no change
 * Experiment:
 * - setting updates "Hide small balances" to "Show small balances"
 * - if setting is active (hiding), hide assets with no quote
 * - if setting is active (hiding), hide assets with <$1 balance (already done with existing settings)
 * - default setting to active (hiding)
 */

// Default to false (control) and look for this  key. If this key is present, user has set their own settings, so dont override default based on experiment.
const StoreKeys_wallet_smallBalanceHiddenDefaultTrue = new LocalStorageStoreKey<boolean>(
  'wallet_smallBalanceHiddenDefaultTrue',
);
/**
 * Hook to set `areSmallBalanceWalletsHiddenAtom` based on experiment enrollment. To be "ON" by default in experiment. Additionally, leverage the local storage `wallet_smallBalanceHiddenDefaultTrue` key to determine if user has set their own preference regardless of experiment enrollment.
 * Utilizes a callback as this function sets state so cannot be called at root level in `App.tsx` or similar (causes re-render loops). The callback sets states and is triggered async by event
 * @returns Callback to trigger atom-setting logic.
 */
export function useOverrideSmallBalanceWalletsHiddenDefault(): () => void {
  const isHideNoQuoteEnabled = useIsFeatureEnabled('wallet_hide_no_quote_with_low_balance');
  const setAreSmallBalanceWalletsHidden = useSetRecoilState(areSmallBalanceWalletsHiddenAtom);
  const setAreNoQuoteAssetsHiddenExperimentAtom = useSetRecoilState(
    areNoQuoteAssetsHiddenExperimentAtom,
  );
  return useCallback(
    /**
     * Callback to trigger atom-setting logic.
     * @returns Void
     */
    function triggerOverrideSmallBalanceWalletsHiddenDefault() {
      const walletSmallBalanceHiddenDefaultTrueKey = Store.get<boolean>(
        StoreKeys_wallet_smallBalanceHiddenDefaultTrue,
      );
      // Since we cannot use `useIsFeatureEnabled` within atom (e.g. `visiblePortfolioWalletsByWalletGroupSelector`), we are storing this feature enabled state as an atom itself -- see discussion in https://github.cbhq.net/wallet/wallet-mobile/pull/24031.
      setAreNoQuoteAssetsHiddenExperimentAtom(isHideNoQuoteEnabled);
      if (!walletSmallBalanceHiddenDefaultTrueKey && isHideNoQuoteEnabled) {
        // set default to TRUE
        // set store key for future to not run this loop next time
        setAreSmallBalanceWalletsHidden(true);
        Store.set(StoreKeys_wallet_smallBalanceHiddenDefaultTrue, true);
      }
    },
    [
      isHideNoQuoteEnabled,
      setAreNoQuoteAssetsHiddenExperimentAtom,
      setAreSmallBalanceWalletsHidden,
    ],
  );
}
