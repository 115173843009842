import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';

export type DefineIsExpectedToBeSignedInCallback = ({
  hasAccounts,
  firstAccountType,
}: {
  hasAccounts: boolean;
  firstAccountType?: AccountType;
}) => boolean;

/**
 * calcIsExpectedToBeSignedIn is used to calculate whether the user is expected
 * to be signed in based on whether there's a defineIsExpectedToBeSignedInCallback
 * function.
 *
 * The newer AuthGateRootStack flow relies on the callback since it uses authState.
 */
export function calcIsExpectedToBeSignedIn({
  defineIsExpectedToBeSignedInCallback,
  hasSecrets,
  hasAccounts,
  firstAccountType,
}: {
  defineIsExpectedToBeSignedInCallback?: DefineIsExpectedToBeSignedInCallback;
  hasSecrets: boolean;
  hasAccounts: boolean;
  firstAccountType?: AccountType;
}): boolean {
  if (defineIsExpectedToBeSignedInCallback) {
    return defineIsExpectedToBeSignedInCallback({ hasAccounts, firstAccountType });
  }
  return hasSecrets || hasAccounts;
}
