import { useCallback, useEffect } from 'react';
import { logBalanceUpdated, logZeroNFTs } from 'cb-wallet-analytics/assets';
import { useDebouncedCallback } from 'cb-wallet-data/hooks/useDebouncedCallback';
import {
  ETHEREUM_COLLECTION_CHAIN_ID,
  POLYGON_COLLECTION_CHAIN_ID,
} from 'cb-wallet-data/stores/Collection/api';
import { useCollections } from 'cb-wallet-data/stores/Collection/hooks/useCollections';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { Store } from 'cb-wallet-store/Store';
import noop from 'lodash/noop';

import {
  StoreKeys_collectionsCountCacheState,
  StoreKeys_CollectionTokenCount,
  StoreKeys_ZeroNFTsEventLogged,
} from './storeKeys';

export type CollectionsUpdateOptions = {
  suppressBalanceUpdateEvents?: boolean;
  balanceUpdateCallback?: () => void;
};

export function useCollectionsUpdate({
  balanceUpdateCallback = noop,
  suppressBalanceUpdateEvents = false,
}: CollectionsUpdateOptions) {
  const { collections, isQueryLoading } = useCollections({ suspense: false });
  const collectionsCountCacheState = Store.get(StoreKeys_collectionsCountCacheState);

  // Debouncing balance update callback incase there are multiple collection.tokens updates on one refresh
  const debouncedBalanceUpdateCallback = useDebouncedCallback(balanceUpdateCallback, 1000);

  const handleCollectionsUpdated = useCallback(
    function handleCollectionsUpdated() {
      if (collectionsCountCacheState !== 'cached') return;

      if (
        !isQueryLoading &&
        collections.length === 0 &&
        !Store.get(StoreKeys_ZeroNFTsEventLogged)
      ) {
        logZeroNFTs();
        Store.set(StoreKeys_ZeroNFTsEventLogged, true);
      }

      collections.forEach(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        (collection) => {
          const tokenCount = collection.tokens.tokenList.length;
          const key = StoreKeys_CollectionTokenCount(collection.id);
          const storedTokenCount = Store.get<number>(key);

          if (storedTokenCount === tokenCount) return;

          Store.set(key, tokenCount);

          const chainId = collection.collectionInfo.chainId;
          const network = Network.fromChainId({ chainId: BigInt(chainId) });
          const baseChain = network.asChain();

          const blockchain = (() => {
            switch (chainId) {
              case String(ETHEREUM_COLLECTION_CHAIN_ID):
              case String(POLYGON_COLLECTION_CHAIN_ID):
                return 'ETH';
              default:
                return '';
            }
          })();

          if (!suppressBalanceUpdateEvents) {
            logBalanceUpdated({
              blockchain,
              currencyCode: collection.collectionInfo.collectionSymbol || '',
              tokenType: collection.collectionInfo.contractType,
              networkName: network.rawValue,
              chainName: baseChain?.displayName,
              chainId: String(chainId),
              contractAddress: collection.collectionInfo.contractAddress,
              isCollectionUpdate: true,
              cachedTokenCount: storedTokenCount,
              refreshedTokenCount: tokenCount,
            });
          }

          debouncedBalanceUpdateCallback();
        },
      );
    },
    [
      collections,
      collectionsCountCacheState,
      debouncedBalanceUpdateCallback,
      isQueryLoading,
      suppressBalanceUpdateEvents,
    ],
  );

  useEffect(
    function cacheCollectionTokenCounts() {
      if (collectionsCountCacheState !== 'start') return;

      collections.forEach(
        // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
        // eslint-disable-next-line wallet/no-anonymous-params
        (collection) => {
          const tokenCount = collection.tokens.tokenList.length;
          const key = StoreKeys_CollectionTokenCount(collection.id);
          const storedTokenCount = Store.get<number>(key);

          if (storedTokenCount !== undefined) return;

          Store.set(key, tokenCount);
        },
      );

      Store.set(StoreKeys_collectionsCountCacheState, 'cached');
    },
    [collections, collectionsCountCacheState],
  );

  const debouncedCollectionsUpdated = useDebouncedCallback(handleCollectionsUpdated, 5000);

  useEffect(debouncedCollectionsUpdated, [debouncedCollectionsUpdated, collections]);
}
