import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { txSubmittedFail, txSubmittedSuccess } from '../data/Transactions';
import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';
import { EventProperties } from '../utils/types/eventProperties';

type LogOnrampCommonEventProperties = {
  isConnected?: boolean;
  market?: string;
  provider?: string;
};

export function logOnrampErrorFallback({
  amount,
  screen,
  chainId,
  fiatCode,
  provider,
  errorMessage,
  currencyCode,
}: Pick<
  EventProperties,
  'amount' | 'chainId' | 'provider' | 'fiatCode' | 'errorMessage' | 'currencyCode' | 'screen'
>) {
  logDataEvent('onramp.error.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    amount,
    screen,
    chainId,
    provider,
    fiatCode,
    errorMessage,
    currencyCode,
  });
}

type LogOnrampExternalProviderWebviewViewedParams = {
  amount: string;
  currencyMode: string;
  fiatCode: string;
  hasPreviousTransaction: boolean;
  currencyCode: string;
  chainPrefix: string;
  chainId: string;
  provider: string;
  host: string;
  url: string;
};

export function useLogOnrampExternalProviderWebviewViewed({
  currencyCode,
  amount,
  chainId,
  fiatCode,
  chainPrefix,
  currencyMode,
  hasPreviousTransaction,
  provider,
}: Partial<LogOnrampExternalProviderWebviewViewedParams>) {
  useLogEventOnMount('onramp.external_provider_webview.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    provider,
    amount,
    chainId,
    fiatCode,
    chainPrefix,
    currencyMode,
    asset: currencyCode,
    hasPreviousTransaction,
  });
}

export function logOnrampExternalProviderWebviewNavigated(
  params: Partial<LogOnrampExternalProviderWebviewViewedParams>,
) {
  logEvent('onramp.external_provider_webview.navigated', {
    action: ActionType.view,
    componentType: ComponentType.page,
    ...params,
  });
}

type LogOnrampDefaultOptions = {
  selectedAsset?: string;
  chainId?: number;
  selectedPaymentMethod?: string;
  selectedPaymentMethodUuid?: string;
  selectedProvider?: string;
  amount?: string;
};

export function logOnrampDefaultOptions({
  amount,
  chainId,
  selectedAsset,
  selectedProvider,
  selectedPaymentMethod,
  market,
  isConnected,
}: LogOnrampDefaultOptions & LogOnrampCommonEventProperties) {
  logEvent('onramp.entry_default_options.loaded', {
    action: ActionType.render,
    componentType: ComponentType.page,
    paymentMethodId: selectedPaymentMethod,
    provider: selectedProvider,
    assetId: selectedAsset,
    chainId,
    amount,
    market,
    isConnected,
  });
}

type LogOnrampLaunchedParams = {
  amount?: number | string;
  chainId?: number;
  currencyCode?: string;
};

export function logOnrampLaunched({ amount, chainId, currencyCode }: LogOnrampLaunchedParams) {
  logEvent('onramp.flow.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    amount,
    chainId,
    currencyCode,
  });
}

export function useLogOnrampEntryViewed({
  isConnected,
  market,
  provider,
}: LogOnrampCommonEventProperties) {
  useLogEventOnMount('onramp.entry.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    isConnected,
    market,
    provider,
  });
}

export function useLogOnrampUnsupportedPaymentMethodTrayViewed({
  isConnected,
  market,
  provider,
}: LogOnrampCommonEventProperties) {
  useLogEventOnMount('onramp.unsupported_payment_method_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    isConnected,
    market,
    provider,
  });
}

export function logOnrampExit({ isConnected, market, provider }: LogOnrampCommonEventProperties) {
  logEvent('onramp.entry.exited', {
    action: ActionType.click,
    componentType: ComponentType.button,
    isConnected,
    market,
    provider,
  });
}

export function logOnrampPressSelectCell(variant: 'payment-method' | 'asset' | 'network') {
  logEvent('onramp.entry_select_cell.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    variant,
  });
}

export function useLogOnrampProviderSelectorTrayOnMount() {
  useLogEventOnMount('onramp.provider_selector_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampLastUsedProviderTrayViewed() {
  logEvent('onramp.last_used_provider_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampLastUsedProviderTrayPress(variant: 'close' | 'continue') {
  logEvent('onramp.last_used_provider_tray.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    variant,
  });
}

type LogOnrampEntryPreviewPressedParams = {
  asset?: string;
  amount?: string;
  chainId?: number;
  fiatCode?: string;
  provider?: string;
  paymentMethodId?: string;
};

export function logOnrampEntryPreviewPressed({
  asset,
  amount,
  fiatCode,
  provider,
  paymentMethodId,
  isConnected,
  market,
}: LogOnrampEntryPreviewPressedParams & LogOnrampCommonEventProperties) {
  logEvent('onramp.entry_preview.pressed', {
    action: ActionType.render,
    componentType: ComponentType.button,
    asset,
    amount,
    fiatCode,
    provider,
    paymentMethodId,
    isConnected,
    market,
  });
}

type LogOnrampEntryQuoteLoadedParams = {
  exchangeRate?: string;
  feeAmount?: string;
  payoutAmount?: string;
  totalAmount?: string;
  slippage?: string;
};

export function logOnrampEntryQuoteLoaded({
  feeAmount,
  totalAmount,
  exchangeRate,
  payoutAmount,
  slippage,
}: LogOnrampEntryQuoteLoadedParams) {
  logEvent('onramp.entry_quote_preview.loaded', {
    action: ActionType.render,
    componentType: ComponentType.text,
    networkFee: feeAmount,
    amount: totalAmount,
    exchangeRate,
    payoutAmount,
    slippage,
  });
}

type LogOnrampEntryQuoteErrorParams = {
  errorMessage?: string;
};

export function logOnrampEntryQuoteError({ errorMessage }: LogOnrampEntryQuoteErrorParams) {
  logEvent('onramp.entry_quote_preview.error', {
    action: ActionType.render,
    componentType: ComponentType.text,
    errorMessage,
  });
}

type LogOnrampEntryQuoteContinuePressedParams = {
  asset?: string;
  chainId?: number;
  amount?: string;
  quoteId?: string;
  fiatCode?: string;
  provider?: string;
  paymentMethodId?: string;
};

export function logOnrampEntryQuoteContinuePressed({
  asset,
  chainId,
  amount,
  quoteId,
  fiatCode,
  provider,
  paymentMethodId,
  isConnected,
  market,
}: LogOnrampEntryQuoteContinuePressedParams & LogOnrampCommonEventProperties) {
  logEvent('onramp.entry_quote_preview_continue.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    asset,
    amount,
    chainId,
    quoteId,
    fiatCode,
    provider,
    paymentMethodId,
    isConnected,
    market,
  });
}

type LogOnrampEntryQuotePreviewViewedParams = {
  amount?: string;
  chainId?: number;
  fiatCurrency?: string;
  selectedAsset?: string;
  selectedProvider?: string;
  selectedPaymentMethod?: string;
};

export function useLogOnrampEntryQuotePreview({
  fiatCurrency,
  selectedAsset,
  chainId,
  selectedProvider,
  selectedPaymentMethod,
  amount,
  isConnected,
  market,
}: LogOnrampEntryQuotePreviewViewedParams & LogOnrampCommonEventProperties) {
  useLogEventOnMount('onramp.entry_quote_preview.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    amount,
    chainId,
    assetId: selectedAsset,
    fiatCode: fiatCurrency,
    provider: selectedProvider,
    paymentMethodId: selectedPaymentMethod,
    isConnected,
    market,
  });
}

type LogOnrampEntryErrorParams = {
  error:
    | 'max-out-of-range'
    | 'min-out-of-range'
    | 'payment-method-unavailable'
    | 'limit-min-max-out-of-range';
  provider?: string;
  paymentMethod?: string;
};

export function logOnrampEntryError({ error, provider, paymentMethod }: LogOnrampEntryErrorParams) {
  logEvent('onramp.entry.error', {
    action: ActionType.render,
    componentType: ComponentType.page,
    error,
    provider,
    paymentMethodId: paymentMethod,
  });
}

type LogOnrampAssetSelected = {
  currencyCode?: string;
  chainId?: number;
  isOnrampSwap?: boolean;
  isOnrampMgx?: boolean;
};

export function logOnrampAssetSelected(
  properties: LogOnrampAssetSelected & LogOnrampCommonEventProperties,
) {
  logEvent('onramp.entry.asset_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...properties,
  });
}

export function logOnrampPaymentMethodSelected({
  paymentMethodId,
  isConnected,
  market,
}: { paymentMethodId?: string } & LogOnrampCommonEventProperties) {
  logEvent('onramp.entry.payment_method_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    paymentMethodId,
    isConnected,
    market,
  });
}

export function logOnrampProviderSelected({
  provider,
  isConnected,
  market,
}: { provider?: string } & LogOnrampCommonEventProperties) {
  logEvent('onramp.entry.provider_selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    provider,
    isConnected,
    market,
  });
}

export function logOnrampMoreOptionClicked() {
  logEvent('onramp.entry.more_option_clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logOnrampChangeCurrencySelected() {
  logEvent('onramp.entry.change_currency_selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logOnrampCurrencySelected(properties: Pick<EventProperties, 'currencyCode'>) {
  logEvent('onramp.currency.selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...properties,
  });
}

export function logMoreOptionTrayViewed() {
  logEvent('onramp.more_option_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampMgxAssetTrayOptionClicked({ context }: { context: string }) {
  logEvent('onramp.mgx_asset_tray_option.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    context,
  });
}

export function useLogOnrampSelectCurrencyViewed() {
  useLogEventOnMount('onramp.select_currency.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSelectAssetViewed() {
  useLogEventOnMount('onramp.select_asset.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSelectPaymentMethodViewed(params: LogOnrampCommonEventProperties = {}) {
  useLogEventOnMount('onramp.select_payment_method.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...params,
  });
}

export function useLogOnrampSelectNetworkViewed() {
  useLogEventOnMount('onramp.select_network.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampNetworkFilterViewed() {
  useLogEventOnMount('onramp.network_filter.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function useLogOnrampSwappableAssetTrayViewed() {
  useLogEventOnMount('onramp.swappable_asset_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

type UseLogOnrampMgxAssetTrayViewedProps = {
  asset: string;
  chainId: number;
};

export function useLogOnrampMgxAssetTrayViewed({
  asset,
  chainId,
}: UseLogOnrampMgxAssetTrayViewedProps) {
  useLogEventOnMount('onramp.mgx_asset_tray.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    asset,
    chainId,
  });
}

type UseLogMgxAssetUnsupportedErrorModalViewedProps = { type: string };

export function useLogMgxAssetUnsupportedErrorModalViewed({
  type,
}: UseLogMgxAssetUnsupportedErrorModalViewedProps) {
  useLogEventOnMount('onramp.mgx_asset_unsupported_error_modal.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
    type,
  });
}

export function logOnrampNetworkFilterSelected(chainId?: string | number | null) {
  logEvent('onramp.network_filter.selected', {
    action: ActionType.render,
    componentType: ComponentType.page,
    chainId: chainId ?? undefined,
  });
}

type LogOnrampTransactionSubmittedParams = {
  provider: string;
};

export function logOnrampTransactionSubmitted({
  provider,
  isConnected,
  market,
}: LogOnrampTransactionSubmittedParams & LogOnrampCommonEventProperties) {
  logEvent('onramp.transaction.submitted', {
    action: ActionType.render,
    componentType: ComponentType.page,
    provider,
    isConnected,
    market,
  });
}

type LogOnrampTransactionSuccessParams = {
  asset?: string;
  amount?: string;
  chainId?: number;
  chainName?: string;
  provider?: string;
};

export function logOnrampTransactionSuccess(
  params: LogOnrampTransactionSuccessParams & LogOnrampCommonEventProperties,
) {
  logEvent(txSubmittedSuccess, {
    action: ActionType.measurement,
    componentType: ComponentType.unknown,
    txSource: 'MGX_onramp',
    ...params,
  });
}

export function logOnrampTransactionFailure(
  params: LogOnrampTransactionSuccessParams & LogOnrampCommonEventProperties,
) {
  logEvent(txSubmittedFail, {
    action: ActionType.measurement,
    componentType: ComponentType.unknown,
    txSource: 'MGX_onramp',
    ...params,
  });
}

type LogOnrampTransactionErrorParams = {
  provider: string;
  errorMessage?: string;
};

export function logOnrampTransactionError({
  provider,
  errorMessage,
}: LogOnrampTransactionErrorParams) {
  logEvent('onramp.transaction.error', {
    action: ActionType.render,
    componentType: ComponentType.page,
    provider,
    errorMessage,
  });
}

export function logOnrampExternalProviderFlowLaunched(params: LogOnrampCommonEventProperties = {}) {
  logEvent('onramp.external_provider.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...params,
  });
}

export function logOnrampProviderFlowLaunched(
  params: Pick<
    EventProperties,
    | 'provider'
    | 'asset'
    | 'network'
    | 'paymentMethodId'
    | 'paymentMethodUuid'
    | 'fiatCode'
    | 'amount'
    | 'isConnected'
    | 'market'
  >,
) {
  logEvent('onramp.provider.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    ...params,
  });
}

type LogOnrampDeviceBrowserLaunchedParams = {
  source: string;
  url: string | undefined;
  errorMessage: string | undefined;
};

export function logOnrampDeviceBrowserLaunched({
  url,
  source,
  errorMessage,
}: LogOnrampDeviceBrowserLaunchedParams) {
  logEvent('onramp.device_browser.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    url,
    source,
    errorMessage,
  });
}

export function logOnrampIABLaunched(isInAppBrowserAvailable: boolean) {
  logEvent('onramp.iab.launched', {
    action: ActionType.render,
    componentType: ComponentType.page,
    enabled: isInAppBrowserAvailable,
  });
}

export function logOnrampIABResponse(type?: string) {
  logEvent('onramp.iab.response', {
    action: ActionType.render,
    componentType: ComponentType.page,
    type,
  });
}

export function logOnrampUnavailableProvider({
  asset = '',
  chainId = '',
  provider = '',
  newProvider = '',
}: {
  asset?: string;
  chainId?: string;
  provider?: string;
  newProvider?: string;
}) {
  logMetric({
    metricName: 'onramp.unavailable_provider',
    metricType: MetricType.count,
    value: 1,
    tags: {
      asset,
      chainId,
      provider,
      newProvider,
    },
  });
}

export function logOnrampAuthLaunched() {
  logEvent('onramp.authorization.viewed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampAuthCompleted() {
  logEvent('onramp.authorization.completed', {
    action: ActionType.render,
    componentType: ComponentType.page,
  });
}

export function logOnrampNativeBuyConnectSucceeded(
  props: LogOnrampEntryPreviewPressedParams & { navigateTo?: string },
) {
  logEvent('onramp.native_buy_connected.succeeded', {
    action: ActionType.unknown,
    componentType: ComponentType.unknown,
    ...props,
  });
}
