import { memo, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import Image from 'next/image';
import { Button } from 'wallet-cds-web/components/Button';
import { Checkbox } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { HomebaseModal } from '../HomebaseModal/HomebaseModal';

export const StoreKeys_externalLinkWarningDisabled = new LocalStorageStoreKey<boolean>(
  'externalLinkWarning_disabled',
);

const messages = defineMessages({
  primaryCTAText: {
    defaultMessage: 'Yes, open the link',
    description: 'CTA for continuing to external link.',
  },
  goBack: {
    defaultMessage: 'Go back',
    description: 'Label for close button in external link modal.',
  },
  title: {
    defaultMessage: 'Do you trust {hostname}?',
    description: 'Title for external link modal.',
  },
  description: {
    defaultMessage: 'Never interact with websites you do not trust or you may lose your funds.',
    description: 'Description for external link modal.',
  },
  dontShowAgain: {
    defaultMessage: "Don't show this warning again",
    description: 'Label for checkbox to not show the external link modal again.',
  },
  modalImageAlt: {
    defaultMessage: 'External link warning',
    description: 'Alt text for external link warning image.',
  },
});

export type ExternalLinkModalProps = {
  visible?: boolean;
  onContinue?: () => void;
  onClose: () => void;
  hostname: string;
};

function ExternalLinkWarningModal({
  visible,
  onContinue,
  onClose,
  hostname,
}: ExternalLinkModalProps) {
  const { formatMessage } = useIntl();

  const [isWarningDisabled, setIsWarningDisabled] = useState<boolean>(
    Store.get(StoreKeys_externalLinkWarningDisabled) || false,
  );

  const handleDisableWarningChange = useCallback(() => {
    setIsWarningDisabled(!isWarningDisabled);
    Store.set(StoreKeys_externalLinkWarningDisabled, (!isWarningDisabled).toString());
  }, [isWarningDisabled, setIsWarningDisabled]);

  return (
    <HomebaseModal
      visible={Boolean(visible)}
      header={<></>}
      onClose={onClose}
      testID="external-link-warning-modal"
    >
      <VStack gap={2} spacingTop={1}>
        <VStack alignItems="center">
          <Image
            alt={formatMessage(messages.modalImageAlt)}
            src="/external-link-modal-image.png"
            width={240}
            height={120}
          />
        </VStack>
        <TextTitle3 as="h2" color="foreground" align="center">
          {formatMessage(messages.title, { hostname })}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted" align="center" spacingBottom={2}>
          {formatMessage(messages.description)}
        </TextBody>
        <Button testID="external-link-warning-continue-btn" onClick={onContinue} block>
          {formatMessage(messages.primaryCTAText)}
        </Button>
        <Button variant="secondary" testID="external-link-warning-back-btn" onClick={onClose}>
          {formatMessage(messages.goBack)}
        </Button>
        <VStack alignItems="center">
          <Checkbox
            testID="external-link-warning-disable-checkbox"
            value="disableWarning"
            checked={isWarningDisabled}
            onChange={handleDisableWarningChange}
          >
            {formatMessage(messages.dontShowAgain)}
          </Checkbox>
        </VStack>
      </VStack>
    </HomebaseModal>
  );
}

export const ExternalLinkModal = memo(ExternalLinkWarningModal);
