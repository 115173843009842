import { isJestTest } from 'cb-wallet-data/utils/debuggingUtils';
import { DataSource, ObjectLiteral, Repository } from '@cbhq/typeorm';
import { IndexedDbConnectionOptions } from '@cbhq/typeorm/driver/indexeddb/IndexedDbConnectionOptions';

import { ENTITY_SCHEMAS, IDatabase } from './Database.interface';
import { NoopRepository } from './NoopRepository';
import { isDatabaseAccessAllowed } from './state';

export const CONNECTION_NAME = 'walletExt';

export const defaultConnectionOptions: IndexedDbConnectionOptions = {
  type: 'indexeddb',
  dropSchema: isJestTest(),
  version: 53,
  name: CONNECTION_NAME,
  database: CONNECTION_NAME,
  entities: ENTITY_SCHEMAS,
  synchronize: true,
  dexieJsConfig: {
    autoOpen: true,
  },
};

// Used synchronously in the tryGetRepository function below
let datasource: DataSource | undefined;
let connectingPromise: Promise<DataSource> | undefined;

async function initializeConnection() {
  datasource = new DataSource(defaultConnectionOptions);
  await datasource.initialize();

  return datasource;
}

export const { openDatabase, deleteDatabase, tryGetRepository }: IDatabase = {
  openDatabase: async function openDatabase(): Promise<DataSource> {
    if (!connectingPromise) {
      connectingPromise = initializeConnection();
      return connectingPromise;
    }

    return connectingPromise;
  },

  deleteDatabase: async function deleteDatabase() {
    const innerDatasource = await openDatabase();
    await innerDatasource.dropDatabase();
    connectingPromise = undefined;
    datasource = undefined;
  },

  /**
   * Gets a repository object for the given model.
   */
  tryGetRepository: function tryGetRepository<Entity extends ObjectLiteral>(
    name: string,
  ): Repository<Entity> {
    // If the database access is not allowed, return NoopRepository
    if (!isDatabaseAccessAllowed()) {
      return new NoopRepository() as unknown as Repository<Entity>;
    }

    return datasource!.getRepository(name);
  },
};
