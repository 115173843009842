import {
  ActionType,
  AnalyticsEventImportance,
  ComponentType,
  logMetric,
  MetricType,
  sendScheduledEvents,
  ValidEventData,
} from '@cbhq/client-analytics';

import {
  AnalyticsEventProperties,
  logDataEvent,
  logEvent,
  LogEventDataLayerParams,
  LogEventOnMountParams,
  useLogEventOnMount,
} from '../utils/log';
import { AccountTypeEventPropertyValue } from '../utils/types/eventProperties';

type MultiAccountDataEventProps = Omit<LogEventDataLayerParams, 'loggingId'>;

/**
 * Log when the orchestration of creating of any accounts, wallet groups, etc., **starts** during onboarding
 */
export function triggerOnboardingCreateStarted(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.onboarding.create.started', {
    ...props,
    loggingId: '6f64be72-2a4b-11ed-a261-0242ac120002',
  });
}

/**
 * Log when the orchestration of creating of any accounts, wallet groups, etc., **succeeds** during onboarding
 */
export function triggerOnboardingCreateSuccess({
  accountType,
  flow,
  ...props
}: MultiAccountDataEventProps & { flow: 'onboarding' | 'add-account' }) {
  // Generalized amplitude event for creating accounts
  logDataEvent('accounts.onboarding.create.success', {
    ...props,
    accountType,
    flow,
    loggingId: 'afd8abfe-a20e-4eab-93dc-5f4787262650',
  });

  // Event tracking for datadog logging
  // ie: onboarding.create-success.mnemonic
  logDataEvent(`${flow}.create-success.${accountType}`, {});
}

/**
 * Log when the orchestration of creating of any accounts, wallet groups, etc., **fails** during onboarding
 */
export function triggerOnboardingCreateFailed(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.onboarding.create.failed', {
    ...props,
    loggingId: 'd81406b2-a289-4c08-bcc7-1b2f90e12805',
  });
}

/**
 * Log when any type of account (e.g. mnemonic, ledger, WalletLink, etc.) is created during the onboarding orchestration
 */
export function triggerOnboardingAccountCreated(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.onboarding.account.created', {
    ...props,
    loggingId: '97e65158-facf-47e2-a11e-bd2605d2b6f9',
  });
}

/**
 * Log when a wallet group is created during the onboarding orchestration
 */
export function triggerOnboardingWalletGroupCreated(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.onboarding.wallet_group.created', {
    ...props,
    loggingId: '40da5529-c855-4d7b-8d5c-7b5ff1c10cc6',
  });
}

/**
 * Log when we **succeed** in creating any number of wallets at a specific wallet index
 */
export function triggerCreateWalletsAtIndexSuccess(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallets_at_index.success', {
    ...props,
    loggingId: 'e8539129-6076-4174-8283-e2be411e15aa',
  });
}

/**
 * Log when we **fail** in creating any number of wallets at a specific wallet index
 */
export function triggerCreateWalletsAtIndexFailed(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallets_at_index.failed', {
    ...props,
    loggingId: '7268f5c6-261e-4310-8572-5a665ca01c1c',
  });
}

/**
 * Log when we **start** creating wallets from public keys
 */
export function triggerCreateWalletsFromPublicKeysStart(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallets_from_public_keys.start', props);
}

/**
 * Log when we **succeed** creating wallets from public keys
 */
export function triggerCreateWalletsFromPublicKeysSuccess(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallets_from_public_keys.success', props);
}

/**
 * Log when we **fail** creating wallets from public keys
 */
export function triggerCreateWalletsFromPublicKeysFailed(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallets_from_public_keys.failed', props);
}

/**
 * Log when we **succeed** in creating any number of wallet groups
 */
export function triggerCreateWalletGroupsSuccess(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallet_groups.success', {
    ...props,
    loggingId: '9de9f533-3335-473f-9ac5-6db97f2e632e',
  });
}

/**
 * Log when we **fail** in creating any number of wallet groups
 */
export function triggerCreateWalletGroupsFailed(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.create_wallet_groups.failed', {
    ...props,
    loggingId: '43e3b500-9278-4a75-92fd-318fcfbc37f8',
  });
}

/**
 * Log when the user has reached the limit on the max number of wallet groups that can be created
 */
export function triggerLogWalletLimitReached(
  props: MultiAccountDataEventProps = {
    action: ActionType.measurement,
    component: ComponentType.unknown,
  },
) {
  logDataEvent('multiwallet.walletgrouplimit.reached', {
    ...props,
    loggingId: 'bd1e837c-dbcd-4f0b-b4d9-f48ecd3392f5',
  });
}

/**
 * Log when we detect that a Multi-Account user is missing their default account that should have been created during onboarding
 */
export function triggerMissingDefaultAccountDetected(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.missing_default_account_detected', {
    ...props,
    loggingId: '8df3d229-74f5-44e6-afc2-5e3a2065a784',
  });
}

/**
 * Log when we detect that a Multi-Account user is missing their default wallet group that should have been created during onboarding
 */
export function triggerMissingDefaultWalletGroupDetected(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.missing_default_wallet_group_detected', {
    ...props,
    loggingId: '9f329552-b752-4b50-9fdd-7216ef12c0d5',
  });
}

/**
 * Log for users to track metadata about their cloud backups and how it relates to their active `Account`
 */
export function triggerUserAccountCloudBackups(props: MultiAccountDataEventProps = {}) {
  logDataEvent('accounts.user_account_cloud_backups', {
    ...props,
    loggingId: '162bfdd2-2154-4033-a8ed-b093033f8350',
  });
}

type MultiAccountEventOnMountProps = Omit<LogEventOnMountParams, 'loggingId'>;

/**
 * Log when the multi-wallet NUX screen is viewed
 */
export function useTriggerNUXScreenViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.nux_screen.viewed', {
    ...props,
    loggingId: '0055cc47-5acb-4ca7-8497-aefc9f69f220',
  });
}

/**
 * Log when a wallet switcher UI is viewed
 */
export function useTriggerWalletSwitcherViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.wallet_switcher.viewed', {
    ...props,
    loggingId: '0a5cf7f7-b164-4b42-bf6d-50f77af7c9ff',
  });
}

/**
 * Log when the "Add & manage wallets" screen is viewed
 */
export function useTriggerAddManageWalletsViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.add_manage_wallets.viewed', {
    ...props,
    loggingId: '752404c9-5802-4ca0-a76a-dc7fe15c9355',
  });
}

/**
 * Log when user has reached maximum number of wallets / addresses for a given account
 */
export function useTriggerMaxWalletsReachedCopyViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.add_manage_wallets.max_wallets_reached_viewed', {
    ...props,
    loggingId: 'd5a9b97d-c253-431f-9af1-eb68765d22fd',
  });
}

/**
 * Log when the "Add a new wallet" screen is viewed (for users manually creating a wallet group for the first time)
 */
export function useTriggerWhyCreateNewWalletViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.why_create_new_wallet.viewed', {
    ...props,
    loggingId: '815a78f1-e9d0-4799-8026-0c9be0927e32',
  });
}

/**
 * Log when the "Edit wallet" screen is viewed
 */
export function useTriggerEditWalletScreenViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.edit_wallet_screen.viewed', {
    ...props,
    loggingId: '0059624c-56a3-4371-80ed-cf0efea17b3b',
  });
}

/**
 * Log when the "Edit wallet nickname" screen is viewed
 */
export function useTriggerEditWalletNicknameScreenViewed(props: MultiAccountEventOnMountProps) {
  useLogEventOnMount('accounts.edit_wallet_nickname_screen.viewed', {
    ...props,
    loggingId: '640adf05-01b6-4262-928b-2b9f1bc1e681',
  });
}

type MultiAccountEventProps = Omit<AnalyticsEventProperties<ValidEventData>, 'loggingId'>;

/**
 * Log when a wallet switcher UI is opened
 */
export function triggerWalletSwitcherOpened(
  props: MultiAccountEventProps = {
    action: ActionType.click,
    componentType: ComponentType.text,
    context: 'nav-bar',
  },
) {
  logEvent('accounts.wallet_switcher.opened', {
    ...props,
    loggingId: 'ae7033e9-139a-4679-9f65-9934fd85ff50',
  });
}

/**
 * Log when a wallet switcher UI is closed
 */
export function triggerWalletSwitcherClosed(props: MultiAccountEventProps) {
  logEvent('accounts.wallet_switcher.closed', {
    ...props,
    loggingId: '91fbc52f-b178-42ba-96a6-24528822058e',
  });
}

/**
 * Log when the user chooses a wallet group item from the wallet switcher UI
 */
export function triggerWalletSwitcherWalletGroupSelected(props: MultiAccountEventProps) {
  logEvent('accounts.wallet_switcher.wallet_group.selected', {
    ...props,
    loggingId: 'b1998889-fe81-41d9-98ae-2da55e58b3ab',
  });
}

/**
 * Log when the user clicks on a link, button, etc., that will bring them to the entry step of the create wallet group CUJ funnel
 */
export function triggerWalletSwitcherWalletGroupCreateEntryClicked(props: MultiAccountEventProps) {
  logEvent('accounts.wallet_switcher.wallet_group.create_entry_clicked', {
    ...props,
    loggingId: 'da6ff158-ffef-4a69-9c71-ad681ee3bd96',
  });
}

type TriggerWalletGroupCreatedByUserProps = MultiAccountEventProps & {
  /**
   * Users who manually create their first wallet group will be taken down a unique workflow with any subsequent creations using
   * the generic new wallet group workflow
   */
  context: 'first_new_manual_wallet_group' | 'subsequent_new_manual_wallet_group';
};

/**
 * Log when the user **manually** creates a wallet group
 *
 * **NOTE:** This is **NOT** for when any wallet groups are automatically created during onboarding or wallet scanning
 */
export function triggerWalletGroupCreatedByUser(props: TriggerWalletGroupCreatedByUserProps) {
  logEvent('accounts.wallet_group.created_by_user', {
    ...props,
    loggingId: '5de14990-c95d-4689-b9ec-9e272c348462',
  });
}

/**
 * Log when a wallet group's nickname is updated
 */
export function triggerWalletGroupNicknameUpdated(props: MultiAccountEventProps) {
  logEvent('accounts.wallet_group_nickname.updated', {
    ...props,
    loggingId: '79da79e2-d2e2-4749-b481-f36a2aaefe7f',
  });
}

/**
 * Log when a wallet group's visibility is toggled
 */
export function triggerWalletGroupVisibilityToggled(props: MultiAccountEventProps) {
  logEvent('accounts.wallet_group_visibility.toggled', {
    ...props,
    loggingId: '6ecff47a-b941-4499-9d59-d640ee646275',
  });
}

/**
 * Logged when starting to get an xpub key from local/memory storage.
 */
export function logGetXpubKeyStart({
  blockchain,
  addressType,
  walletIndex,
  accountType,
}: {
  blockchain: string;
  addressType: string;
  walletIndex: string;
  accountType: string;
}) {
  logDataEvent('accounts.get_xpubkey.start', {
    data: {
      blockchain,
      addressType,
      walletIndex,
      accountType,
    },
  });
}

/**
 * Logged after getting xpubkey from local/memory storage, with result 'success' or
 * 'missing' based on whether the xpubkey is truthy or not.
 */
export function logGetXpubKeyResult({
  blockchain,
  addressType,
  walletIndex,
  accountType,
  result,
}: {
  blockchain: string;
  addressType: string;
  walletIndex: string;
  accountType: string;
  result: 'success' | 'missing';
}) {
  logDataEvent(`accounts.get_xpubkey.${result}`, {
    data: {
      blockchain,
      addressType,
      walletIndex,
      accountType,
    },
  });
}

type XpubKeyMissingProps = {
  blockchain: string;
  addressType: string;
  walletIndex: string;
};

/**
 * During UTXO balance refreshing, this event is logged if the xpub
 * key can't be found in local memory storage for the given address type
 * (i.e. BitcoinSegWit, BitcoinLegacy, etc) in order to balance scan across addresses.
 */
export function logXpubKeyMissing({ blockchain, addressType, walletIndex }: XpubKeyMissingProps) {
  logMetric({
    metricName: 'accounts.xpubkey.missing',
    metricType: MetricType.count,
    value: 1,
    tags: { blockchain, addressType, walletIndex },
  });
}

/**
 * If logXpubKeyMissing (see above) was fired, this event will fire afterwards
 * if the missing key was found stored in the legacy format prior to multi-account,
 * and then migrated to the new format which includes the account ID + wallet index.
 *
 * NOTE: This event should not fire more than once for an individual user, for
 * each address type.
 */
export function logXpubStoreKeyMigrated({
  blockchain,
  addressType,
  walletIndex,
}: XpubKeyMissingProps) {
  logMetric({
    metricName: 'accounts.xpubkey.migrated',
    metricType: MetricType.count,
    value: 1,
    tags: { blockchain, addressType, walletIndex },
  });
}

/**
 * @see useCheckAuthStateAndAccountsAndSignOutIfMistached
 * RN-only
 */
export function triggerCheckAuthStateAndAccountsMismatchStart({
  onboardingContextComponentStack,
  authStateHasMnemonic,
}: {
  onboardingContextComponentStack:
    | 'onboarding'
    | 'createNewMnemonicAccount'
    | 'importNewMnemonicAccount';
  authStateHasMnemonic?: boolean;
}) {
  logDataEvent('accounts.check_auth_state_and_accounts_mismatch.start', {
    loggingId: '174353b0-5950-4c59-ae19-ec1d5085c87d',
    data: {
      onboardingContextComponentStack,
      authStateHasMnemonic,
    },
  });
}

export function triggerCheckAuthStateAndAccountsMismatchFinish({
  newAuthStateHasMnemonic,
  isKilled = false,
  hasAccount,
  hasWalletGroup,
  hasWallet,
  hasAddress,
  signout = false,
}: {
  newAuthStateHasMnemonic?: boolean;
  isKilled?: boolean;
  hasAccount?: boolean;
  hasWalletGroup?: boolean;
  hasWallet?: boolean;
  hasAddress?: boolean;
  signout?: boolean;
}) {
  logDataEvent('accounts.check_auth_state_and_accounts_mismatch.finish', {
    loggingId: '82e4044d-7f42-492f-a3d7-ac15dae2ce40',
    data: {
      newAuthStateHasMnemonic,
      isKilled,
      hasAccount,
      hasWalletGroup,
      hasWallet,
      hasAddress,
      signout,
    },
  });
  sendScheduledEvents();
}
/**
 * End
 */

// Usage: Settings Session viewed on mount
export function logSettingsSessionViewed() {
  logEvent('accounts.settings.session.viewed', {
    loggingId: '10729598-5b57-4e70-bddb-7a8f188ac3d4',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

// Usage: When there is a missing mnemonic, just before it navigates to
// missing mnemonic escape hatch
export function logMissingMnemonic({
  isMissingMnemonicEscapeHatchKilled,
}: {
  isMissingMnemonicEscapeHatchKilled: boolean;
}) {
  logDataEvent(
    'accounts.missing_mnemonic',
    {
      loggingId: 'ee113ff3-f28c-461a-af51-ce0f94d085c6',
      isMissingMnemonicEscapeHatchKilled,
    },
    AnalyticsEventImportance.high,
  );
}

// Wallet Creation start
export function logCreateWalletsStart({
  accountType,
  createWithZeroBalance,
  createAtIndexes,
}: {
  accountType: AccountTypeEventPropertyValue;
  createWithZeroBalance: boolean;
  createAtIndexes?: number[];
}) {
  logDataEvent('accounts.create_wallets.start', {
    data: {
      accountType,
      createWithZeroBalance,
      createAtIndexes,
    },
  });
}

export function logCreateWalletsSuccess({
  accountType,
  createWithZeroBalance,
  createAtIndexes,
  createdWalletsLength,
}: {
  accountType: AccountTypeEventPropertyValue;
  createWithZeroBalance: boolean;
  createAtIndexes?: number[];
  createdWalletsLength: number;
}) {
  logDataEvent('accounts.create_wallets.success', {
    data: {
      accountType,
      createWithZeroBalance,
      createAtIndexes,
      createdWalletsLength,
    },
  });
}

export function logCreateWalletsFailed({
  accountType,
  createWithZeroBalance,
  createAtIndexes,
}: {
  accountType: AccountTypeEventPropertyValue;
  createWithZeroBalance: boolean;
  createAtIndexes?: number[];
}) {
  logDataEvent('accounts.create_wallets.failed', {
    data: {
      accountType,
      createWithZeroBalance,
      createAtIndexes,
    },
  });
}

export function logFindAccountsThatNeedWalletsStart() {
  logDataEvent(
    'accounts.find_accounts_that_need_wallets_start',
    {
      loggingId: '3adfe135-ae69-4305-8880-912b2c41680e',
    },
    AnalyticsEventImportance.high,
  );
}

export function logFindAccountsThatNeedWalletsResult({
  accountsThatNeedWalletsLength,
  accountsThatWereCorruptLength,
  accountsLength,
  hasMissingAddresses,
  hasMissingWallets,
  missingAddressBlockchains,
  missingAddressEthNetworks,
  missingAddressIndexes,
  missingWalletBlockchains,
  missingWalletEthNetworks,
  missingWalletIndexes,
}: {
  accountsThatNeedWalletsLength: number;
  accountsThatWereCorruptLength: number;
  accountsLength: number;
  hasMissingAddresses: boolean;
  hasMissingWallets: boolean;
  missingAddressBlockchains: string[];
  missingAddressEthNetworks: number[];
  missingAddressIndexes: number[];
  missingWalletBlockchains: string[];
  missingWalletEthNetworks: number[];
  missingWalletIndexes: number[];
}) {
  logDataEvent(
    'accounts.find_accounts_that_need_wallets_result',
    {
      loggingId: '7e292620-cb5d-434b-af3b-85e9c8644e2b',
      accountsThatNeedWalletsLength,
      accountsThatWereCorruptLength,
      accountsLength,
      hasMissingAddresses,
      hasMissingWallets,
      missingAddressBlockchains,
      missingAddressEthNetworks,
      missingAddressIndexes,
      missingWalletBlockchains,
      missingWalletEthNetworks,
      missingWalletIndexes,
    },
    AnalyticsEventImportance.high,
  );
}

export function logHadCorruptWallets({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue;
}) {
  logDataEvent('accounts.find_accounts_that_need_wallets_had_corrupt_wallets', {
    loggingId: 'f812fa24-1137-478a-9d9b-e6ec1064e560',
    accountType,
  });
}

export function logCreateWalletsForAccountsStart({
  accountsThatNeedWalletsLength,
  mnemonicsLength,
}: {
  accountsThatNeedWalletsLength: number;
  mnemonicsLength: number;
}) {
  logDataEvent(
    'accounts.create_wallets_for_accounts_start',
    {
      loggingId: '9fa5597d-da2c-4dd6-b18d-214233c5d522',
      accountsThatNeedWalletsLength,
      mnemonicsLength,
    },
    AnalyticsEventImportance.high,
  );
}

export function logCreateWalletsForAccountsResult({
  accountsThatNeedWalletsLength,
  mnemonicsLength,
}: {
  accountsThatNeedWalletsLength: number;
  mnemonicsLength: number;
}) {
  logDataEvent(
    'accounts.create_wallets_for_accounts_result',
    {
      loggingId: 'd72a156d-191d-41d0-8741-000871709a65',
      accountsThatNeedWalletsLength,
      mnemonicsLength,
    },
    AnalyticsEventImportance.high,
  );
}
// Wallet Creation end

export function logMultiAccountAnnouncementPressed() {
  logEvent('accounts.multi_account_announcement.pressed', {
    loggingId: 'a4c62468-b261-4cf2-8c08-4ad24151c504',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logMultiAccountAnnouncementDismissed() {
  logEvent('accounts.multi_account_announcement.dismissed', {
    loggingId: 'a5ea2c7e-b9bc-4c85-923f-1929b99d5e5d',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logMultiAccountAnnouncementViewed() {
  logEvent('accounts.multi_account_announcement.viewed', {
    loggingId: '56466db5-3809-462c-b1e1-e88558a727be',
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

/**
 * Log when Claim DID button is pressed from Wallet Group Settings
 */
export function logClaimDIDFromSettings() {
  logDataEvent('accounts.claim_did_from_settings.pressed', {
    loggingId: 'cd8c8bc2-d508-42bc-804c-3ace6769ad40',
  });
}

/*
 * Log when the user views the Show Private Key screen
 */
export function useShowPrivateKeyViewed() {
  return useLogEventOnMount('accounts.settings_show_private_key.viewed', {
    loggingId: 'a3488a34-9928-4c15-adcf-a011e3390177',
    componentType: ComponentType.page,
  });
}

export function logSaveAndSetAccountStarted() {
  logDataEvent('accounts.save_and_set.started', {
    loggingId: '712a8250-df8b-463b-bb5b-435ef9fb9eca',
  });
}

export function logSaveAndSetAccountFailed() {
  logDataEvent('accounts.save_and_set.failed', {
    loggingId: 'bafef7b1-2b44-4fb6-b999-88df5f68e8d0',
  });
}
export function logSaveAndSetAccountSuccess() {
  logDataEvent('accounts.save_and_set.success', {
    loggingId: '0b54b783-8b25-4f90-bbdf-8205c92050a2',
  });
}

export function logBackupAnnouncementCardViewed() {
  logEvent('accounts.backup_announcement_card.viewed', {
    loggingId: '175840fc-405d-4f88-87d5-64f196d4d7e4',
    action: ActionType.view,
    componentType: ComponentType.banner,
  });
}

export function logBackupAnnouncementCardPressed() {
  logEvent('accounts.backup_announcement_card.pressed', {
    loggingId: '8ffa2755-07ea-43e6-96ca-a387f877fa38',
    action: ActionType.view,
    componentType: ComponentType.banner,
  });
}

export function triggerBackupMnemonicWalletPressed() {
  logEvent('accounts.backup_mnemonic_wallet.pressed', {
    action: ActionType.view,
    componentType: ComponentType.button,
  });
}

// Full Screen Backup Reminder

export function useFullScreenBackupReminderViewed() {
  useLogEventOnMount('accounts.full_screen_backup_reminder.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function triggerFullScreenBackupNowPressed() {
  logEvent('accounts.full_screen_backup_reminder.backup_now.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerFullScreenBackupNotNowPressed() {
  logEvent('accounts.full_screen_backup_reminder.not_now.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerFullScreenBackupNowTrayPressed() {
  logEvent('accounts.full_screen_backup_reminder.backup_now_tray.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function triggerFullScreenBackupRemindMeLaterPressed() {
  logEvent('accounts.full_screen_backup_reminder.remind_me_later_tray.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

// Balance Scanning

/**
 * Triggers a start event for the balance scanning process, which will
 * fill in missing wallet group automatically, after importing a mnemonic.
 */
export function triggerBalanceScanForAccountStart({
  userFacingAccountIndexByCreatedAt,
}: {
  /** Corresponds current account to order within user-facing accounts, as an index starting from 0 */
  userFacingAccountIndexByCreatedAt: number;
}) {
  logDataEvent('accounts.balance_scan_for_account.start', {
    data: {
      userFacingAccountIndexByCreatedAt,
    },
  });
}

/**
 * Triggers an end event for the balance scanning process, which will
 * fill in missing wallet group automatically, after importing a mnemonic.
 */
export function triggerBalanceScanForAccountResult({
  userFacingAccountIndexByCreatedAt,
  createdWalletGroupsLength,
  createdWalletGroupIndexes,
}: {
  /** Corresponds current account to order within user-facing accounts, as an index starting from 0 */
  userFacingAccountIndexByCreatedAt: number;

  /** Total number wallet groups created, where balances were found on corresponding indexes */
  createdWalletGroupsLength: number;

  /** All indexes, that wallet groups were automatically created for */
  createdWalletGroupIndexes: number[];
}) {
  logDataEvent('accounts.balance_scan_for_account.result', {
    data: {
      userFacingAccountIndexByCreatedAt,
      createdWalletGroupsLength,
      createdWalletGroupIndexes: createdWalletGroupIndexes.sort(),
    },
  });
}

/**
 * Triggers a fail event for the balance scanning process, which will
 * fill in missing wallet group automatically, after importing a mnemonic.
 *
 * Note: Failed event means that an error was thrown, and NOT that no
 * results were found during scanning.
 */
export function triggerBalanceScanForAccountFailed({
  userFacingAccountIndexByCreatedAt,
}: {
  /** Corresponds current account to order within user-facing accounts, as an index starting from 0 */
  userFacingAccountIndexByCreatedAt: number;
}) {
  logDataEvent('accounts.balance_scan_for_account.failed', {
    data: {
      userFacingAccountIndexByCreatedAt,
    },
  });
}

/**
 * User/Device App-wide Observability
 */
/**
 * This function logs session identifiers to debug if there are downstream events
 * with unexpected deviceId or userId. There is no start event.
 *
 * @param deviceId
 * @param userId
 */
export function logSetAnalyticsSessionMetadataSuccess({
  deviceId,
  locale,
  userId,
}: {
  deviceId: string;
  locale: string;
  userId?: string;
}) {
  logDataEvent(
    'accounts.set_analytics_session_metadata.success',
    {
      deviceId,
      locale,
      userId,
    },
    AnalyticsEventImportance.high,
  );
}

export function logHandleUserChangeStart({ userId }: { userId?: string }) {
  logDataEvent(
    'accounts.handle_user_change.start',
    {
      userId,
    },
    AnalyticsEventImportance.high,
  );
}

export function logHandleUserChangeSuccess({ userId }: { userId?: string }) {
  logDataEvent(
    'accounts.handle_user_change.success',
    {
      userId,
    },
    AnalyticsEventImportance.high,
  );
}

export function logHandleUserChangeFailure({
  userId,
  errorMessage,
}: {
  userId?: string;
  errorMessage: string;
}) {
  logDataEvent(
    'accounts.handle_user_change.failure',
    {
      userId,
      errorMessage,
    },
    AnalyticsEventImportance.high,
  );
}
/**
 * User/Device App-wide Observability End
 */

export function triggerDeleteMnemonicAccountPostDeletion(
  props: Pick<MultiAccountDataEventProps, 'walletsLength'> = {},
) {
  logDataEvent('accounts.delete_mnemonic_account.post_deletion', { ...props });
}

// Derivation and Signing Methods
// Used primarily during, and immediately following, onboarding + MMA flows for account creation

export const FROM_NATIVE_MODULE = 'from_native_module';
export const FROM_WALLET_ENGINE = 'from_wallet_engine';

type DerivationMethodSource = typeof FROM_NATIVE_MODULE | typeof FROM_WALLET_ENGINE;
type DerivationMethodResult = 'success' | 'failure';
type SigningMethodSource = typeof FROM_NATIVE_MODULE | typeof FROM_WALLET_ENGINE;
type SigningMethodResult = 'success' | 'failure';

export function triggerDeriveEthereumAddressFromPublicKeyStart({ platform }: { platform: string }) {
  logDataEvent('accounts.derive_ethereum_address_from_public_key.start', {
    data: { platform },
  });
}

export function triggerDeriveEthereumAddressFromPublicKeyResult({
  platform,
  source,
  result,
}: {
  platform: string;
  source: DerivationMethodSource;
  result: DerivationMethodResult;
}) {
  logDataEvent('accounts.derive_ethereum_address_from_public_key.result', {
    data: { platform, source, result },
  });
}

export function triggerDeriveEthereumPublicKeyFromSeedStart({ platform }: { platform: string }) {
  logDataEvent('accounts.derive_ethereum_public_key_from_seed.start', {
    data: { platform },
  });
}

export function triggerDeriveEthereumPublicKeyFromSeedResult({
  platform,
  source,
  result,
}: {
  platform: string;
  source: DerivationMethodSource;
  result: SigningMethodResult;
}) {
  logDataEvent('accounts.derive_ethereum_public_key_from_seed.result', {
    data: { platform, source, result },
  });
}

export function triggerSignEthereumMessageFromSeedStart({ platform }: { platform: string }) {
  logDataEvent('accounts.sign_ethereum_message_from_seed.start', {
    data: { platform },
  });
}

export function triggerSignEthereumMessageFromSeedResult({
  platform,
  source,
  result,
}: {
  platform: string;
  source: SigningMethodSource;
  result: SigningMethodResult;
}) {
  logDataEvent('accounts.sign_ethereum_message_from_seed.result', {
    data: { platform, source, result },
  });
}

export function triggerDeriveLegacyAddressFromXpubKeyStart({ platform }: { platform: string }) {
  logDataEvent('accounts.derive_legacy_address_from_xpub_key.start', {
    data: { platform },
  });
}

export function triggerDeriveLegacyAddressFromXpubKeyResult({
  platform,
  source,
  result,
}: {
  platform: string;
  source: DerivationMethodSource;
  result: DerivationMethodResult;
}) {
  logDataEvent('accounts.derive_legacy_address_from_xpub_key.result', {
    data: { platform, source, result },
  });
}

export function triggerDeriveSegwitAddressFromXpubKeyStart({ platform }: { platform: string }) {
  logDataEvent('accounts.derive_segwit_address_from_xpub_key.start', {
    data: { platform },
  });
}

export function triggerDeriveSegwitAddressFromXpubKeyResult({
  platform,
  source,
  result,
}: {
  platform: string;
  source: DerivationMethodSource;
  result: DerivationMethodResult;
}) {
  logDataEvent('accounts.derive_segwit_address_from_xpub_key.result', {
    data: { platform, source, result },
  });
}

// Derivation and Signing Methods End

/**
 * Post onboarding recovery phrase created tray - Begin
 */
export function logRecoveryPhraseTrayLaterPressed() {
  logEvent('recovery_phrase_tray.later.pressed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function logRecoveryPhraseTrayBackupPressed() {
  logEvent('recovery_phrase_tray.claim.pressed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function useLogRecoveryPhraseTrayViewed() {
  useLogEventOnMount('recovery_phrase_tray.viewed', {
    componentType: ComponentType.tray,
    action: ActionType.view,
  });
}

/**
 * Post onboarding recovery phrase created tray - End
 */

export function logGetEncryptedSecretsStart() {
  logDataEvent('accounts.get_encrypted_secrets.start', {});
}

export function logGetEncryptedSecretsResult({
  encryptedSecretsLength,
  isEncryptedSecretsUndefined,
  isLocalStorageUndefined,
  localStorageLength,
}: {
  encryptedSecretsLength: number;
  isEncryptedSecretsUndefined: boolean;
  isLocalStorageUndefined: boolean;
  localStorageLength: number;
}) {
  logDataEvent('accounts.get_encrypted_secrets.result', {
    data: {
      encryptedSecretsLength,
      isEncryptedSecretsUndefined,
      isLocalStorageUndefined,
      localStorageLength,
    },
  });
}

// Post cleanup repair scripts
export function triggerDeleteSoftDeletedAccountStart({ num }: { num: number }) {
  logDataEvent('app-load.delete-soft-deleted-account.start', {
    data: { num },
  });
}

export function triggerDeleteSoftDeletedAccountEnd({ num }: { num: number }) {
  logDataEvent('app-load.delete-soft-deleted-account.end', {
    data: { num },
  });
}

export function triggerDeleteFullDeletedAccountStart({ num }: { num: number }) {
  logDataEvent('app-load.delete-full-deleted-account.start', {
    data: { num },
  });
}

export function triggerDeleteFullDeletedAccountEnd({ num }: { num: number }) {
  logDataEvent('app-load.delete-full-deleted-account.end', {
    data: { num },
  });
}

export function triggerDappProviderUserIdBackfill({ userId }: { userId: string }) {
  logDataEvent('accounts.dapp_provider_user_id.backfill', {
    data: { userId },
  });
}

export function triggerWalletConnectionSuccess({
  provider,
  providerUserId,
}: {
  provider: string;
  providerUserId: string;
}) {
  logDataEvent('accounts.wallet_connection.success', {
    provider,
    providerUserId,
  });
}
