/* eslint camelcase: 0 */

import { createExperiments } from '@cbhq/experiments';

export const experiments = createExperiments({
  june_2022_rn_wallet_nux_preset_buy: [
    'control',
    'assets-eth',
    'wallet-eth',
    'assets-matic',
  ] as const,
  oct_2022_wallet_attestations: ['control', 'treatment'] as const,
  nov_2022_transak_cbpay: ['control', 'treatment'] as const,
  nov_2022_instagram_dapp_no_collections: ['control', 'treatment'] as const,
  nov_2022_cbpay_guest_checkout: ['control', 'treatment'] as const,
  jan_2023_nft_pfp: ['control', 'treatment'] as const,
  jan_2023_wallet_nft_native_buy: ['control', 'treatment'] as const,
  jan_2023_quick_buy_eth: ['control', 'treatment'] as const,
  feb_2023_rn_max_button_for_eth_non_1559_assets: ['control', 'treatment'] as const,
  feb_2023_stake_top_up: ['control', 'treatment'] as const,
  feb_2023_stake: ['control', 'treatment'] as const,
  feb_2023_extension_max_button_for_eth_non_1559_assets: ['control', 'treatment'] as const,
  feb_2023_mint_streak_rn: ['control', 'treatment'] as const,
  feb_2023_weth_stake: ['control', 'treatment'] as const,
  feb_2023_public_key_export_announcement: ['control', 'treatment'] as const,
  feb_2023_wallet_real_time_send_gas_fee_rn: ['control', 'tratment'] as const,
  feb_2023_wallet_real_time_send_gas_fee_extension: ['control', 'treatment'] as const,
  mar_2023_public_key_export_new_tag: ['control', 'treatment'] as const,
  mar_2023_private_pool: ['control', 'treatment'] as const,
  apr_2023_wallet_mobile_messaging: ['control', 'treatment'] as const,
  jun_2023_gasless_swap_polygon: ['control', 'treatment'] as const,
  mar_2023_wallet_extension_recipient_screen_new_tab_view: ['control', 'treatment'] as const,
  mar_2023_wallet_rn_recipient_screen_new_tab_view: ['control', 'treatment'] as const,
  mar_2023_utxo_extension: ['control', 'treatment'] as const,
  apr_2023_web3_starter_kit_rn: ['control', 'treatment'] as const,
  apr_2023_quest_reward_pill: ['control', 'treatment'] as const,
  may_2023_unified_login_rn: ['control', 'treatment'] as const,
  apr_2023_did_ma_android: ['control', 'treatment'] as const,
  apr_2023_did_ma_extension: ['control', 'treatment'] as const,
  apr_2023_did_ma_ios: ['control', 'treatment'] as const,
  may_2023_utxo_banner_ios: ['control', 'treatment'] as const,
  may_2023_utxo_banner_android: ['control', 'treatment'] as const,
  may_2023_wallet_rn_search_receive_asset: ['control', 'treatment'] as const,
  may_2023_wallet_extension_search_receive_asset: ['control', 'treatment'] as const,
  may_2023_wallet_music_nfts: ['control', 'treatment'] as const,
  may_2023_wallet_rn_retail_address_creation_fallback: ['control', 'treatment'] as const,
  may_2023_wallet_backend_nft_chain_config: ['control', 'treatment'] as const,
  jun_2023_wallet_edit_qr_code_rn: ['control', 'treatment'] as const,
  jun_2023_wallet_edit_qr_code_extension: ['control', 'treatment'] as const,
  jun_2023_wallet_rn_standardized_qr_codes: ['control', 'treatment'] as const,
  jun_2023_wallet_extension_standardized_qr_codes: ['control', 'treatment'] as const,
  june_2023_fund_and_swap_on_explore: ['control', 'treatment'] as const,
  aug_2023_wallet_rn_global_trays_on_qr_code_scan: ['control', 'treatment'] as const,
  aug_2023_standardize_errors_dex: ['control', 'treatment'] as const,
  sept_2023_wallet_nft_grid_views: ['control', 'treatment'] as const,
  aug_2023_wallet_rn_gas_tracker: ['control', 'treatment'] as const,
  sep_2023_delegator_staking: ['control', 'treatment'] as const,
  sep_2023_messaging_suggestions: ['control', 'treatment'] as const,
  sep_2023_human_readable_transactions_client: ['control', 'treatment'] as const,
  oct_2023_top_up_all_supported_assets_rn: ['control', 'treatment'] as const,
  oct_2023_address_scan_rn: ['control', 'treatment'] as const,
  oct_2023_address_scan_extension: ['control', 'treatment'] as const,
  oct_2023_show_notifications_tray_post_onboarding_rn: ['control', 'treatment'] as const,
  oct_2023_create_wallet: [
    'control',
    'did_claim_takeover',
    'did_claim_tray',
    'recovery_phrase_tray',
  ] as const,
  oct_2023_wallet_settings_hub_notif_optin_rn: ['control', 'treatment'] as const,
  sep_2023_message_scanner_extension: ['control', 'treatment'] as const,
  sep_2023_message_scanner_ios: ['control', 'treatment'] as const,
  sep_2023_message_scanner_android: ['control', 'treatment'] as const,
  dec_2023_recent_recipients_tab_v3: ['control', 'treatment'] as const,
  nov_2023_wallet_extension_promotion_announcement: ['control', 'treatment'] as const,
  nov_2023_send_via_link_rn: ['control', 'treatment'] as const,
  nov_2023_nft_incentive_with_usdc: ['control', 'treatment'] as const,
  nov_2023_explore_defi: ['control', 'treatment'] as const,
  nov_2023_rp_secure_indicator: ['control', 'treatment_a', 'treatment_b', 'treatment_c'] as const,
  nov_2023_message_attachment_upload: ['control', 'treatment'] as const,
  nov_2023_guided_import_paths: ['control', 'treatment'] as const,
  dec_2023_nft_ticketing: ['control', 'treatment'] as const,
  dec_2023_streaks_v2: ['control', 'treatment'] as const,
  dec_2023_wallet_quests_intake_card: ['control', 'treatment'] as const,
  dec_2023_send_via_link_send_input_option: ['control', 'treatment'] as const,
  dec_2023_account_limit_increase: ['control', 'treatment'] as const,
  jan_2024_wallet_app_architecture: ['control', 'treatment'] as const,
  jan_2024_gasless_send_confirmation_upsell_rn: ['control', 'treatment'] as const,
  jan_2024_gasless_send_success_upsell_rn: ['control', 'treatment'] as const,
  jan_2024_wallet_global_search_mobile: ['control', 'treatment'] as const,
  feb_2024_asset_metadata_v4_endpoint: ['control', 'treatment'] as const,
  mar_2024_wallet_native_assets_claim_links_optimism_arbitrum: ['control', 'treatment'] as const,
  q1_2024_cashout_us: ['control', 'treatment'] as const,
  q2_2024_cashout_intl_providers: ['control', 'treatment'] as const,
  q2_cashout_retail_fiat_account_expansion: ['control', 'treatment'] as const,
  dec_2023_walletlink_gns_migration: ['control', 'treatment'] as const,
  feb_2024_top_up_for_web3_transactions: ['control', 'treatment'] as const,
  mar_2024_wallet_app_architecture_messaging: ['control', 'treatment'] as const,
  mar_2024_open_frames_in_messaging: ['control', 'treatment'] as const,
  mar_2024_user_events_action_service: ['control', 'treatment'] as const,
  mar_2024_txn_complete_notif_optin: ['control', 'treatment'] as const,
  mar_2024_wallet_notif_library: ['control', 'treatment'] as const,
  mar_2024_transaction_preview_solana: ['control', 'treatment'] as const,
  mar_2024_wallet_nux_eth_on_base: ['control', 'treatment'] as const,
  mar_2024_wallet_rn_global_tray_provider: ['control', 'treatment'] as const,
  mar_2024_wallet_rn_default_virtual_tray: ['control', 'treatment'] as const,
  apr_2024_wallet_rn_user_interests_quiz: ['control', 'treatment'] as const,
  apr_2024_wallet_redesign_miami_vice: ['control', 'treatment'] as const,
  apr_2024_wallet_onramp_swap_assets: ['control', 'treatment'] as const,
  apr_2024_messaging_xmtp_rn_sdk_listener: ['control', 'treatment'] as const,
  apr_2024_wallet_send_address_poisoning_warning_rn: ['control', 'treatment'] as const,
  apr_2024_wallet_send_address_poisoning_warning_extension: ['control', 'treatment'] as const,
  apr_2024_wallet_global_search_using_wac: ['control', 'treatment'] as const,
  apr_2024_wallet_redesign_miami_vice_panorama: ['control', 'treatment'] as const,
  may_2024_wallet_onramp_native_buy: ['control', 'treatment'] as const,
  may_2024_add_fund_web3_starter_kit_action: ['control', 'treatment'] as const,
  may_2024_cross_chain_swap: ['control', 'treatment'] as const,
  may_2024_tron_usdt_receive: ['control', 'treatment'] as const,
  may_2024_social_page_web: ['control', 'treatment'] as const,
  may_2024_explore_on_home_redesign: ['control', 'treatment'] as const,
  may_2024_watchlist_notif_opt_in: ['control', 'treatment'] as const,
  may_2024_social_native_mint: ['control', 'treatment'] as const,
  may_2024_warm_welcome: ['control', 'treatment'] as const,
  jun_2024_swap_mode_best: ['control', 'treatment'] as const,
  jun_2024_import_mnemonic_warning_rn: ['control', 'treatment'] as const,
  jun_2024_import_mnemonic_warning_extension: ['control', 'treatment'] as const,
  jun_2024_assets_percentage_change: ['control', 'treatment'] as const,
  jun_2024_add_to_watchlist_tray: ['control', 'treatment'] as const,
  jun_2024_scw_recovery: ['control', 'treatment'] as const,
  jun_2024_scw_magic_link: ['control', 'treatment'] as const,
  jun_2024_mev_experiment: ['control', 'treatment'] as const,
  jun_2024_mesh_connect: ['control', 'treatment'] as const,
  jul_2024_restore_wallet_v2_rn: ['control', 'treatment'] as const,
  jul_2024_app_mode_notification_preferences: ['control', 'treatment'] as const,
  jul_2024_scw_onboarding_fund_wallet: ['control', 'treatment'] as const,
  jul_2024_onramp_cb_native_buy: ['control', 'treatment'] as const,
  jul_2024_scw_onboarding_close: ['control', 'treatment'] as const,
  jul_2024_tx_confirmation_rearchitecture_swap: ['control', 'treatment'] as const,
  jul_2024_scw_import_rn: ['control', 'treatment'] as const,
  jul_2024_wallet_stripe_onramp_integration: ['control', 'treatment'] as const,
  jul_2024_pano_swap_disconnected_state: ['control', 'treatment'] as const,
  jul_2024_wallet_rn_tx_confirmation_rearchitecture_send: ['control', 'treatment'] as const,
  aug_2024_van_accounts: ['control', 'treatment'] as const,
  aug_2024_wallets_rn_filter_network: ['control', 'treatment'] as const,
  jul_2024_walletlink_modal_v2: ['control', 'treatment'] as const,
  jul_2024_wallet_rn_tx_confirmation_rearchitecture_bridge: ['control', 'treatment'] as const,
  q3_cashout_supported_assets_v2: ['control', 'treatment'] as const,
  aug_2024_transactions_pagination: ['control', 'treatment'] as const,
  q3_erc20_to_ach_expansion: ['control', 'treatment'] as const,
  aug_2024_deeplink_v2: ['control', 'treatment'] as const,
  aug_2024_explore_refresh: ['control', 'treatment'] as const,
  aug_2024_introducing_smart_wallet_mint: ['control', 'treatment'] as const,
  sep_2024_wallet_social_feed: ['control', 'treatment'] as const,
  aug_2024_wallet_hide_no_quote_with_low_balance: ['control', 'treatment'] as const,
  aug_2024_wallet_pfp: ['control', 'treatment'] as const,
  aug_2024_scw_webauthn_p256_authentication: ['control', 'treatment'] as const,
  aug_2024_real_time_swaps: ['control', 'treatment'] as const,
  sep_2024_signout_flow_v2: ['control', 'treatment'] as const,
  sep_2024_wallet_rn_tx_confirmation_rearchitecture_web3: ['control', 'treatment'] as const,
  sep_2024_defi_tab_all_networks_rn: ['control', 'treatment'] as const,
  sep_2024_social_feed_mint: ['control', 'treatment'] as const,
  sept_2024_trading_activity_adp: ['control', 'treatment'] as const,
  sep_2024_scw_session_keys: ['control', 'treatment'] as const,
  sep_2024_van_accounts_us: ['control', 'treatment'] as const,
  sep_2024_van_accounts_global: ['control', 'treatment'] as const,
  q3_usdc_instant_ach: ['control', 'treatment'] as const,
  q3_sponsored_cashout: ['control', 'treatment'] as const,
});

export { experiments as Experiments };
