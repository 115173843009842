import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  logBalanceFetchDurationByBlockchain,
  logCreateUTXOWalletComplete,
  logCreateUTXOWalletStart,
  logUTXODroppedAddresses,
  logUTXOGenericError,
} from 'cb-wallet-analytics/balances/Balances';
import { addAddressesToHistoryListener } from 'cb-wallet-data/AddressHistory/utils/addAddressesToUTXOHistoryListeners';
import { storeLastSyncedBlockheight } from 'cb-wallet-data/AddressHistory/utils/blockheightSyncing';
import { storeThrottleRequestLastUpdated } from 'cb-wallet-data/AddressHistory/utils/getThrottleRequestLastUpdated';
import { getUTXOAddressesForAccount } from 'cb-wallet-data/AddressHistory/utils/getUTXOAddressesForAccount';
import {
  logUTXOAddressCreation,
  logUTXOAddressScanning,
} from 'cb-wallet-data/AddressHistory/utils/UTXOBalanceAnalytics';
import {
  blockchainConfigurations,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { UTXOConfiguration } from 'cb-wallet-data/chains/UTXO/models/UTXOConfiguration';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getAccounts } from 'cb-wallet-data/stores/Accounts/database';
import { useAccounts } from 'cb-wallet-data/stores/Accounts/hooks/useAccounts';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { getAddressesForBlockchain, saveAddresses } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { AddressType } from 'cb-wallet-data/stores/Addresses/models/AddressType';
import { usePaginatedTransactions } from 'cb-wallet-data/stores/Transactions/hooks/usePaginatedTransactions';
import { syncTransactions } from 'cb-wallet-data/stores/Transactions/hooks/useSyncTransactionHistory';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { getWalletsOfBlockchainRecord, saveWallets } from 'cb-wallet-data/stores/Wallets/database';
import { useSetLastBalanceUpdateMetadata } from 'cb-wallet-data/stores/Wallets/hooks/useLastBalanceUpdateMetadata';
import {
  setBlockchainHasBalance,
  setIndexesToSync,
  useSetBlockchainIndexesSynced,
} from 'cb-wallet-data/stores/Wallets/hooks/useZeroBalanceTracking';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { WalletAddress } from 'cb-wallet-data/stores/Wallets/models/WalletAddress';
import {
  blockchainIsRefreshedMapAtom,
  blockchainIsSyncedMapAtom,
} from 'cb-wallet-data/stores/Wallets/state';
import { getTimer } from 'cb-wallet-data/utils/globalTimer';
import keyBy from 'lodash/keyBy';
import { useSetRecoilState } from 'recoil';
import { HistoryEventUpdate } from 'wallet-engine-signing/history';
import {
  DroppedAddressesError,
  UTXOBalanceSyncingError,
} from 'wallet-engine-signing/history/errors';
import { UTXOAddressHistory } from 'wallet-engine-signing/history/UTXO/types';

import { UTXOAddressHistoryListeners } from '../listeners';

type HandleUTXOAddressUpdateParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  updates: UTXOAddressHistory[];
  blockheight: number;
  updatePaginatedTransactions: (transactions: TxOrUserOp[]) => void;
  isBalanceFetchingThrottleEnabled: boolean;
  isUTXOTransactionBlockheightSyncingEnabled: boolean;
};

export async function handleAddressUpdate({
  blockchainSymbol,
  updates,
  blockheight,
  updatePaginatedTransactions,
  isBalanceFetchingThrottleEnabled,
  isUTXOTransactionBlockheightSyncingEnabled,
}: HandleUTXOAddressUpdateParams) {
  const accounts = (await getAccounts()).filter((account) => account.supportsUTXO);
  const configuration = blockchainConfigurations[blockchainSymbol];
  const existingAddresses = await getAddressesForBlockchain({
    blockchain: configuration.blockchain,
    network: configuration.networkSetting.defaultMainnet.network,
  });

  accounts.forEach((account) => {
    storeThrottleRequestLastUpdated(blockchainSymbol, Date.now(), account.id);
  });

  const addressesToSave: Address[] = [];
  const existingAddressMap = keyBy(
    existingAddresses,
    (address) => `${address.address}/${address.accountId}`,
  );

  updates.forEach(function getAddressesToUpdate(update) {
    const accountId = update.context!.accountId;
    const existingAddress = existingAddressMap[`${update.address}/${accountId}`];

    if (!existingAddress) {
      // In the case where the address was deleted, don't try to update it
      return;
    }

    if (update?.balance > 0n && update?.isUsed) {
      setBlockchainHasBalance(blockchainSymbol, true);
    }

    if (existingAddress.balance !== update.balance || existingAddress.isUsed !== update.isUsed) {
      addressesToSave.push(
        new Address({
          index: existingAddress.index,
          address: existingAddress.address,
          balance: update.balance,
          currencyCode: existingAddress.currencyCode,
          isChangeAddress: existingAddress.isChangeAddress,
          network: existingAddress.network,
          derivationPath: existingAddress.derivationPath,
          isUsed: update.isUsed,
          blockchain: existingAddress.blockchain,
          type: existingAddress.type,
          accountId,
        }),
      );
    }
  });

  if (addressesToSave.length) {
    await saveAddresses(addressesToSave);
  }

  await Promise.allSettled(
    accounts.map(async function updateAddressesOfBlockchainForAccount(account) {
      // On Pano we have an account per connected chain/wallet, so we can skip blockchains
      // not connected to the account
      if (
        account.type === AccountType.DAPP_PROVIDER &&
        account.primaryAddressChain !== blockchainSymbol
      ) {
        return;
      }

      try {
        const { existingAddresses: updatedExistingAddresses, newAddresses } =
          await getUTXOAddressesForAccount({
            blockchainSymbol,
            accountId: account.id,
          });

        if (newAddresses.length) {
          addAddressesToHistoryListener({
            addresses: newAddresses,
            blockchainSymbol,
            accountId: account.id,
            resetBlockheight: true,
            isBalanceFetchingThrottleEnabled,
          });
        } else if (updatedExistingAddresses.length) {
          // If no new addresses, new balance scanning is done and we can update wallet
          await createOrUpdateWallets({
            blockchainSymbol,
            accountId: account.id,
            addressesForBlockchainOfAccount: updatedExistingAddresses,
          });

          storeLastSyncedBlockheight({
            blockchainSymbol,
            accountId: account.id,
            lastSyncedBlockheight: blockheight,
            storeTransactionsBlockheight: false,
          });
        }
      } catch (err: unknown) {
        cbReportError({
          context: 'utxo_balance_scanning',
          metadata: {
            numAddresses: existingAddresses.length,
            blockchainSymbol,
            accountId: account.id,
            step: 'wallet_update',
          },
          error: err as Error,
          isHandled: false,
          severity: 'error',
        });

        logUTXOGenericError(blockchainSymbol);
      }
    }),
  );

  refreshTransactions({
    blockchainSymbol,
    updatePaginatedTransactions,
    isUTXOTransactionBlockheightSyncingEnabled,
  });
}

type RefreshTransactionsParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  isUTXOTransactionBlockheightSyncingEnabled: boolean;
  updatePaginatedTransactions: (transactions: TxOrUserOp[]) => void;
};

async function refreshTransactions({
  blockchainSymbol,
  isUTXOTransactionBlockheightSyncingEnabled,
  updatePaginatedTransactions,
}: RefreshTransactionsParams) {
  const existingWalletsRecord = await getWalletsOfBlockchainRecord(blockchainSymbol);
  syncTransactions({
    syncableWallets: Object.values(existingWalletsRecord),
    isUTXOTransactionBlockheightSyncingEnabled,
    updatePaginatedTransactions,
  });
}

type UseUTXOAddressHistoryParams = {
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
};

export function useUTXOAddressHistory({
  skipBalanceRefresh,
  skipTransactionRefresh,
}: UseUTXOAddressHistoryParams): () => void {
  const allAccounts = useAccounts();
  const setBlockchainIsRefreshedMap = useSetRecoilState(blockchainIsRefreshedMapAtom);
  const setBlockchainIsSyncedMap = useSetRecoilState(blockchainIsSyncedMapAtom);
  const setBlockchainIndexesSynced = useSetBlockchainIndexesSynced();
  const setLastBalanceUpdateMetadata = useSetLastBalanceUpdateMetadata();
  const isBalanceFetchingThrottleEnabled = useIsFeatureEnabled('balance_fetching_throttle');
  const hasLoggedRefreshMetricByChain = useRef<Set<string>>(new Set());
  const isUTXOTransactionBlockheightSyncingEnabled = useIsFeatureEnabled(
    'utxo_transaction_blockheight_syncing',
  );
  const { updatePaginatedTransactions } = usePaginatedTransactions();

  const accountsWithUTXOSupport = useMemo(
    function memoizeAccountsWithUTXOSupport() {
      return allAccounts.filter((account) => account.supportsUTXO);
    },
    [allAccounts],
  );

  useEffect(function cleanupUTXOAddressHistoryListeners() {
    return function cleanupAddressHistoryListenersOnUmount() {
      Object.values(UTXOAddressHistoryListeners).forEach(function cleanupListener(listener) {
        listener.resetHistoryListener();
      });
    };
  }, []);

  function createUTXOHistoryListeners() {
    setBlockchainIsRefreshedMap(function setNextState(prevState) {
      return Object.keys(UTXOAddressHistoryListeners).reduce(
        function reduceNextState(acc, blockchainSymbol) {
          // Set isRefreshed state to TRUE for every UTXO blockchain, if there's no accounts to fetch UTXO balances for.
          // Prevents user from seeing any pending refresh states for UTXO, if not relevant to their account type.
          acc[blockchainSymbol] = !accountsWithUTXOSupport.length;
          return acc;
        },
        { ...prevState },
      );
    });

    // Register all UTXO-enabled accounts with listeners on each UTXO chain
    Object.entries(UTXOAddressHistoryListeners).forEach(function registerUTXOListeners([
      blockchainSymbol,
      addressHistoryListener,
    ]) {
      accountsWithUTXOSupport.forEach(async function createListenersForAccount(account) {
        // On Pano we have an account per connected chain/wallet, so we can skip blockchains
        // not connected to the account
        if (
          account.type === AccountType.DAPP_PROVIDER &&
          account.primaryAddressChain !== blockchainSymbol
        ) {
          return;
        }

        const { existingAddresses, newAddresses } = await getUTXOAddressesForAccount({
          blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
          accountId: account.id,
        });

        logUTXOAddressCreation({
          existingAddresses,
          newAddresses,
          blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
          accountId: account.id,
        });

        const addresses = [...existingAddresses, ...newAddresses];

        if (addresses.length) {
          addAddressesToHistoryListener({
            addresses,
            blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
            accountId: account.id,
            isBalanceFetchingThrottleEnabled,
            skipBalanceRefresh,
            skipTransactionRefresh,
          });
        }
      });

      const startTime = performance.now();

      addressHistoryListener.addEventListener(
        'balance',
        async function handleInitUTXOAddressHistoryEvent(ev: HistoryEventUpdate) {
          const { updates, blockheight, interval, errors } = ev;

          // Only log the initial refresh per blockchain
          if (!hasLoggedRefreshMetricByChain.current.has(blockchainSymbol) && interval === 'init') {
            logBalanceFetchDurationByBlockchain({
              startTime,
              blockchain: blockchainSymbol,
              getAppBackgroundStart: () => getTimer('timer.AppBackgroundStart'),
              getAppBackgroundEnd: () => getTimer('timer.AppBackgroundEnd'),
            });

            logUTXOAddressScanning({
              blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
              updates,
            });

            hasLoggedRefreshMetricByChain.current.add(blockchainSymbol);
          }

          if (errors?.length) {
            errors.forEach(function logUTXOErrors(
              error: DroppedAddressesError | UTXOBalanceSyncingError,
            ) {
              cbReportError({
                context: 'utxo_balance_history_event',
                metadata: {
                  numAddresses: updates.length,
                  blockchainSymbol,
                  step: 'balance_update',
                },
                error,
                isHandled: false,
                severity: 'error',
              });

              if (error instanceof DroppedAddressesError) {
                logUTXODroppedAddresses({
                  chainName: blockchainSymbol,
                  failureRate: error.failureRate,
                });
              }
            });

            logUTXOGenericError(blockchainSymbol);
          }

          await handleAddressUpdate({
            blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
            updates: updates as UTXOAddressHistory[],
            blockheight: blockheight as number,
            isBalanceFetchingThrottleEnabled,
            updatePaginatedTransactions,
            isUTXOTransactionBlockheightSyncingEnabled,
          });

          if (interval === 'init') {
            setBlockchainIsRefreshedMap((curr) => ({
              ...curr,
              [blockchainSymbol]: true,
            }));
            setBlockchainIsSyncedMap((curr) => ({ ...curr, [blockchainSymbol]: true }));
            setBlockchainIndexesSynced(
              blockchainSymbol as PossibleUTXOBlockchainSymbol,
              new Set([0n]),
            );
            setLastBalanceUpdateMetadata({ updatedAt: new Date().getTime(), source: 'init' });
          }
        },
      );

      setIndexesToSync(blockchainSymbol as PossibleUTXOBlockchainSymbol, [0n]);
    });
  }

  return useCallback(createUTXOHistoryListeners, [
    accountsWithUTXOSupport,
    setBlockchainIsRefreshedMap,
    setBlockchainIsSyncedMap,
    setBlockchainIndexesSynced,
    setLastBalanceUpdateMetadata,
    isBalanceFetchingThrottleEnabled,
    isUTXOTransactionBlockheightSyncingEnabled,
    skipBalanceRefresh,
    skipTransactionRefresh,
    updatePaginatedTransactions,
  ]);
}

type AddAddressesToHistoryListenersParams = {
  accountId: Account['id'];
  isBalanceFetchingThrottleEnabled?: boolean;
};

export async function addAddressesToUTXOHistoryListeners({
  accountId,
  isBalanceFetchingThrottleEnabled,
}: AddAddressesToHistoryListenersParams) {
  Object.keys(UTXOAddressHistoryListeners).forEach(async function addAddressesForBlockchain(
    blockchainSymbol,
  ) {
    addAddressesToUTXOHistoryListener({
      accountId,
      blockchainSymbol: blockchainSymbol as PossibleUTXOBlockchainSymbol,
      isBalanceFetchingThrottleEnabled,
    });
  });
}

type AddAddressesToHistoryListenerParams = {
  accountId: Account['id'];
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  isBalanceFetchingThrottleEnabled?: boolean;
};

export async function addAddressesToUTXOHistoryListener({
  accountId,
  blockchainSymbol,
  isBalanceFetchingThrottleEnabled,
}: AddAddressesToHistoryListenerParams) {
  const { existingAddresses, newAddresses } = await getUTXOAddressesForAccount({
    blockchainSymbol,
    accountId,
  });

  const addresses = [...existingAddresses, ...newAddresses];

  if (addresses.length) {
    addAddressesToHistoryListener({
      addresses,
      blockchainSymbol,
      accountId,
      // Reset the blockheight when we add a new account / new addresses
      resetBlockheight: true,
      isBalanceFetchingThrottleEnabled,
    });
  }
}

// TODO (post-launch) we should create the UTXO Wallet when we create the 0-index change/rec address.
// Thats how we do every other wallet and it would eliminate confusion and weird code like this function
// below. But we should do it post launch to keep the experiment clean.

type CreateOrUpdateWalletsParams = {
  blockchainSymbol: PossibleUTXOBlockchainSymbol;
  accountId: Account['id'];
  addressesForBlockchainOfAccount: Address[];
};

// TODO Split this out into its own file
export async function createOrUpdateWallets({
  blockchainSymbol,
  accountId,
  addressesForBlockchainOfAccount,
}: CreateOrUpdateWalletsParams) {
  const configuration = blockchainConfigurations[blockchainSymbol] as UTXOConfiguration;
  const walletsRecord = await getWalletsOfBlockchainRecord(blockchainSymbol);
  const wallets = Object.values(walletsRecord); // Only one wallet should exist per UTXO chain, per account

  const existingWallet = wallets.find((wallet) => {
    return wallet.accountId === accountId;
  });

  if (!existingWallet) {
    logCreateUTXOWalletStart(blockchainSymbol);
  }

  const totalBalance = addressesForBlockchainOfAccount
    .map(({ balance }) => balance)
    .reduce((walletBalance, addressBalance) => {
      return (walletBalance ?? 0n) + (addressBalance ?? 0n);
    }, 0n);

  const receiveAddresses = addressesForBlockchainOfAccount.filter(
    ({ isChangeAddress }) => !isChangeAddress,
  );

  const addressTypesWithNoAddresses: string[] = [];

  const receivableAddresses = configuration.supportedAddressTypes
    .map(function getReceivableAddresses(addressType: AddressType) {
      const addressesForType = receiveAddresses.filter(
        ({ type, isUsed }) => type.rawValue === addressType.rawValue && !isUsed,
      );
      const [nextReceiveAddress] = addressesForType.sort((a, b) => Number(a.index - b.index));

      if (!nextReceiveAddress) {
        addressTypesWithNoAddresses.push(addressType.rawValue);
        return;
      }
      // TODO nextReceiveAddress can be undefined based on bugsnag error reports
      return new WalletAddress(
        nextReceiveAddress.type,
        nextReceiveAddress.address,
        nextReceiveAddress.index,
      );
    })
    .filter((address): address is WalletAddress => !!address);

  const primaryAddress =
    receivableAddresses.find(
      (address) => address.type.rawValue === configuration.defaultReceiveType.rawValue,
    ) ?? receivableAddresses[0];

  if (primaryAddress && receivableAddresses.length) {
    const network = configuration.networkSetting.defaultMainnet.network;
    const wallet = new Wallet({
      primaryAddress: primaryAddress.address,
      addresses: receivableAddresses,
      displayName: configuration.displayName(network),
      currencyCode: configuration.currencyCode,
      imageURL: configuration.imageURL,
      balance: totalBalance,
      decimals: configuration.decimals,
      blockchain: configuration.blockchain,
      network,
      accountId,
    });

    if (!existingWallet || hasWalletChanged(existingWallet, wallet)) {
      await saveWallets([wallet]);
      logCreateUTXOWalletComplete({
        chainName: blockchainSymbol,
        results: 'success',
        missingAddressTypes: addressTypesWithNoAddresses,
      });
    }
  } else {
    logCreateUTXOWalletComplete({
      chainName: blockchainSymbol,
      results: 'failure',
      missingAddressTypes: addressTypesWithNoAddresses,
    });
  }
}

export function hasWalletChanged(existingWallet: Wallet, updatedWallet: Wallet): boolean {
  const balanceChanged = existingWallet.balance !== updatedWallet.balance;
  const addressChanged = existingWallet.primaryAddress !== updatedWallet.primaryAddress;

  const receiveAddressesChanged = existingWallet.addresses.reduce(function hasReceiveAddressChanged(
    hasChanged,
    existingAddress,
  ) {
    const updatedAddress = updatedWallet.addresses.find(
      ({ type }) => existingAddress.type.rawValue === type.rawValue,
    );
    return !updatedAddress || updatedAddress.address !== existingAddress.address || hasChanged;
  },
  false);

  return balanceChanged || addressChanged || receiveAddressesChanged;
}
