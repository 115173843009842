import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { SolanaNetworkMap } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

/**
 * List of supported blockchains which holds the conversion between blockchain code and blockchain name
 * as cbpay uses blockchain name and cbwallet uses blockchain code
 * @currencyCode - Blockchain code provided by wallet
 * @name - Blockchain name used in cbpay
 * @chainId - Account-based blockchain chainId
 */
export const SUPPORTED_BLOCKCHAINS = Object.freeze([
  {
    currencyCode: 'ETH',
    name: 'ethereum',
    chainId: EthereumNetworkMap.whitelisted.ETHEREUM_MAINNET.chainId,
  },
  {
    currencyCode: 'AVAX',
    name: 'avalanche-c-chain',
    chainId: EthereumNetworkMap.whitelisted.AVALANCHE_MAINNET.chainId,
  },
  {
    currencyCode: 'POL',
    name: 'polygon',
    chainId: EthereumNetworkMap.whitelisted.POLYGON_MAINNET.chainId,
  },
  {
    currencyCode: 'OP',
    name: 'optimism',
    chainId: EthereumNetworkMap.whitelisted.OPTIMISM_MAINNET.chainId,
  },
  {
    currencyCode: 'ARB',
    name: 'arbitrum',
    chainId: EthereumNetworkMap.whitelisted.ARBITRUM_MAINNET.chainId,
  },
  {
    currencyCode: 'BASE',
    name: 'base',
    chainId: EthereumNetworkMap.whitelisted.BASE_MAINNET.chainId,
  },
  {
    currencyCode: 'SOL',
    name: 'solana',
    chainId: SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId,
  },
  { currencyCode: 'BTC', name: 'bitcoin', chainId: null },
  { currencyCode: 'DOGE', name: 'dogecoin', chainId: null },
  { currencyCode: 'LTC', name: 'litecoin', chainId: null },
] as const);

/**
 * Map of network code to blockchain, used to determine the blockchain based on the network code
 * @example
 * {
 *  ETH: { currencyCode: 'ETH', name: 'ethereum', chainId: 1 },
 *  BTC: { currencyCode: 'BTC', name: 'bitcoin', chainId: null },
 * }
 */
export const NETWORK_CODE_TO_BLOCKCHAIN_MAP = Object.fromEntries(
  SUPPORTED_BLOCKCHAINS.map((blockchain) => [blockchain.currencyCode, blockchain]),
);

/**
 * Map of chainId to blockchain, used to determine the account-based blockchain based on the chainId
 * @example
 * {
 *  1: { currencyCode: 'ETH', name: 'ethereum', chainId: 1 },
 *  10: { currencyCode: 'OP', name: 'optimism', chainId: 10 },
 *  8453: { currencyCode: 'BASE', name: 'base', chainId: 8453 },
 * }
 *
 */
export const CHAIN_ID_TO_BLOCKCHAIN_MAP = Object.fromEntries(
  SUPPORTED_BLOCKCHAINS.filter((blockchain) => !!blockchain.chainId).map((blockchain) => [
    blockchain.chainId,
    blockchain,
  ]),
);

/**
 * List of network codes for Ethereum-based networks
 * Mainly used to determine 'ETH' blockchain code and return Ethereum address
 * since Ethereum-based networks shared same receive address in wallet,
 */
export const ETHEREUM_NETWORK_CODES = SUPPORTED_BLOCKCHAINS.filter(
  (b) => !['BTC', 'DOGE', 'LTC', 'SOL'].includes(b.currencyCode),
).map((b) => b.currencyCode);

export const UTXO_NETWORK_CODES = ['BTC', 'DOGE', 'LTC'];

export function getCBPayIdentifierForNetwork(network: Network | undefined, currencyCode?: string) {
  if (!network) return undefined;

  if (network.asChain()?.chainId === EthereumNetworkMap.whitelisted.OPTIMISM_MAINNET.chainId)
    return 'OP';
  if (network.asChain()?.chainId === EthereumNetworkMap.whitelisted.ARBITRUM_MAINNET.chainId)
    return 'ARB';
  if (network.asChain()?.chainId === EthereumNetworkMap.whitelisted.BASE_MAINNET.chainId)
    return 'BASE';

  return network.asChain()?.baseAssetCurrencyCode ?? currencyCode;
}
