import { Formatters } from 'react-intl';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { Decimal } from 'decimal.js';

import {
  CurrencyFormatter,
  DEFAULT_PRIVACY_MODE_ELEMENT,
  RoundOption,
} from '../hooks/useCurrencyFormatter';

import { fiatValue } from './fiatValue';

/**
 * ## `formatToFiatCurrency`
 * Returns the formatted fiat value of a given value of a given currency with
 * advanced formatting depending upon the parameters passed in.
 *
 * @param fromCurrencyCode - The currency code of the value to be formatted
 * @param fiatCurrency - The fiat currency to format to
 * @param decimals - The number of decimals of the value to be formatted
 * @param value - The value to be formatted
 * @param isAbbreviated - Whether or not to abbreviate the value ie. 1,000,000 => 1M
 * @param contractAddress - The contract address of the value to be formatted
 * @param network - The network of the value to be formatted
 * @param blockchain - The blockchain of the value to be formatted
 * @param includeCode - Whether or not to include the currency code ie. $1 - true, 1 - false
 * @param formatZeroBalance - Whether or not to format a 0 balance ie. $0.00 - true, '' - false
 * @param overridePrivacy - Whether or not to override privacy mode
 * @param preserveEndingZeros - Whether or not to preserve ending zeros ie. 1.00 - true, 1 - false
 * @param numberFormatNotation - The notation to use for the number format
 * @param exchangeRatesMap - The exchange rates map ie. { 'networks/ethereum-mainnet-x-ETH': 5050 } @see useExchangeRatesMap
 * @param maximumFractionDigits - The maximum number of fraction digits to use
 * @param privacyModeIsEnabled - Whether or not privacy mode is enabled
 * @param formatters - The Formatters from react-intl or @formatjs/intl
 * @param shouldOverrideWithDollar - Whether or not to override the fiat currency with USD
 * @param round - Whether or not to round up, down or not at all, default is not at all
 * @returns formatted fiat currency
 */
export function formatToFiatCurrency({
  fromCurrencyCode,
  fiatCurrency,
  decimals,
  value,
  isAbbreviated = false,
  contractAddress,
  network,
  blockchain,
  includeCode = true,
  formatZeroBalance = false,
  overridePrivacy = false,
  preserveEndingZeros = false,
  numberFormatNotation = 'compact',
  exchangeRatesMap,
  maximumFractionDigits,
  privacyModeIsEnabled = false,
  formatters,
  shouldOverrideWithDollar,
  round = RoundOption.None,
}: Parameters<CurrencyFormatter['formatToFiatCurrency']>[0] & {
  privacyModeIsEnabled?: boolean;
  formatters: Formatters;
  maximumFractionDigits?: number;
  exchangeRatesMap: ReturnType<typeof useExchangeRatesMap>;
}): string | undefined {
  if (value === undefined) return;
  // proceed when formatZeroBalance is true and value is 0n
  // i.e we want to display a $0 balance
  if (typeof value === 'bigint' && value === 0n && !formatZeroBalance) return;

  const fiatDecimalValue = fiatValue({
    currencyCode: fromCurrencyCode,
    decimals,
    value,
    contractAddress,
    network,
    blockchain,
    exchangeRatesMap,
    shouldOverrideWithDollar,
  });

  if (!fiatDecimalValue) return undefined;

  const fiatFractionDigits = Number(fiatCurrency.decimals);

  if (privacyModeIsEnabled && !overridePrivacy) {
    return DEFAULT_PRIVACY_MODE_ELEMENT;
  }

  if (isAbbreviated) {
    const formatter = formatters.getNumberFormat(undefined, {
      style: 'decimal',
      notation: numberFormatNotation,
      compactDisplay: 'short',
      maximumFractionDigits: 1,
    });
    return formatter.format(fiatDecimalValue.toNumber());
  }
  if (!includeCode) {
    const formatter = formatters.getNumberFormat(undefined, {
      style: 'decimal',
      maximumFractionDigits: fiatFractionDigits,
    });

    return preserveEndingZeros
      ? fiatDecimalValue.toFixed(fiatFractionDigits)
      : formatter.format(fiatDecimalValue.toNumber());
  }

  const currencyFormatter = formatters.getNumberFormat(undefined, {
    style: 'currency',
    currency: fiatCurrency.code.code,
    maximumFractionDigits,
  });

  const fiatValueToDecimals = (() => {
    switch (round) {
      case RoundOption.Up:
        return new Decimal(fiatDecimalValue).toDecimalPlaces(fiatFractionDigits, Decimal.ROUND_UP);
      case RoundOption.Down:
        return new Decimal(fiatDecimalValue).toDecimalPlaces(
          fiatFractionDigits,
          Decimal.ROUND_DOWN,
        );
      case RoundOption.None:
      default:
        return new Decimal(fiatDecimalValue.toFixed(fiatFractionDigits));
    }
  })();

  return currencyFormatter.format(fiatValueToDecimals.toNumber());
}
