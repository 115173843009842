import { NewPartialPaletteConfig } from '@cbhq/cds-common/types/ThemeConfig';

export const THEME_COLORS = ['blue', 'teal', 'purple', 'pink', 'green'] as const;
export type ThemeColorPreference = (typeof THEME_COLORS)[number];
export const MIAMI_THEME_COLORS = ['blue', 'purple', 'pink', 'green', 'yellow', 'orange'] as const;
export type MiamiThemeColorPreference = (typeof MIAMI_THEME_COLORS)[number];

export type CombinedThemeColorPreference =
  | 'blue'
  | 'teal'
  | 'purple'
  | 'pink'
  | 'green'
  | 'yellow'
  | 'orange';
export type ThemeColorPreferenceProviderProps = { children: React.ReactNode };

export const themeColorConfigs = {
  blue: {
    primary: 'blue60',
    primaryForeground: 'gray0',
    primaryWash: 'blue0',
  },
  teal: {
    primary: 'teal60',
    primaryWash: 'teal0',
    primaryForeground: 'gray0',
  },
  purple: {
    primary: 'purple70',
    primaryForeground: 'gray0',
    primaryWash: 'purple0',
  },
  pink: {
    primary: 'pink60',
    primaryForeground: 'gray0',
    primaryWash: 'pink0',
    negative: 'red50',
  },
  green: {
    primary: 'green60',
    primaryForeground: 'gray0',
    primaryWash: 'green0',
    positive: 'green40',
  },
} as const;

export const darkThemeColorConfigs = {
  ...themeColorConfigs,
  green: {
    ...themeColorConfigs.green,
    positive: 'green70',
  },
} as const;

export const miamiThemeColorConfigs = {
  blue: {
    primary: 'blue60',
    primaryWash: 'blue0',
    primaryForeground: 'gray0',
  },
  pink: {
    primary: 'pink60',
    primaryWash: 'pink0',
    primaryForeground: 'gray0',
  },
  purple: {
    primary: 'purple70',
    primaryWash: 'purple0',
    primaryForeground: 'gray0',
  },
  green: {
    primary: 'green70',
    primaryWash: 'green0',
    primaryForeground: 'gray0',
  },
  orange: {
    primary: 'orange70',
    primaryWash: 'orange0',
    primaryForeground: 'gray0',
  },
  yellow: {
    primary: 'yellow70',
    primaryWash: 'yellow0',
    primaryForeground: 'gray0',
  },
} as const;

export const miamiDarkThemeColorConfigs = {
  blue: {
    primary: 'blue60',
    primaryWash: 'blue0',
    primaryForeground: 'gray0',
  },
  pink: {
    primary: 'pink50',
    primaryWash: 'pink0',
    primaryForeground: 'gray0',
  },
  purple: {
    primary: 'purple50',
    primaryWash: 'purple0',
    primaryForeground: 'gray0',
  },
  green: {
    primary: 'green70',
    primaryWash: 'green0',
    primaryForeground: 'gray0',
  },
  orange: {
    primary: 'orange50',
    primaryWash: 'orange0',
    primaryForeground: 'gray0',
  },
  yellow: {
    primary: 'yellow60',
    primaryWash: 'yellow10',
    primaryForeground: 'gray0',
  },
} as const;

// Responsible for white buttons during onboarding
export const ONBOARDING_PALETTE: NewPartialPaletteConfig = {
  primary: 'gray100',
  primaryWash: 'gray20',
  foregroundMuted: 'gray90',
  primaryForeground: 'gray0',
};
