/* eslint-disable no-process-env */
import { BaseChain } from 'cb-wallet-data/chains/AccountBased/shared/BaseChain';
import { NetworkMap } from 'cb-wallet-data/chains/AccountBased/shared/NetworkMap';
import { ethereumUrls } from 'wallet-engine-signing/signing/ethereum/urls';

export type EthereumChain = {
  blockchainSymbol: 'ETH';
  is1559Enabled: boolean;
  etherscanCompatibleTxHistoryApi: string;
  etherscanLikeApiKey: string | undefined;
  chainProxyTarget?: string;
  isOvmNetwork?: boolean;
} & BaseChain;

const ethereumNetworkAttributes: Pick<EthereumChain, 'blockchainSymbol' | 'isNonEthChain'> = {
  blockchainSymbol: 'ETH',
  isNonEthChain: false,
};

const whitelisted: Record<string, EthereumChain> = {
  ETHEREUM_MAINNET: {
    chainId: 1,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Ethereum',
    baseAssetDecimal: 18,
    displayName: 'Ethereum',
    isTestnet: false,
    isLayer2: false,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/ethereum-mainnet',
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    chainProxyTarget: 'ethereum-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.ETHEREUM_MAINNET,
  },
  OPTIMISM_MAINNET: {
    chainId: 10,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Ethereum',
    baseAssetDecimal: 18,
    displayName: 'OP Mainnet',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    isOvmNetwork: true,
    etherscanLikeApiKey: process.env.OPTIMISM_API_KEY,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/optimism_logo.png',
    isNudgeSupported: true,
    wacNetworkId: 'networks/optimism-mainnet',
    chainProxyTarget: 'optimism-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.OPTIMISM_MAINNET,
  },
  BASE_MAINNET: {
    chainId: 8453,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Ethereum',
    baseAssetDecimal: 18,
    displayName: 'Base',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    isOvmNetwork: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/base-logo.png',
    isNudgeSupported: true,
    chainProxyTarget: 'basescan',
    wacNetworkId: 'networks/base-mainnet',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.BASE_MAINNET,
  },
  POLYGON_MAINNET: {
    chainId: 137,
    baseAssetCurrencyCode: 'POL',
    baseAssetImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/b474d4bf3af389e5f26ed66956f1da8a/maticLogo.png',
    baseAssetDisplayName: 'Polygon',
    baseAssetDecimal: 18,
    displayName: 'Polygon',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.POLYGONSCAN_API_KEY,
    chainImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0_64.png',
    isNudgeSupported: true,
    wacNetworkId: 'networks/polygon-mainnet',
    chainProxyTarget: 'polygonscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.POLYGON_MAINNET,
  },
  BNB_MAINNET: {
    chainId: 56,
    baseAssetCurrencyCode: 'BNB',
    baseAssetImageUrl:
      'https://djly28hup5duz.cloudfront.net/wallet/wais/ea/1d/ea1dd1e2173d23bf2fdd95f78c145d064700aaccc24380403e73bbda74183742-MjQwOWEyYzYtMjUyMy00OGY4LTkxYzAtY2FlMzQyMmU1YTMw',
    baseAssetDisplayName: 'BNB',
    baseAssetDecimal: 18,
    displayName: 'BNB (Binance Smart) Chain',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.BSCSCAN_API_KEY,
    chainImageUrl: 'https://wallet-api-production.s3.amazonaws.com/uploads/tokens/bnb_288.png',
    isNudgeSupported: true,
    wacNetworkId: 'networks/bnb-mainnet',
    chainProxyTarget: 'bsc-bscscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.BNB_MAINNET,
  },
  FANTOM_MAINNET: {
    chainId: 250,
    baseAssetCurrencyCode: 'FTM',
    baseAssetImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/ddbcf362d2717855adb007691745b83f/ftm_logo.png',
    baseAssetDisplayName: 'FTM',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/ddbcf362d2717855adb007691745b83f/ftm_logo.png',
    displayName: 'Fantom Opera',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.FANTOMSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/fantom-mainnet',
    chainProxyTarget: 'fantom-ftmscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.FANTOM_MAINNET,
  },
  ARBITRUM_MAINNET: {
    chainId: 42161,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Ethereum',
    baseAssetDecimal: 18,
    displayName: 'Arbitrum',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ARBISCAN_API_KEY,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/arbitrum_logo.png',
    isNudgeSupported: true,
    wacNetworkId: 'networks/arbitrum-mainnet',
    chainProxyTarget: 'arbitrum-arbiscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.ARBITRUM_MAINNET,
  },
  GNOSIS_MAINNET: {
    chainId: 100,
    baseAssetCurrencyCode: 'XDAI',
    baseAssetImageUrl: 'https://static-assets.coinbase.com/wallet/assets/xdai.png',
    baseAssetDisplayName: 'xDai',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://dynamic-assets.coinbase.com/d1afa9a4f7ce51fa1af1ab94a15ea875a4df2b9a0b6ca619ea3de482d688198d67112c70516b703405b01d9abd5856493c238fe58004b63f4c5b5986a7624da2/asset_icons/b2e81465e2ac5d20898c4793c1c672073895ba71688ad1044a01011fe555ca88.png',
    displayName: 'Gnosis',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.XDAI_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/xdai-mainnet',
    chainProxyTarget: 'gnosis-gnosisscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.GNOSIS_MAINNET,
  },
  AVALANCHE_MAINNET: {
    chainId: 43114,
    baseAssetCurrencyCode: 'AVAX',
    baseAssetImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/889d119cede59f72d7dae9132b2d35fc/avax_logo.png',
    baseAssetDisplayName: 'AVAX',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/889d119cede59f72d7dae9132b2d35fc/avax_logo.png',
    displayName: 'Avalanche C-Chain',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.SNOWTRACE_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/avacchain-mainnet',
    chainProxyTarget: 'avalanche-snowtrace',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.AVALANCHE_MAINNET,
  },
  ZETACHAIN_MAINNET: {
    chainId: 7000,
    baseAssetCurrencyCode: 'ZETA',
    baseAssetImageUrl:
      'https://asset-metadata-service-production.s3.amazonaws.com/asset_icons/ef9e387c9bf4354c6163feeb01183f15f362d500e6cb7b0d9a2ed3346e0256a8.png',
    baseAssetDisplayName: 'ZETA',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://asset-metadata-service-production.s3.amazonaws.com/asset_icons/ef9e387c9bf4354c6163feeb01183f15f362d500e6cb7b0d9a2ed3346e0256a8.png',
    displayName: 'ZetaChain',
    isTestnet: false,
    isLayer2: false,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY, // required for client to hit chain-proxy endpoint
    wacNetworkId: 'networks/zetachain-mainnet',
    chainProxyTarget: 'zetachain-mainnet-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.ZETACHAIN_MAINNET,
  },
  ZORA_MAINNET: {
    chainId: 7777777,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Ethereum',
    baseAssetDecimal: 18,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/chains/zora-logo.png',
    displayName: 'Zora',
    isTestnet: false,
    isLayer2: true,
    is1559Enabled: true,
    isOvmNetwork: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY, // required for client to hit chain-proxy endpoint
    isNudgeSupported: true,
    wacNetworkId: 'networks/zora-mainnet',
    chainProxyTarget: 'zora-mainnet-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.ZORA_MAINNET,
  },
  // TESTNETS
  HOLESKY: {
    chainId: 17000,
    baseAssetCurrencyCode: undefined,
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Holesky Ethereum',
    baseAssetDecimal: 18,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    displayName: 'Holesky',
    isTestnet: true,
    isLayer2: false,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/ethereum-holesky-testnet',
    chainProxyTarget: 'ethereum-holesky-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.HOLESKY,
  },
  SEPOLIA: {
    chainId: 11155111,
    baseAssetCurrencyCode: undefined,
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Sepolia Ethereum',
    baseAssetDecimal: 18,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    displayName: 'Sepolia',
    isTestnet: true,
    isLayer2: false,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/ethereum-sepolia-testnet',
    chainProxyTarget: 'ethereum-sepolia-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.SEPOLIA,
  },
  OP_SEPOLIA: {
    chainId: 11155420,
    baseAssetCurrencyCode: undefined,
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Sepolia Ethereum',
    baseAssetDecimal: 18,
    displayName: 'OP Sepolia',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    isOvmNetwork: true,
    etherscanLikeApiKey: process.env.ETHERSCAN_API_KEY,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/optimism_logo.png',
    isNudgeSupported: true,
    wacNetworkId: 'networks/optimism-sepolia-testnet',
    chainProxyTarget: 'optimism-sepolia-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.OP_SEPOLIA,
  },
  BASE_SEPOLIA: {
    chainId: 84532,
    baseAssetCurrencyCode: undefined,
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Sepolia Ethereum',
    baseAssetDecimal: 18,
    displayName: 'Base Sepolia',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    isOvmNetwork: true,
    chainImageUrl: 'https://go.wallet.coinbase.com/static/base-logo.png',
    isNudgeSupported: true,
    chainProxyTarget: 'base-sepolia-etherscan',
    wacNetworkId: 'networks/base-sepolia-testnet',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.BASE_SEPOLIA,
  },
  BNB_TESTNET: {
    chainId: 97,
    baseAssetCurrencyCode: 'BNB',
    baseAssetImageUrl:
      'https://djly28hup5duz.cloudfront.net/wallet/wais/ea/1d/ea1dd1e2173d23bf2fdd95f78c145d064700aaccc24380403e73bbda74183742-MjQwOWEyYzYtMjUyMy00OGY4LTkxYzAtY2FlMzQyMmU1YTMw',
    baseAssetDisplayName: 'BNB',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://djly28hup5duz.cloudfront.net/wallet/wais/ea/1d/ea1dd1e2173d23bf2fdd95f78c145d064700aaccc24380403e73bbda74183742-MjQwOWEyYzYtMjUyMy00OGY4LTkxYzAtY2FlMzQyMmU1YTMw',
    displayName: 'BNB (Binance Smart) Chain Testnet',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.BSCSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/bnb-testnet',
    chainProxyTarget: 'bsc-testnet-bscscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.BNB_TESTNET,
  },
  FANTOM_TESTNET: {
    chainId: 4002,
    baseAssetCurrencyCode: 'FTM',
    baseAssetImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/ddbcf362d2717855adb007691745b83f/ftm_logo.png',
    baseAssetDisplayName: 'FTM',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/ddbcf362d2717855adb007691745b83f/ftm_logo.png',
    displayName: 'Fantom Testnet',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.FANTOMSCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/fantom-testnet',
    chainProxyTarget: 'fantom-testnet-ftmscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.FANTOM_TESTNET,
  },
  ARBITRUM_SEPOLIA: {
    chainId: 421614,
    baseAssetCurrencyCode: undefined,
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Sepolia Ethereum',
    baseAssetDecimal: 18,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/arbitrum_logo.png',
    displayName: 'Arbitrum Sepolia',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.ARBISCAN_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/arbitrum-sepolia-testnet',
    chainProxyTarget: 'arbitrum-sepolia-etherscan',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.ARBITRUM_SEPOLIA,
  },
  AVALANCHE_FUJI: {
    chainId: 43113,
    baseAssetCurrencyCode: 'AVAX',
    baseAssetImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/889d119cede59f72d7dae9132b2d35fc/avax_logo.png',
    baseAssetDisplayName: 'Testnet AVAX',
    baseAssetDecimal: 18,
    chainImageUrl:
      'https://wallet-api-production.s3.amazonaws.com/uploads/889d119cede59f72d7dae9132b2d35fc/avax_logo.png',
    displayName: 'Avalanche Fuji',
    isTestnet: true,
    isLayer2: true,
    is1559Enabled: true,
    isCustomNetwork: false,
    etherscanLikeApiKey: process.env.SNOWTRACE_API_KEY,
    isNudgeSupported: true,
    wacNetworkId: 'networks/avacchain-fuji-testnet',
    chainProxyTarget: 'avalanche-fuji-snowtrace',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.AVALANCHE_FUJI,
  },
  LOCALHOST_8545: {
    chainId: 31337,
    baseAssetCurrencyCode: 'ETH',
    baseAssetImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    baseAssetDisplayName: 'Localhost ETH',
    baseAssetDecimal: 18,
    chainImageUrl: 'https://coinbase-west.s3.us-west-2.amazonaws.com/ethereum_logo.png',
    displayName: 'Localhost 8545',
    isTestnet: true,
    isLayer2: false,
    is1559Enabled: false,
    isCustomNetwork: false,
    etherscanLikeApiKey: '',
    ...ethereumNetworkAttributes,
    ...ethereumUrls.LOCALHOST_8545,
  },
};

export const EthereumNetworkMap = new NetworkMap<EthereumChain>(whitelisted);

export const localhostTestnetChainIds = [31337, 5777, 1337];
export const errorIgnoredChainIds = [...localhostTestnetChainIds];
