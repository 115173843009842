import { useCallback, useEffect, useMemo, useState } from 'react';
import { EthereumBlockchain } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { SolanaBlockchain } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { BitcoinBlockchain } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { SyncUTXOTxHistoryOperation } from 'cb-wallet-data/chains/UTXO/common/SyncUTXOTxHistoryOperation';
import { DogecoinBlockchain } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LitecoinBlockchain } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useInterval } from 'cb-wallet-data/hooks/useInterval';
import { usePreviousValue } from 'cb-wallet-data/hooks/usePreviousValue';
import { usePaginatedTransactions } from 'cb-wallet-data/stores/Transactions/hooks/usePaginatedTransactions';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { TXN_SYNCING_INTERVAL } from 'cb-wallet-data/utils/intervalConstants';

import { useSyncableWallets } from './useSyncableWallets';

type SyncTransactionsParams = {
  syncableWallets: Wallet[];
  updatePaginatedTransactions: (updatedTransactions: TxOrUserOp[]) => void;
  // TODO this feature has rolled out successfully. We should remove this killswitch.
  // ticket: https://jira.coinbase-corp.com/browse/WALL-37661
  isUTXOTransactionBlockheightSyncingEnabled: boolean;
};

/**
 * @deprecated
 * Tx history syncing is moving to the Address History listener.
 */
export async function syncTransactions({
  syncableWallets,
  isUTXOTransactionBlockheightSyncingEnabled,
  updatePaginatedTransactions,
}: SyncTransactionsParams): Promise<void> {
  try {
    await Promise.allSettled(
      syncableWallets.map(async function syncWalletsByBlockchain(wallet) {
        const { blockchain } = wallet;
        switch (blockchain.rawValue) {
          // EVM and Solana tx syncing has moved to the Address History listener.
          // The rest of this function can be removed once UTXOs have been migrated as well.
          case EthereumBlockchain.rawValue:
          case SolanaBlockchain.rawValue: {
            return Promise.resolve();
          }

          case BitcoinBlockchain.rawValue:
          case DogecoinBlockchain.rawValue:
          case LitecoinBlockchain.rawValue: {
            return new SyncUTXOTxHistoryOperation(
              wallet,
              isUTXOTransactionBlockheightSyncingEnabled,
            ).syncTxHistory({ updatePaginatedTransactions });
          }

          default: {
            return Promise.resolve();
          }
        }
      }),
    );
  } catch (error) {
    const err = coerceError(error, 'useSyncTransactionHistory');
    cbReportError({ context: 'transactions', error: err, severity: 'error', isHandled: false });
  }
}

/**
 * @deprecated
 * Tx history syncing is moving to the Address History listener.
 *
 * This hook should only be included in one component.
 * It syncs transactions every 30 seconds, and whenever Wallets change.
 */
export function useSyncTransactionHistory(): void {
  const syncableWallets = useSyncableWallets();
  const prevSyncableWallets = usePreviousValue<typeof syncableWallets>(syncableWallets);
  const { updatePaginatedTransactions } = usePaginatedTransactions();
  const isUTXOTransactionBlockheightSyncingEnabled = useIsFeatureEnabled(
    'utxo_transaction_blockheight_syncing',
  );

  const unsyncedWallets = useMemo(() => {
    const prevSyncableWalletIdsSet = new Set((prevSyncableWallets ?? []).map((w) => w.id));
    return syncableWallets.filter((wallet) => !prevSyncableWalletIdsSet.has(wallet.id));
  }, [prevSyncableWallets, syncableWallets]);

  // Sync transactions on mount and when the wallet group changes
  useEffect(
    function syncTransactionsForWallets() {
      if (unsyncedWallets.length) {
        syncTransactions({
          syncableWallets: unsyncedWallets,
          isUTXOTransactionBlockheightSyncingEnabled,
          updatePaginatedTransactions,
        });
      }
    },
    [unsyncedWallets, updatePaginatedTransactions, isUTXOTransactionBlockheightSyncingEnabled],
  );

  useInterval(
    async function syncTransactionsInterval() {
      syncTransactions({
        syncableWallets,
        updatePaginatedTransactions,
        isUTXOTransactionBlockheightSyncingEnabled,
      });
    },
    { interval: TXN_SYNCING_INTERVAL },
  );
}

/**
 * @deprecated
 * Tx history syncing is moving to the Address History listener.
 *
 * Manually refresh transactions rather than syncing on a timer
 */
export function useRefreshTransactionHistory() {
  const { updatePaginatedTransactions } = usePaginatedTransactions();
  const syncables = useSyncableWallets();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isUTXOTransactionBlockheightSyncingEnabled = useIsFeatureEnabled(
    'utxo_transaction_blockheight_syncing',
  );

  const refresh = async () => {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);
    try {
      await syncTransactions({
        syncableWallets: syncables,
        updatePaginatedTransactions,
        isUTXOTransactionBlockheightSyncingEnabled,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  return useCallback(refresh, [
    updatePaginatedTransactions,
    isRefreshing,
    syncables,
    isUTXOTransactionBlockheightSyncingEnabled,
  ]);
}
