import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from 'cb-wallet-data/stores/SettingsRedesign/messages/hideAssets';
import {
  SettingCategory,
  SettingCategoryConfig,
} from 'cb-wallet-data/stores/SettingsRedesign/types';
import { useRouter } from 'next/router';

import { useSearchContext } from ':dapp/providers/SearchProvider';
import { SettingRoutesEnum } from ':dapp/utils/RoutesEnum';

import { SETTING_CATEGORY_INNER_SPACING } from '../constants';

export function useHideAssetsConfig(): SettingCategoryConfig {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { toggleSearchOff } = useSearchContext();

  const onPress = useCallback(() => {
    router.push(SettingRoutesEnum.HIDE_ASSETS);
    toggleSearchOff();
  }, [router, toggleSearchOff]);

  const hideAssetsConfig = useMemo<SettingCategoryConfig>(() => {
    return {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      media: SettingCategory.HIDE_ASSETS,
      accessory: 'arrow',
      testID: `${SettingCategory.HIDE_ASSETS}-setting`,
      navigationPath: SettingRoutesEnum.HIDE_ASSETS,
      innerSpacing: SETTING_CATEGORY_INNER_SPACING,
      onPress,
    };
  }, [formatMessage, onPress]);
  return hideAssetsConfig;
}
