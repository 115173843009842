import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useEventLogger } from '../utils/log';

type TxDeeplinkResultData = {
  walletId: string;
  receiveAddress: string;
  txNotificationProcessResult:
    | 'success'
    | 'cached'
    | 'wallet_not_found'
    | 'wallet_group_not_found'
    | 'failed';
};

export type AssetData = {
  walletId: string;
  asset: string;
  contractAddress: string | undefined;
  network: string | undefined;
  missingImage: boolean;
  missingDescription: boolean;
  missingResources: boolean;
  missingMarketcap: boolean;
  missingVolume: boolean;
  isSupported: boolean;
};

export type AssetDataV4 = {
  assetSymbol: string;
  contractAddress: string | undefined;
  networkId: string | undefined;
  chainId?: string | number;
};

type AssetActionDetails = {
  walletId: string | undefined;
  asset: string | undefined;
};

export function logAssetDetailSendClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.send.clicked', {
    loggingId: 'c114ebaa-2ba8-47b4-b9d6-7ae132729765',
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    asset,
  });
}

export function logAssetDetailCashoutClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.cashout.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    asset,
  });
}

export function logAssetDetailSwapClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.swap.clicked', {
    loggingId: 'c007ec53-560f-4b16-9e62-69ae5ec3f621',
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    asset,
  });
}

export function logAssetDetailBridgeClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.bridge.clicked', {
    loggingId: 'ceee1607-776e-4dcf-885b-393934b4e2e8',
    action: ActionType.click,
    componentType: ComponentType.button,
    asset,
    walletId,
  });
}

export function logAssetDetailBuyClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.buy.clicked', {
    loggingId: 'b036ad3b-2b7a-487d-957b-90fe304da79a',
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    asset,
  });
}

export function logAssetDetailReceiveClicked({ walletId, asset }: AssetActionDetails) {
  return logEvent('asset_detail.receive.clicked', {
    loggingId: '9aba7a3e-917d-4d51-8c71-1e57d3ea017b',
    action: ActionType.click,
    componentType: ComponentType.button,
    walletId,
    asset,
  });
}

type ActionButton =
  | 'buy'
  | 'swap'
  | 'bridge'
  | 'send'
  | 'receive'
  | 'cashout'
  | 'txt-send'
  | 'share';
export function logAssetDetailActionError(actionButton: ActionButton) {
  return () =>
    logMetric({
      metricName: 'asset_detail.action_button_error',
      metricType: MetricType.count,
      value: 1,
      tags: {
        actionButton,
      },
    });
}

type Banner = 'svl';
export function logAssetDetailBannerError(banner: Banner) {
  return () =>
    logMetric({
      metricName: 'asset_detail.banner_error',
      metricType: MetricType.count,
      value: 1,
      tags: {
        banner,
      },
    });
}

export function useAssetDetailSeeAllTxClicked() {
  return useEventLogger('asset_detail.see_all_transactions.clicked', {
    loggingId: 'eb5b075e-b097-4e31-843d-3c7724005293',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useAssetDetailTxClicked() {
  return useEventLogger('asset_detail.transaction.clicked', {
    loggingId: '5bdfeb1f-a9e0-4351-90bd-15fce37b0b55',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetDetailViewed(data?: AssetData) {
  logEvent('asset_detail.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
    ...data,
  });
}

export function logContractAddressCopyClicked(asset: string, contractAddress: string | undefined) {
  return logEvent('asset_detail.contract_address_copy.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    asset,
    contractAddress,
  });
}

export function logAssetDetailMetadataViewed() {
  logEvent('asset_detail.metadata.viewed', {
    loggingId: '2137c3aa-d8ef-414b-a0f3-1f6289b7e21e',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logAssetDetailPriceChartLoaded(currencyCode: string) {
  logEvent('asset_detail.price_chart.loaded', {
    action: ActionType.view,
    componentType: ComponentType.page,
    currencyCode,
  });
}

export function logAssetDetailStaticDataLoadSucceeded({
  assetSymbol,
  contractAddress,
  networkId,
  chainId,
}: AssetDataV4) {
  logDataEvent('asset_detail.v4_static_data.load_succeeded', {
    tokenSymbol: assetSymbol,
    contractAddress,
    network: networkId,
    chainId,
  });
}

export function logAssetDetailStaticDataLoadFailed({
  assetSymbol,
  contractAddress,
  networkId,
  chainId,
  err,
}: AssetDataV4 & { err: ErrorOrAny }) {
  logDataEvent('asset_detail.v4_static_data.load_failed', {
    tokenSymbol: assetSymbol,
    contractAddress,
    network: networkId,
    chainId,
    error: String(err),
  });
}

export function logAssetDetailDynamicDataLoadSucceeded({
  assetSymbol,
  contractAddress,
  networkId,
  chainId,
}: AssetDataV4) {
  logDataEvent('asset_detail.v4_dynamic_data.load_succeeded', {
    tokenSymbol: assetSymbol,
    contractAddress,
    network: networkId,
    chainId,
  });
}

export function logAssetDetailDynamicDataLoadFailed({
  assetSymbol,
  contractAddress,
  networkId,
  chainId,
  err,
}: AssetDataV4 & { err: ErrorOrAny }) {
  logDataEvent('asset_detail.v4_dynamic_data.load_failed', {
    tokenSymbol: assetSymbol,
    contractAddress,
    network: networkId,
    chainId,
    error: String(err),
  });
}

export function logAssetDetailTxListViewed(walletId: string, asset: string) {
  logEvent('asset_detail.transaction_list.viewed', {
    loggingId: 'f4d5bf8f-d028-48a7-95ef-cbf261380a7c',
    action: ActionType.view,
    componentType: ComponentType.page,
    walletId,
    asset,
  });
}

export function logEnableSuperModeClicked() {
  logEvent('asset_detail.enable_super_mode.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logPriceChartTimePeriodClick(
  period: 'hour' | 'day' | 'week' | 'month' | 'year' | 'all',
  asset: string,
) {
  return logEvent('asset_detail.chart_period.clicked', {
    loggingId: 'b0bb2568-bac8-4887-bcc0-e406a1439a20',
    action: ActionType.click,
    componentType: ComponentType.button,
    period,
    asset,
  });
}

export function logAssetDetailTxDeeplinkProcessed(data: TxDeeplinkResultData) {
  return logEvent('asset_detail.tx_deeplink_processed', {
    loggingId: 'ea331f78-6d21-454b-b68d-0e3c5e0c05d8',
    action: ActionType.view,
    componentType: ComponentType.page,
    ...data,
  });
}

export function logTxPushNotificationDeeplinkProcessTime(data: {
  timeInSeconds: number;
  result: TxDeeplinkResultData['txNotificationProcessResult'];
}) {
  return logEvent('asset_detail.tx_deeplink_process_time', {
    loggingId: 'ea331f78-6d21-454b-b68d-0e3c5e0c05d8',
    action: ActionType.view,
    componentType: ComponentType.page,
    ...data,
  });
}
