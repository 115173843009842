/* eslint-disable no-process-env */
// Put all process.env.XXX usages in this file,

import { safeParseUrl } from 'cb-wallet-data/utils/urlUtils';
import { PushConfig } from '@cbhq/web-push-notifications';

enum NodeEnv {
  production = 'production',
  development = 'development',
  test = 'test',
}

export enum ReleaseEnvironment {
  production = 'production',
  preprod = 'pre-prod',
  development = 'development',
  localprod = 'local-prod',
  local = 'local',
  test = 'test',
  staging = 'staging',
}

// Node envs
const NODE_ENV = process.env.NODE_ENV as NodeEnv;
export const IS_DEV = NODE_ENV === NodeEnv.development;
export const IS_PROD = NODE_ENV === NodeEnv.production;
export const IS_TEST = NODE_ENV === NodeEnv.test;

// Release Environments
export const APP_VERSION = process.env.APP_VERSION as string;
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || 'Coinbase Wallet';
export const APP_BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID;
export const RELEASE_ENVIRONMENT = process.env.NEXT_PUBLIC_RELEASE_STAGE as ReleaseEnvironment;
export const PRODUCTION_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.production;
export const DEVELOPMENT_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.development;
export const STAGING_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.staging;
export const PREPRODUCTION_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.preprod;
export const TEST_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.test;
export const LOCAL_RELEASE = RELEASE_ENVIRONMENT === ReleaseEnvironment.local;

// Client Side
/**
 * https://github.com/vercel/next.js/issues/5354#issuecomment-650170660
 * @deprecated Directly use typeof window !== "undefined" wherever required for optimum bundler output.
 */
export const IS_CLIENT = typeof window !== 'undefined';
export const CB_WALLET_PUBLIC_URL =
  process.env.CB_WALLET_PUBLIC_URL ?? 'https://wallet.coinbase.com';

export const CB_WALLET_DAPP_URL =
  process.env.NEXT_PUBLIC_CB_WALLET_DAPP_URL ?? 'https://wallet.coinbase.com';
export const CB_WALLET_HOMEBASE_URL =
  process.env.NEXT_PUBLIC_CB_WALLET_HOMEBASE_URL ?? 'https://wallet.coinbase.com';
export const CB_WALLET_SCW_URL =
  process.env.NEXT_PUBLIC_CB_WALLET_SCW_URL ?? 'https://keys.coinbase.com';

export const USM_URL = process.env.NEXT_PUBLIC_USM_URL ?? 'https://wallet.coinbase.com';
export const USM_ENV = process.env.NEXT_PUBLIC_USM_ENV ?? 'production';

export const USM_PROXY_URL =
  USM_ENV === 'production' ? 'https://wallet-qa.coinbase.com' : 'https://wallet-dev.cbhq.net';
export const ONRAMP_PROXY_URL =
  USM_ENV === 'production'
    ? 'https://onramp.wallet.coinbase.com'
    : 'https://onramp-wallet-dev.cbhq.net';

export const CB_WALLET_SCW_LOCAL_URL = 'http://localhost:3005';
export const CB_WALLET_SCW_DEV_URL = 'https://scw-dev.cbhq.net';
export const CB_WALLET_SCW_PROD_URL = 'https://keys.coinbase.com';
export const CB_WALLET_SCW_STAGING_URL = 'https://keys-beta.coinbase.com';

// 3rd Party Keys
export const AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY ?? '';
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '';
export const SMARTLING_PROJECT_ID = process.env.NEXT_PUBLIC_SMARTLING_PROJECT_ID;
export const SMARTLING_SERVICE_IDENTIFIER = process.env.NEXT_PUBLIC_SMARTLING_SERVICE_IDENTIFIER;
export const NEYNAR_API_KEY = process.env.NEYNAR_API_KEY ?? '';
export const THIRDWEB_CLIENT_ID =
  process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? 'b891d14d436694bb9a7feeba91730b95';

// Coinbase Api's
export const CB_API_URL = process.env.CB_API_URL ?? 'api.coinbase.com';
export const CB_WALLET_API_URL = process.env.CB_WALLET_API_URL ?? 'api.wallet.coinbase.com';
export const CB_WALLET_BLOCKCHAIN_URL =
  process.env.CB_WALLET_BLOCKCHAIN_URL ?? 'blockchain.wallet.coinbase.com';
export const CB_WALLET_CHAIN_PROXY_URL =
  process.env.CB_WALLET_CHAIN_PROXY_URL ?? 'chain-proxy.wallet.coinbase.com';
export const BUGSNAG_ENDPOINT =
  process.env.NEXT_PUBLIC_BUGSNAG_ENDPOINT ?? 'https://exceptions.coinbase.com';
export const BUGSNAG_SESSIONS_ENDPOINT =
  process.env.NEXT_PUBLIC_BUGSNAG_SESSIONS_ENDPOINT ?? 'https://sessions.coinbase.com';

export const WALLET_CONNECT_PROJECT_ID = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ?? '';

export const CBPAY_ID = process.env.NEXT_PUBLIC_CBPAY_ID;

export const ALLOWED_SCW_ORIGINS = [
  CB_WALLET_SCW_URL,
  CB_WALLET_SCW_DEV_URL,
  CB_WALLET_SCW_STAGING_URL,
  CB_WALLET_SCW_PROD_URL,
];

export const ALLOWED_AUTOCONNECT_ORIGINS = [CB_WALLET_DAPP_URL, ...ALLOWED_SCW_ORIGINS];

export const ALLOWED_AUTOCONNECT_HOSTNAMES = ALLOWED_AUTOCONNECT_ORIGINS.map(
  (origin) => safeParseUrl(origin)?.hostname,
);

if (LOCAL_RELEASE) {
  ALLOWED_AUTOCONNECT_ORIGINS.push(CB_WALLET_SCW_LOCAL_URL);
}

if (!process.env.NEXT_PUBLIC_KILL_SWITCH_SERVICE_URL) {
  throw new Error('NEXT_PUBLIC_KILL_SWITCH_SERVICE_URL is missing');
}
export const KILL_SWITCH_SERVICE_URL = process.env.NEXT_PUBLIC_KILL_SWITCH_SERVICE_URL;

export const CROSSMINT_PROJECT_ID = process.env.NEXT_PUBLIC_CROSSMINT_PROJECT_ID;

// Slice Api's
export const THE_GRAPH_KEY = process.env.NEXT_PUBLIC_THE_GRAPH_KEY;
export const THE_GRAPH_KEY_INTERNAL = process.env.THE_GRAPH_KEY_INTERNAL;

export const PUSH_CONFIG: PushConfig = {
  firebaseConfig: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  },
  vapidKey: process.env.FIREBASE_VAPID_KEY,
  defaultIcon: 'https://static-assets.coinbase.com/international/coinbase_wallet.png',
};
