import {
  DehydratedQuery,
  PersistedClient,
  PersistedClientMetadata,
  Persistor,
  PersistorAsync,
} from './persistQueryClient';

function debugLog(message: string) {
  // eslint-disable-next-line no-console
  console.log(`[persist-query] ${message}`);
}

/**
 * Adds debug and performance logs to a Persistor.
 */
export class InstrumentedPersistor implements PersistorAsync {
  _basePersistor: Persistor;
  _totalTimePersisting = 0;

  constructor(persistor: Persistor) {
    this._basePersistor = persistor;
  }

  async persistQuery(query: DehydratedQuery): Promise<void> {
    const start = performance.now();
    await this._basePersistor.persistQuery(query);
    const elapsed = performance.now() - start;
    this._totalTimePersisting += elapsed;
    debugLog(
      `Persisted query "${query.queryHash}" in ${elapsed}ms, total time ${this._totalTimePersisting}ms`,
    );
  }

  async removeQuery(queryHash: string): Promise<void> {
    await this._basePersistor.removeQuery(queryHash);
    debugLog(`Removed query "${queryHash}"`);
  }

  async restoreClient(): Promise<PersistedClient | undefined> {
    const start = performance.now();
    const client = await this._basePersistor.restoreClient();
    if (client !== undefined) {
      debugLog(
        `Restored client with ${client.clientState.queries.length} queries in ${
          performance.now() - start
        }ms`,
      );
    } else {
      debugLog('No client restored');
    }
    return client;
  }

  async removeClient(): Promise<void> {
    this._basePersistor.removeClient();
    debugLog('Removed client');
  }

  async persistMetadata(metadata: PersistedClientMetadata): Promise<void> {
    this._basePersistor.persistMetadata(metadata);
    debugLog(`Persisted metadata ${JSON.stringify(metadata)}`);
  }
}
