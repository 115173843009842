// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React, { Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { defineMessage } from 'react-intl';
import { SliceProvider } from '@slicekit/react';
import { DataProvider } from 'cb-wallet-data/DataProvider';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { PageOutageProvider } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { ThemeColorPreference } from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { useGetAppearanceBackground } from 'cb-wallet-data/stores/User/hooks/useGetAppearanceBackground';
import { themeColorDefault } from 'cb-wallet-data/stores/User/hooks/useThemeColor';
import { StoreKeys_userId } from 'cb-wallet-http/User/state';
import { Store } from 'cb-wallet-store/Store';
import { parse } from 'cookie';
import isEmpty from 'lodash/isEmpty';
import NextApp, { type AppContext, AppProps as NextAppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { ExperimentationProvider } from 'wallet-cds-web/components/ExperimentationProvider/ExperimentationProvider';
import { GlobalErrorPage } from 'wallet-cds-web/components/GlobalErrorPage';
import { Spectrum } from '@cbhq/cds-common';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { EventHandlerProvider, ThemeProvider } from '@cbhq/cds-web/system';
import { automatedMappingConfig } from '@cbhq/client-analytics';
import {
  ClientKillSwitchProvider,
  defaultClientKillSwitchConfig,
  setLatestKillSwitchConfig,
} from '@cbhq/client-kill-switch';
import {
  convertToCBSupported,
  DEFAULT_LOCALE,
  fetchMessagesOnServer,
  MessagesMap,
} from '@cbhq/intl';
import { polyfillIntl } from '@cbhq/intl/polyfills';

import { AppHead } from ':dapp/AppHead';
import {
  APP_VERSION,
  PRODUCTION_RELEASE,
  RELEASE_ENVIRONMENT,
  SMARTLING_PROJECT_ID,
  SMARTLING_SERVICE_IDENTIFIER,
} from ':dapp/config/env';
import { KILLSWITCHES_CONFIG } from ':dapp/init/killswitches';
import { GlobalEventMetadataProvider } from ':dapp/providers/GlobalEventsProvider';
import { consoleUserSafety } from ':dapp/utils/consoleUserSafety';
import { getRouteMetadata, Metadata } from ':dapp/utils/getRouteMetadata';

import { AppFallback } from './components/AppFallback/AppFallback';
import { RootErrorFallback } from './components/RootErrorFallback/RootErrorFallback';
import { UnderMaintenance } from './components/UnderMaintenance/UnderMaintenance';
import { AppIntlProvider } from './providers/AppIntlProvider';
import { CookieManagerProvider } from './providers/CookieManagerProvider';
import { ExperienceModalProvider } from './providers/ExperienceModalProvider';
import { SearchProvider } from './providers/SearchProvider';
import { ShareModalProvider } from './providers/ShareModalProvider';
import { WalletThemeProvider } from './providers/WalletThemeProvider';
import { getDeviceId } from './utils/deviceId';
import { getLocalStorageSize } from './utils/getLocalStorageSize';
import { App } from './App';
import { AppLayout } from './AppLayout';
import { AppPage } from './page';

type AppProps = {
  themeColorPreference: ThemeColorPreference;
  spectrumPreference: Spectrum;
  routeMetadata: Metadata[];
  messages: MessagesMap;
};

export async function getInitialProps(context: AppContext) {
  const appProps = await NextApp.getInitialProps(context);
  let routeMetadata: Metadata[] = [];
  let messages = {};
  try {
    if (context.router.locale && SMARTLING_PROJECT_ID && SMARTLING_SERVICE_IDENTIFIER) {
      messages = await fetchMessagesOnServer(
        convertToCBSupported(context.router.locale),
        SMARTLING_PROJECT_ID,
        SMARTLING_SERVICE_IDENTIFIER,
      );
    }

    const query = !isEmpty(context.ctx.query)
      ? context.ctx.query
      : Object.fromEntries(new URLSearchParams(context.ctx.asPath?.split('?')[1] ?? ''));

    routeMetadata = await getRouteMetadata(context.ctx.pathname, query);
  } catch (error) {
    cbReportError({
      error: coerceError(error, 'App'),
      context: 'dapp-load',
      severity: 'error',
      isHandled: false,
    });
  }

  const cookies = parse(context?.ctx?.req?.headers?.cookie ?? '');
  return {
    ...appProps,
    themeColorPreference: cookies.themeColor ?? themeColorDefault,
    spectrumPreference: cookies.spectrum ?? 'dark',
    routeMetadata,
    messages,
  };
}

type RootProps = NextAppProps & {
  Component: AppPage;
} & AppProps;

const dismissText = defineMessage({
  description: 'Refresh the page to dismiss the error message',
  defaultMessage: 'Refresh',
});

const MemoizedRoot = memo(function Root({
  Component,
  pageProps,
  themeColorPreference,
  spectrumPreference,
  routeMetadata,
  messages,
}: RootProps) {
  const router = useRouter();
  const locale = router.locale ?? DEFAULT_LOCALE;
  const [, background] = useGetAppearanceBackground();

  const errorReportingMetadata = useMemo(() => ({ localStorageSize: getLocalStorageSize() }), []);

  const initialKillSwitches = useMemo(
    () => ({
      ...defaultClientKillSwitchConfig,
      wallet: setLatestKillSwitchConfig(defaultClientKillSwitchConfig.wallet || {}),
    }),
    [],
  );

  const isWalletWebKilled = initialKillSwitches.wallet.kill_wallet_web;

  useEffect(
    function setBackgroundColorForSpectrum() {
      document.documentElement.setAttribute('style', `background-color: ${background}`);
    },
    [background],
  );

  useEffect(
    function polyfillOnMount() {
      void polyfillIntl(convertToCBSupported(locale));
    },
    [locale],
  );

  useOnMount(function runSafetyMeasures() {
    if (PRODUCTION_RELEASE) {
      consoleUserSafety();
    }
  });

  const getLayout = useMemo(
    () => Component.getLayout ?? ((x: JSX.Element) => x),
    [Component.getLayout],
  );
  const Layout = useMemo(() => Component.Layout ?? Fragment, [Component.Layout]);

  const handleRefresh = useCallback(() => {
    localStorage.clear();
    router.reload();
  }, [router]);

  if (isWalletWebKilled) {
    return (
      <ErrorBoundary context="dapp_error" fallback={<RootErrorFallback />}>
        <AppIntlProvider messages={messages}>
          <ThemeProvider spectrum="dark">
            <UnderMaintenance />
          </ThemeProvider>
        </AppIntlProvider>
      </ErrorBoundary>
    );
  }

  const userId = Number(Store.get(StoreKeys_userId));
  const deviceId = getDeviceId();

  return (
    <ErrorBoundary
      context="dapp_error"
      fallback={<RootErrorFallback />}
      metadata={errorReportingMetadata}
    >
      <AppIntlProvider messages={messages}>
        <ErrorBoundary
          context="dapp_error"
          fallback={
            <ThemeProvider spectrum="dark">
              <GlobalErrorPage dismissText={dismissText} onDismiss={handleRefresh} />
            </ThemeProvider>
          }
          metadata={errorReportingMetadata}
        >
          <ClientKillSwitchProvider
            initialKillSwitches={initialKillSwitches}
            scope={KILLSWITCHES_CONFIG.scope}
          >
            <DataProvider
              fallback={
                <WalletThemeProvider
                  initialThemeColorPreference={themeColorPreference}
                  initialSpectrumPreference={spectrumPreference}
                >
                  <AppLayout>
                    <AppFallback source="root" />
                  </AppLayout>
                </WalletThemeProvider>
              }
            >
              <ExperimentationProvider
                appVersion={APP_VERSION}
                releaseEnvironment={RELEASE_ENVIRONMENT}
                userId={userId}
                deviceId={deviceId}
                shouldSuspend
              >
                <WalletThemeProvider
                  initialThemeColorPreference={themeColorPreference}
                  initialSpectrumPreference={spectrumPreference}
                >
                  <EventHandlerProvider config={automatedMappingConfig}>
                    <PortalProvider>
                      <GlobalEventMetadataProvider>
                        <CookieManagerProvider>
                          <PageOutageProvider>
                            <SliceProvider>
                              <SearchProvider>
                                <ExperienceModalProvider>
                                  <ShareModalProvider>
                                    <AppHead routeMetadata={routeMetadata} />
                                    <App>
                                      {/* eslint-disable-next-line @cbhq/react-no-nested-component */}
                                      <Layout>
                                        {/* eslint-disable-next-line @cbhq/react-no-nested-component */}
                                        {getLayout(<Component {...pageProps} />)}
                                      </Layout>
                                    </App>
                                  </ShareModalProvider>
                                </ExperienceModalProvider>
                              </SearchProvider>
                            </SliceProvider>
                          </PageOutageProvider>
                        </CookieManagerProvider>
                      </GlobalEventMetadataProvider>
                    </PortalProvider>
                  </EventHandlerProvider>
                </WalletThemeProvider>
              </ExperimentationProvider>
            </DataProvider>
          </ClientKillSwitchProvider>
        </ErrorBoundary>
      </AppIntlProvider>
    </ErrorBoundary>
  );
});

function RootWithInitialProps(props: RootProps) {
  return <MemoizedRoot {...props} />;
}

RootWithInitialProps.getInitialProps = getInitialProps;

export { RootWithInitialProps as Root };
