import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { onrampServiceAuthedConfig } from '../config';
import { OnrampAsset, OnrampAssetResponse } from '../models/OnrampAsset';
import { OnrampFiat } from '../types/OnrampFiat';
import { OnrampPaymentMethod, OnrampPaymentMethodLimit } from '../types/OnrampPaymentMethod';
import { OnrampProvider } from '../types/OnrampProvider';

import { useOnrampAuthedGet } from './useOnrampAuthedGet';
import { useOnrampGeoLocation } from './useOnrampGeoLocation';

const STALE_TIME_IN_MS = 30 * 1000;

const ONRAMP_DEFAULT_OPTIONS_QUERY_KEY = 'payment-providers/v1/defaultOptions';

export type GetOnrampDefaultOptionsResponse = {
  fiat: OnrampFiat;
  asset: OnrampAssetResponse;
  provider: OnrampProvider;
  paymentMethod: OnrampPaymentMethod;
  paymentMethodLimit: OnrampPaymentMethodLimit;
  checkoutConfig: {
    checkoutSessionRequired: boolean;
  };
  availableFiats: OnrampFiat[];
  fiatSections: {
    otherFiats: OnrampFiat[];
    suggestedFiats: OnrampFiat[];
  };
};

export type OnrampDefaultOptionsParams = {
  isConnected: boolean | null;
  enabled?: boolean;
  fiatCode: string;
  suspense?: boolean;
  countryCode?: string;
  subdivisionCode?: string;
};

export type OnrampDefaultOptions = Omit<GetOnrampDefaultOptionsResponse, 'asset'> & {
  asset: OnrampAsset;
};

export function useOnrampDefaultOptions({
  enabled,
  suspense,
  isConnected,
  fiatCode,
  countryCode,
  subdivisionCode,
}: OnrampDefaultOptionsParams): OnrampDefaultOptions | undefined {
  const getDefaultOptions = useOnrampAuthedGet<GetOnrampDefaultOptionsResponse>('defaultOptions');

  const { data } = useQuery({
    queryKey: getQueryKey({ fiatCode, countryCode, subdivisionCode, enabled, isConnected }),
    queryFn: async () =>
      getDefaultOptions({
        countryCode,
        subdivisionCode,
        fiatCode,
        isConnected: Boolean(isConnected),
      }),
    suspense,
    enabled,
    staleTime: STALE_TIME_IN_MS,
    notifyOnChangeProps: ['data'],
    select: selectOnrampDefaultOptionsData,
  });

  return useMemo(() => data && { ...data, asset: new OnrampAsset(data?.asset) }, [data]);
}

type Params = {
  fiatCode: string;
  isConnected: boolean | null;
};

export function useQueryOnrampDefaultOptions({ suspense = false } = {}) {
  const queryClient = useQueryClient();
  const { countryCode, subdivisionCode } = useOnrampGeoLocation({ suspense });
  const getDefaultOptions = useOnrampAuthedGet<GetOnrampDefaultOptionsResponse>('defaultOptions');

  return useCallback(
    async ({ fiatCode, isConnected }: Params) => {
      // always use the authed config when isConnected is explicitly set to true
      // this could happen when isConnected is determined by async action (function call) instead of sync action (hook)
      const options = isConnected
        ? { ...onrampServiceAuthedConfig, withRetailToken: true }
        : undefined;

      const response = await queryClient.fetchQuery({
        queryKey: getQueryKey({
          fiatCode,
          countryCode,
          subdivisionCode,
          enabled: true,
          isConnected,
        }),
        queryFn: async function queryOnrampDefaultOptions() {
          return getDefaultOptions(
            {
              countryCode,
              subdivisionCode,
              fiatCode,
              isConnected: Boolean(isConnected),
            },
            options,
          );
        },
        staleTime: STALE_TIME_IN_MS,
      });

      return selectOnrampDefaultOptionsData(response);
    },
    [countryCode, getDefaultOptions, queryClient, subdivisionCode],
  );
}

export function getQueryKey({
  enabled,
  fiatCode,
  countryCode,
  isConnected,
  subdivisionCode,
}: {
  fiatCode: string;
  countryCode: string | undefined;
  subdivisionCode: string | undefined;
  enabled: boolean | undefined;
  isConnected: boolean | null;
}) {
  return [
    ONRAMP_DEFAULT_OPTIONS_QUERY_KEY,
    countryCode,
    subdivisionCode,
    fiatCode,
    isConnected,
    enabled,
  ];
}

export function selectOnrampDefaultOptionsData(response: GetOnrampDefaultOptionsResponse) {
  response.provider = { ...response.provider, ...response.checkoutConfig };
  response.paymentMethod.provider = response.provider;

  return response;
}
