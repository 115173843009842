import { convertIpfsToHttps } from './convertIpfsToHttps';
import { CloudinaryMediaUrl, isCloudinaryMediaUrl } from './isCloudinaryMediaUrl';

export type MediaQuality = 'max' | 'high' | 'auto' | 'autobest';

/**
 * Mapping internal media quality types to Cloudinary query params
 * See https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
 * and https://cloudinary.com/documentation/image_optimization#set_the_quality_when_delivering_an_image
 * for more information on quality params.
 */
const MEDIA_QUALITY_MAP: Record<MediaQuality, string> = {
  max: '100',
  high: '90',
  auto: 'auto',
  autobest: 'auto:best',
};

export type CloudinaryUrlOptions = {
  width?: number;
  quality?: MediaQuality;
  flag?: 'progressive' | 'animated';
  cropResize?: 'crop' | 'fill' | 'fill_pad' | 'fit' | 'limit' | 'lpad' | 'pad' | 'scale' | 'limit';
  disableUriEncoding?: boolean;
  transformationType?: string;
  retrievalMethod?: 'fetch' | 'upload';
};

export type GetCloudinaryMediaUrlParams = {
  media: string;
  options?: CloudinaryUrlOptions;
  type?: 'image' | 'video';
};

const CLOUDINARY_ROOT_URL = 'https://res.cloudinary.com/coin-nft';

/**
 * Extracts the Cloudinary media URL for an image or video with optional transformations.
 *
 * @param media URL of the media (image or video)
 * @param options Optional flags for transforming the media
 * @param type Type of media ('image' or 'video')
 * @returns cloudinary URL for the media
 * @link https://cloudinary.com/documentation/transformation_reference
 */
export function getCloudinaryMediaUrl({
  media,
  options,
  type = 'image',
}: GetCloudinaryMediaUrlParams): CloudinaryMediaUrl {
  if (isCloudinaryMediaUrl(media)) {
    return media;
  }

  const { width, quality = 'high', flag, cropResize, disableUriEncoding } = options || {};

  const qualityParam = `q_${MEDIA_QUALITY_MAP[quality]}`;
  const widthParam = width ? `,w_${width}` : '';
  const flagParam = flag ? `,fl_${flag}` : '';
  const cropResizeParam = cropResize ? `c_${cropResize},` : '';
  const ipfsHttps = convertIpfsToHttps(String(media));
  const encodedUrl = disableUriEncoding ? ipfsHttps : encodeURI(ipfsHttps);

  const defaultTransformationType = type === 'video' ? 'f_auto:video' : 'f_auto';
  const transformationType = options?.transformationType
    ? `f_${options?.transformationType}`
    : defaultTransformationType;

  return options?.retrievalMethod === 'upload'
    ? `${CLOUDINARY_ROOT_URL}/${type}/upload/${cropResizeParam}${qualityParam}${widthParam}${flagParam}/${transformationType}/v1/cache/${encodedUrl}`
    : `${CLOUDINARY_ROOT_URL}/${type}/fetch/${cropResizeParam}${qualityParam}${widthParam}${flagParam}/${transformationType}/${encodedUrl}`;
}
