import { TrackedAsset } from 'cb-wallet-data/stores/ExchangeRates/types';
import { getNetworkId } from 'cb-wallet-data/stores/Networks/utils/getNetworkId';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { getJSON, postJSON } from 'cb-wallet-http/fetchJSON';
import {
  V1GetQuoteRequest,
  V1GetQuoteResponse,
} from '@cbhq/instant-api-hooks-wallet-quote-service/types';

export type FiatExchangeRate = {
  rateRelativeToUSD: string;
  currencyCode: string;
};

export type CryptoExchangeRate = {
  rateRelativeToUSD: string | undefined;
  lastUpdated: number;
  currencyCode: string | undefined;
  chainId?: string | undefined;
  networkId?: string;
  contractAddress?: string | undefined;
};

type AssetInfo = {
  chainId: string | undefined;
  networkId: string | undefined;
  currencyCode: string;
  contractAddress?: string;
};

function bucketizeAssetsByNetwork(
  wallets: Wallet[],
  manuallyTrackedAssets: TrackedAsset[],
): Record<string, Set<AssetInfo>> {
  const assetsByNetwork: Record<string, Set<AssetInfo>> = {};

  wallets.forEach(function groupAssetByNetwork(wallet) {
    const chainId = wallet.network.asChain()?.chainId?.toString();
    const networkId = getNetworkId({ network: wallet.network, blockchain: wallet.blockchain });

    // Use networkId to identify all networks except custom networks
    const networkIdentifier = networkId || chainId;

    if (networkIdentifier) {
      if (!assetsByNetwork[networkIdentifier]) {
        assetsByNetwork[networkIdentifier] = new Set<AssetInfo>();
      }

      if (wallet.isBaseAsset || wallet.balance) {
        assetsByNetwork[networkIdentifier].add({
          chainId,
          networkId,
          currencyCode: wallet.currencyCode.rawValue,
          contractAddress: wallet.contractAddress,
        });
      }
    }
  });

  manuallyTrackedAssets.forEach(function groupTrackedAssetByNetwork(asset) {
    const networkIdentifier = asset.networkId || asset.chainId;
    if (networkIdentifier && !assetsByNetwork[networkIdentifier]) {
      assetsByNetwork[networkIdentifier] = new Set<AssetInfo>();
    }

    assetsByNetwork[networkIdentifier].add({
      chainId: asset.chainId,
      networkId: asset.networkId,
      currencyCode: asset.code.rawValue,
      contractAddress: asset.contractAddress,
    });
  });

  return assetsByNetwork;
}

const MAX_REQUEST_SIZE = 200;

export function paramsForWallets(
  wallets: Wallet[],
  manuallyTrackedAssets: TrackedAsset[],
): GetQuoteQueryParams[] {
  const entries = Object.entries(bucketizeAssetsByNetwork(wallets, manuallyTrackedAssets));

  const params: GetQuoteQueryParams[] = [];
  // each entry is a list of assets grouped by their network identifier (network Id or chainId)
  for (const entry of entries) {
    const [, assets] = entry;
    if (assets.size === 0) continue;

    const nativeAssetSymbols = new Set<string>();
    const contractAddresses = new Set<string>();

    const firstAsset = assets.values().next().value;
    const chainId = firstAsset ? firstAsset.chainId : undefined;
    const networkId = firstAsset ? firstAsset.networkId : undefined;

    assets.forEach((asset) => {
      if (asset.contractAddress) {
        contractAddresses.add(asset.contractAddress);
      } else {
        nativeAssetSymbols.add(asset.currencyCode);
      }
    });

    // We sort the addresses array to ensure a stable response even when the order of
    // the passed wallets array changes. This allows us to properly hash the returned object
    // for use as a react-query cache key
    const sortedSymbols = Array.from(nativeAssetSymbols).sort();
    const sortedAddresses = Array.from(contractAddresses).sort();

    if (assets.size > MAX_REQUEST_SIZE) {
      // Send MAX_REQUEST_SIZE addresses and all symbols in the first batch
      const firstBatchAddresses = sortedAddresses.slice(0, MAX_REQUEST_SIZE);
      params.push({
        chainId,
        networkId,
        nativeAssetSymbols: sortedSymbols, // Include all nativeAssetSymbols in the first batch
        contractAddresses: firstBatchAddresses,
      });

      // Create batches for the remaining addresses
      for (let i = MAX_REQUEST_SIZE; i < sortedAddresses.length; i += MAX_REQUEST_SIZE) {
        const batchAddresses = sortedAddresses.slice(i, i + MAX_REQUEST_SIZE);
        params.push({
          chainId,
          networkId,
          nativeAssetSymbols: undefined, // Empty for subsequent batches, all nativeAssetSymbols are sent with the first batch
          contractAddresses: batchAddresses,
        });
      }
    } else {
      // If assets.size is not greater than MAX_REQUEST_SIZE, push params as usual
      params.push({
        networkId,
        chainId,
        nativeAssetSymbols: sortedSymbols,
        contractAddresses: sortedAddresses,
      });
    }
  }

  return params;
}

type CryptoRate = {
  price: number;
  lastUpdated: number;
  symbol: string;
  name: string;
  contractAddress?: string;
  coinbaseAssetId?: string;
  chainId?: number;
};

export type CryptoRatesResponse = {
  result: {
    rates: CryptoRate[];
  };
};

export type GetQuoteResponse = V1GetQuoteResponse;
export type GetQuoteQueryParams = V1GetQuoteRequest;

export async function fetchCryptoExchangeRates(
  params: GetQuoteQueryParams,
): Promise<CryptoExchangeRate[]> {
  const { results } = await postJSON<GetQuoteResponse>('quote/getQuote', params);
  if (!results) return [];

  return results.reduce(function reduceCryptoExchangeRates(acc, rate) {
    if (!rate) return acc;

    const code = rate.symbol?.toUpperCase();

    if (rate.price !== 0 && !!code) {
      acc.push({
        currencyCode: code,
        rateRelativeToUSD: rate.price?.toString(),
        lastUpdated: rate.timestamp ? new Date(rate.timestamp).getTime() : Date.now(),
        // The backend returns contract addresses for some native assets (e.g., AVAX, POL, SOL).
        // The client always assumes an undefined contract address for native assets; hence, this check is necessary.
        contractAddress: rate.isNative ? undefined : rate.contractAddress,
        // We use networkId and chainId from the request params to populate the exchangeRatesMap.
        // This ensures consistency between the client's query request and the exchangeRatesMap's keys,
        networkId: params.networkId,
        chainId: params.chainId,
      });
    }

    return acc;
  }, [] as CryptoExchangeRate[]);
}

type GetFiatExchangeRatesDTO = {
  result: {
    rates: Record<string, number>;
  };
};

export async function fetchFiatExchangeRates(currencyCodes: string): Promise<FiatExchangeRate[]> {
  const { result } = await getJSON<GetFiatExchangeRatesDTO>('getExchangeRates', {
    symbols: currencyCodes,
  });

  return Object.entries(result.rates).reduce(function reduceFiatExchangeRates(acc, rate) {
    const [currency, value] = rate;
    if (value > 0) {
      acc.push({ currencyCode: currency, rateRelativeToUSD: value.toString() });
    }
    return acc;
  }, [] as FiatExchangeRate[]);
}
