import { useHasAccountsAndWalletGroups } from 'cb-wallet-data/hooks/initialization/useHasAccountsAndWalletGroups';
import { useOnMount } from 'cb-wallet-data/hooks/useOnMount';
import { useSearchParams } from 'next/navigation';

import { DrawerName } from ':dapp/components/GlobalDrawer/constants';
import { SendAndReceiveDrawer } from ':dapp/components/Send/SendAndReceiveDrawer';
import { StakeSidebarDrawer } from ':dapp/components/Stake/StakeSidebarDrawer';
import { SwapSidebarDrawer } from ':dapp/components/Swap/SwapSidebar/SwapSidebarDrawer';

type UseLinkToGlobalDrawerProps = {
  openDrawer: (drawerContent: React.ReactNode) => void;
};

/**
 * Opens the global drawer based on the drawer query param
 * This enables deeplinking to know flows.
 *
 * This hook should only be called once on mount from within the
 * GlobalDrawerProvider.
 */
export function useLinkToGlobalDrawer({ openDrawer }: UseLinkToGlobalDrawerProps) {
  const searchParams = useSearchParams();
  const hasAccountsAndWalletGroups = useHasAccountsAndWalletGroups();

  useOnMount(() => {
    // Global actions require accounts and wallet groups to be loaded
    if (!hasAccountsAndWalletGroups) {
      return;
    }
    const drawer = searchParams.get('drawer');
    if (!drawer) {
      return;
    }
    switch (drawer) {
      case DrawerName.Swap:
        openDrawer(<SwapSidebarDrawer />);
        break;
      case DrawerName.Send:
        openDrawer(<SendAndReceiveDrawer />);
        break;
      case DrawerName.Stake:
        openDrawer(<StakeSidebarDrawer />);
        break;
      // Note: buy flow requires auth so if we need to allow deeplinking
      // to buy flow we need to handle auth in the drawer
      //  case DrawerName.Buy:
      //    openDrawer(<BuyFlowDrawer />);
      //    break;
      default:
        break;
    }
  });

  return null;
}
