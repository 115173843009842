import { useEffect } from 'react';
import { logDataEvent } from 'cb-wallet-analytics/utils/log';
import { Box } from '@cbhq/cds-web/layout';
import { Spinner } from '@cbhq/cds-web/loaders/Spinner';

export type AppFallbackProps = {
  source: string;
};

export function AppFallback({ source }: AppFallbackProps) {
  useEffect(
    function AppFallbackTest() {
      const startTime = Date.now();
      let intervalCount = 0;

      // Log root.fallback_spinner.long_wait event every 10 seconds.
      // This will enable us to get an estimate of how long the user experienced the spinner if Appfallback doesn't unmount
      const intervalRefLocal = window.setInterval(() => {
        intervalCount += 1;
        logDataEvent('root.fallback_spinner.long_wait', {
          source,
          count: intervalCount, // Log the interval
        });
      }, 10_000);

      return () => {
        clearInterval(intervalRefLocal);
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);

        if (elapsedTime > 10) {
          // Log 'root.fallback_spinner.unmount' with the total time (in seconds) if the user saw the fallback for at least 10s
          // This tracks the full duration of the spinner.
          logDataEvent('root.fallback_spinner.unmount', {
            source,
            elapsedTime,
          });
        }
      };
    },
    [source],
  );

  return (
    <Box
      testID="app-fallback"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <Spinner size={4} color="primary" />
    </Box>
  );
}
