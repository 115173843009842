// Used when there is a problem fetching the balance for the
// base asset for a network, e.g. Ether on Ethereum or Pol on Polygon
export class BaseAssetError extends Error {}

// Used when there is a problem fetching the balance for an erc20 token
export class ERC20TokenError extends Error {}

// Used when there is missing critical metadata like symbol,
// decimal, etc for an ERC20 token
export class ERC20MissingMetadataError extends Error {}

// Used for UTXO fetching when the number of addresses sent does not match the
// number of addresses received from the indexer service
export class DroppedAddressesError extends Error {
  failureRate: number;
  constructor(message: string, failureRate: number) {
    super(message);
    this.failureRate = failureRate;
  }
}

// For general errors around fetching UTXO Balances
export class UTXOBalanceSyncingError extends Error {}

// Sent when the server updates the blockheight even when the response
// is faulty (e.g. an address is missing)
export class SyncedBlockheightError extends Error {}

export class InvalidAddressError extends Error {}

// Generic transaction syncing error
export class TransactionSyncingError extends Error {
  blockchainSymbol: string;
  constructor(message: string, blockchainSymbol: string) {
    super(message);
    this.blockchainSymbol = blockchainSymbol;
  }
}

// Used when requests to sync transactions get rate limited
// (most often used by Etherscan tx syncing)
export class TransactionSyncingRateLimitError extends Error {}

// Used when the number of retries for an individual transaction sync is exceeded
export class TransactionMaxRetriesError extends Error {
  blockchainSymbol: string;
  chainId: bigint;
  constructor(message: string, blockchainSymbol: string, chainId?: bigint) {
    super(message);
    this.blockchainSymbol = blockchainSymbol;
    this.chainId = chainId || BigInt(0);
  }
}

export type TransactionError = TransactionSyncingError | TransactionSyncingRateLimitError;

export class WebsocketConnectionError extends Error {}

export class WebsocketMessageParseError extends Error {}
