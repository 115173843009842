import {
  ActionType,
  AnalyticsEventImportance,
  ComponentType,
  logMetric,
  MetricType,
} from '@cbhq/client-analytics';

import { logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';
import { AccountTypeEventPropertyValue, EventProperties } from '../utils/types/eventProperties';

export function triggerAuthMigrationSucceeded() {
  logDataEvent('auth.migration.succeeded', {
    loggingId: '7ce95614-a279-4449-b706-c49e7665c166',
  });
}

export function triggerAuthMigrationFailed() {
  logDataEvent('auth.migration.failed', {
    loggingId: 'eeee1634-fdf6-43f4-b9ee-afe73458e7cc',
  });
}

function getAuthStateIsValid(authState: AuthState) {
  if (authState.appLockType === 'biometrics' && authState.availableBiometry === 'none') {
    return false;
  }

  if (authState.isAuthRequiredForAppAccess === false && authState.isAuthRequiredForTx === false) {
    return false;
  }

  if (authState.areBiometricsInvalidated && authState.biometricProtectionType === 'anyBiometrics') {
    return false;
  }

  return true;
}

export function reportAuthModuleReady({ isReady, depth }: { isReady: boolean; depth: number }) {
  logDataEvent(
    'auth.state.moduleReady',
    {
      loggingId: '9e9b2dae-d6cb-48c9-8daa-3bc31da636ae',
      data: {
        isReady,
        depth,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMismatchedAddressError({
  accountsLength,
  hasUnexpectedDatabaseState,
  isMultiAccountPhase2Enabled,
}: {
  accountsLength: number;
  hasUnexpectedDatabaseState: boolean;
  isMultiAccountPhase2Enabled: boolean;
}) {
  logDataEvent(
    'auth.address_mismatch',
    {
      loggingId: 'bca93b66-d4e4-40dc-8746-6ba55d2bdda0',
      data: {
        accountsLength,
        hasUnexpectedDatabaseState,
        isMultiAccountPhase2Enabled,
      },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDecryptMnemonicsWithPINStart() {
  logDataEvent(
    'auth.decrypt_mnemonic_with_pin.start',
    {
      loggingId: '1805cc82-b3cc-43e7-9e43-850b5b86842e',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDecryptMnemonicsWithPINSuccess() {
  logDataEvent(
    'auth.decrypt_mnemonic_with_pin.success',
    {
      loggingId: '07a22fe3-6223-460e-b4c9-f3f1d3e79512',
    },
    AnalyticsEventImportance.high,
  );
}

type AuthFailureProps = {
  failureReason?: string;
  errorMessage?: string;
};

export function triggerDecryptMnemonicsWithPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.decrypt_mnemonic_with_pin.failure',
    {
      loggingId: '280effe5-862a-4d41-aa10-09f5dc1f4ea8',
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUserCancelledGetUnlockedMnemonicsRequest() {
  return logEvent(
    'auth.unlock_mnemonics.cancelled',
    {
      loggingId: '75f91353-cb10-4031-b4b2-098f8e7339fb',
      action: ActionType.click,
      componentType: ComponentType.button,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicMigrationWithPINStart() {
  logDataEvent(
    'auth.mnemonic_migration_with_pin.start',
    {
      loggingId: '5828df95-95b4-41a0-8bcc-5a0261fd7d6d',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicMigrationWithPINSuccess() {
  logDataEvent(
    'auth.mnemonic_migration_with_pin.success',
    {
      loggingId: '064e8475-6502-4902-9c9e-61e4652ed23c',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicMigrationWithPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.mnemonic_migration_with_pin.failure',
    {
      loggingId: 'b13cae49-9def-487f-8357-67fa686377a8',
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDecryptMnemonicsWithBiometricsStart() {
  logDataEvent(
    'auth.decrypt_mnemonics_with_biometrics.start',
    {
      loggingId: '8f6a0daa-b169-4a21-8da7-89e9978b7598',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDecryptMnemonicsWithBiometricsSuccess() {
  logDataEvent(
    'auth.decrypt_mnemonics_with_biometrics.success',
    {
      loggingId: '26b03699-527d-49e8-bf6f-153257d8efc5',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDecryptMnemonicsWithBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.decrypt_mnemonic_with_biometrics.failure',
    {
      loggingId: '07a22fe3-6223-460e-b4c9-f3f1d3e79512',
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerVerifyUserForNonMnemonicWithBiometricsStart() {
  logDataEvent('auth.verify_user_with_biometrics.start', {
    loggingId: 'cb71a60b-0b5e-47e3-b6d7-310797b2397c',
  });
}

export function triggerVerifyUserForNonMnemonicWithBiometricsSuccess() {
  logDataEvent('auth.verify_user_with_biometrics.success', {
    loggingId: '3e6e7a07-36f7-432e-b7a0-e8b6481e0e1f',
  });
}

export function triggerVerifyUserForNonMnemonicWithBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.verify_user_with_biometrics.failure', {
    loggingId: 'd935759e-644d-4a63-93d2-eece996c99e9',
    data: { failureReason, errorMessage },
  });
}

export function triggerSaveMnemonicForAccountUsingPINStart() {
  logDataEvent('auth.save_mnemonic_for_account_using_pin.start', {
    loggingId: '5bae99d6-bc10-4a22-90ed-09be4920084d',
  });
}

export function triggerSaveMnemonicForAccountUsingPINSuccess() {
  logDataEvent('auth.save_mnemonic_for_account_using_pin.success', {
    loggingId: '2e2f2795-bd2f-4f03-945a-36ad07e9e54b',
  });
}

export function triggerSaveMnemonicForAccountUsingPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.save_mnemonic_for_account_using_pin.failure', {
    loggingId: 'b4196c8d-ab5d-4a9b-b056-4dc3e2202bb6',
    data: { failureReason, errorMessage },
  });
}

export function triggerSaveMnemonicForAccountUsingBiometricsStart() {
  logDataEvent('auth.save_mnemonic_for_account_using_biometrics.start', {
    loggingId: '38f37c87-b068-4abb-9a37-db79febbae00',
  });
}

export function triggerSaveMnemonicForAccountUsingBiometricsSuccess() {
  logDataEvent('auth.save_mnemonic_for_account_using_biometrics.success', {
    loggingId: 'e2a7d729-3e20-4a03-870d-729a6d0bae6e',
  });
}

export function triggerSaveMnemonicForAccountUsingBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.save_mnemonic_for_account_using_biometrics.failure', {
    loggingId: 'f7e458a4-f0e4-4812-a839-fb3412359d2f',
    data: { failureReason, errorMessage },
  });
}

export function triggerDeleteMnemonicForAccountUsingPINStart() {
  logDataEvent('auth.delete_mnemonic_for_account_using_pin.start', {
    loggingId: '9a40ea32-78e9-4bf6-9a4d-5079e0bb6e28',
  });
}

export function triggerDeleteMnemonicForAccountUsingPINSuccess() {
  logDataEvent('auth.delete_mnemonic_for_account_using_pin.success', {
    loggingId: '31b318e9-1f22-4e23-8452-ed4f1288d99b',
  });
}

export function triggerDeleteMnemonicForAccountUsingPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.delete_mnemonic_for_account_using_pin.failure', {
    loggingId: '991a3508-a0c9-4a35-bca0-3a3c4dcd7365',
    data: { failureReason, errorMessage },
  });
}

export function triggerDeleteMnemonicForAccountUsingBiometricsStart() {
  logDataEvent('auth.delete_mnemonic_for_account_using_biometrics.start', {
    loggingId: '0d5a1162-53e5-4266-9731-d4732a9fc211',
  });
}

export function triggerDeleteMnemonicForAccountUsingBiometricsSuccess() {
  logDataEvent('auth.delete_mnemonic_for_account_using_biometrics.success', {
    loggingId: '3d3f4125-16de-4587-a4bf-47aa9c5cdfb8',
  });
}

export function triggerDeleteMnemonicForAccountUsingBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.delete_mnemonic_for_account_using_biometrics.failure', {
    loggingId: 'a505d380-8b16-41ff-851b-39d2a04d38b2',
    data: { failureReason, errorMessage },
  });
}

export function triggerMnemonicMigrationWithBiometricsStart() {
  logDataEvent(
    'auth.mnemonic_migration_with_biometrics.start',
    {
      loggingId: '0fbbe71f-2552-41ff-9208-0ddd5c6751d1',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicMigrationWithBiometricsSuccess() {
  logDataEvent(
    'auth.mnemonic_migration_with_biometrics.success',
    {
      loggingId: '343f77c5-042e-4627-8920-22ffda6a1f57',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicMigrationWithBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.mnemonic_migration_with_biometrics.failure',
    {
      loggingId: '266fc7fd-65b0-413c-8776-d29a67d3fcfa',
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerSaveMnemonicsUsingBiometricsFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.save_mnemonic_using_biometrics.failure',
    {
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function logMigrateMnemonicsWithBiometricsFailure(failureReason = 'Unknown error') {
  logMetric({
    metricName: 'auth.migrate_mnemonic_with_biometrics.failure',
    metricType: MetricType.count,
    value: 1,
    tags: {
      failureReason,
    },
  });
}

export function triggerSaveMnemonicsUsingPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.save_mnemonic_using_pin.failure',
    {
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerSavePINforNonMnemonicAccountSuccess() {
  logDataEvent('auth.save_pin_for_non-mnemonic_account.success', {}, AnalyticsEventImportance.high);
}

export function triggerSavePINforNonMnemonicAccountStart() {
  logDataEvent('auth.save_pin_for_non-mnemonic_account.start', {}, AnalyticsEventImportance.high);
}

export function triggerSavePINforNonMnemonicAccountFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.save_pin_for_non-mnemonic_account.failure',
    {
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDeletePINforNonMnemonicAccountSuccess() {
  logDataEvent(
    'auth.delete_pin_for_non-mnemonic_account.success',
    {},
    AnalyticsEventImportance.high,
  );
}

export function triggerDeletePINforNonMnemonicAccountStart() {
  logDataEvent('auth.delete_pin_for_non-mnemonic_account.start', {}, AnalyticsEventImportance.high);
}

export function triggerDeletePINforNonMnemonicAccountFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.delete_pin_for_non-mnemonic_account.failure',
    {
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUnlockNonMnemonicAccountWithPINSuccess() {
  logDataEvent(
    'auth.unlock_non-mnemonic_account_with_pin.success',
    {},
    AnalyticsEventImportance.high,
  );
}

export function triggerUnlockNonMnemonicAccountWithPINStart() {
  logDataEvent(
    'auth.unlock_non-mnemonic_account_with_pin.start',
    {},
    AnalyticsEventImportance.high,
  );
}

export function triggerUnlockNonMnemonicAccountWithPINFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent(
    'auth.unlock_non-mnemonic_account_with_pin.failure',
    {
      data: { failureReason, errorMessage },
    },
    AnalyticsEventImportance.high,
  );
}

export function logMigrateMnemonicsUsingPINFailure(failureReason: string) {
  logMetric({
    metricName: 'auth.migrate_mnemonic_using_pin.failure',
    metricType: MetricType.count,
    value: 1,
    tags: {
      failureReason,
    },
  });
}

export function triggerMnemonicAccessRequest(
  mnemonicAccessContext: EventProperties['mnemonicAccessContext'],
) {
  logDataEvent(
    'auth.mnemonic_access_request',
    {
      loggingId: 'fcd87cc7-0b45-4db1-a6ac-7ce5d7cf60c8',
      mnemonicAccessContext,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerBackfillPrimaryAddressWithMnemonicStart() {
  logDataEvent(
    'auth.backfill_primary_address_with_mnemonic.start',
    {
      loggingId: '105c2486-d692-4709-91e6-950b286f3b66',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerBackfillPrimaryAddressWithMnemonicSuccess() {
  logDataEvent(
    'auth.backfill_primary_address_with_mnemonic.success',
    {
      loggingId: '3985ecd9-fcbf-4664-b6a5-d283307ecb37',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicAuthenticationStart() {
  logDataEvent(
    'auth.mnemonic_authentication.start',
    {
      loggingId: 'bfd2bd2b-6972-4724-8630-533b7b052f76',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicAuthenticationSuccess() {
  logDataEvent(
    'auth.mnemonic_authentication.success',
    {
      loggingId: '0c1d92b0-83b2-4a25-8185-d480b6bab2ae',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerMnemonicAuthenticationFailure(
  failureReason: EventProperties['mnemonicAuthFailureReason'],
) {
  logDataEvent(
    'auth.mnemonic_authentication.failure',
    {
      loggingId: '63e7d580-a70f-4c77-9377-fb57085947aa',
      mnemonicAuthFailureReason: failureReason,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUseMnemonicSuccessCallbackStart() {
  logDataEvent(
    'auth.use_mnemonic_success_callback.start',
    {
      loggingId: '4efbd2b4-4d2e-4c5d-94b9-53d85cb6136a',
    },
    AnalyticsEventImportance.high,
  );
}

export function useLogMnemonicBackupRecoveryPhraseViewed() {
  useLogEventOnMount('auth.mnemonic_backup_recovery_phrase.viewed', {
    loggingId: 'e0b820bf-8a7b-424b-8e8a-a9043f84cf36',
    componentType: ComponentType.page,
  });
}

export function triggerAuthTokenRefreshStart({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue | undefined;
}) {
  logDataEvent(
    'auth.token_refresh.start',
    {
      loggingId: 'ffe457d0-1c96-4ca9-912c-112b3144a8d2',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerAuthTokenRefreshFailure({
  accountType,
  failureReason,
}: {
  accountType?: AccountTypeEventPropertyValue;
  failureReason: string;
}) {
  logDataEvent(
    'auth.token_refresh.failure',
    {
      loggingId: '6ba45a2c-a41b-4ebc-bbc5-0b2634044ffb',
      accountType,
      data: { failureReason },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerAuthTokenRefreshSuccess({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue | undefined;
}) {
  logDataEvent(
    'auth.token_refresh.success',
    {
      loggingId: '00ed8ccc-2922-434c-b2dc-cccd48d07031',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUpdateAuthTokensStart({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue | undefined;
}) {
  logDataEvent(
    'auth.update_auth_tokens.start',
    {
      loggingId: '3b4d2070-4ed3-42d8-85ea-8727c9788f91',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUpdateAuthTokensFailure({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue | undefined;
}) {
  logDataEvent(
    'auth.update_auth_tokens.failure',
    {
      loggingId: 'd269931e-fea1-4dc2-97fa-09b5c01a0ad0',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerUpdateAuthTokensSuccess({
  accountType,
}: {
  accountType: AccountTypeEventPropertyValue | undefined;
}) {
  logDataEvent(
    'auth.update_auth_tokens.success',
    {
      loggingId: '44d14205-c785-4272-a921-f9200c30402a',
      accountType,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerInvalidRefreshTokenDetected() {
  logDataEvent(
    'auth.invalid_refresh_token.detected',
    {
      loggingId: 'cfea4557-71be-4db2-b9b9-75c64f44f11a',
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerInvalidRefreshTokenHandled() {
  logDataEvent(
    'auth.invalid_refresh_token.handled',
    {
      loggingId: '5eca6e4f-8c0c-44dd-9497-a82059224f65',
    },
    AnalyticsEventImportance.high,
  );
}

/**
 * @deprecated
 * */
export function triggerMnemonicAccountMismatchDetected({
  accountsLength,
  mnemonicsLength,
}: {
  accountsLength: number;
  mnemonicsLength: number;
}) {
  logDataEvent('auth.mnemonic_account_mismatch.detected', {
    accountsLength,
    mnemonicsLength,
  });
}

export function logSetMnemonicAccountConsistencyCheckedAt() {
  logDataEvent(
    'auth.mnemonic_account_consistency.set_checked_at',
    {},
    AnalyticsEventImportance.high,
  );
}

export function logShouldCheckMnemonicAccountConsistencyStarted() {
  logDataEvent(
    'auth.should_check_mnemonic_account_consistency.started',
    {},
    AnalyticsEventImportance.high,
  );
}

export function logShouldCheckMnemonicAccountConsistencyResult({
  mnemonicAccountConsistencyCheckResult,
}: {
  mnemonicAccountConsistencyCheckResult: boolean;
}) {
  logDataEvent(
    'auth.should_check_mnemonic_account_consistency.result',
    {
      mnemonicAccountConsistencyCheckResult,
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerDetectMnemonicAccountMismatchResult({
  mnemonicAccountsLength,
  mnemonicsLength,
  hasMoreMnemonicsThanAccounts,
  hasMoreAccountsThanMnemonics,
  isMnemonicMissing,
}: {
  mnemonicAccountsLength: number;
  mnemonicsLength: number;
  hasMoreMnemonicsThanAccounts: boolean;
  hasMoreAccountsThanMnemonics: boolean;
  isMnemonicMissing: boolean;
}) {
  logDataEvent('auth.detect_mnemonic_account_mismatch.result', {
    mnemonicAccountsLength,
    mnemonicsLength,
    hasMoreMnemonicsThanAccounts,
    hasMoreAccountsThanMnemonics,
    isMnemonicMissing,
  });
}

export function triggerUnlockRequested(type: ExtensionUnlockType) {
  logDataEvent('auth.unlock.requested', {
    type,
  });
}

export function triggerUnlockHandled({
  type,
  canAutoUnlock,
}: {
  type: ExtensionUnlockType;
  canAutoUnlock?: boolean;
}) {
  logDataEvent('auth.unlock.handled', {
    type,
    canAutoUnlock,
  });
}

export function triggerMnemonicDeletionStart() {
  logDataEvent('auth.mnemonic_deletion.start', {});
}

export function triggerMnemonicDeletionSuccess() {
  logDataEvent('auth.mnemonic_deletion.success', {});
}

export function triggerMnemonicDeletionFailure(
  failureReason: EventProperties['mnemonicAuthFailureReason'],
) {
  logDataEvent('auth.mnemonic_deletion.failure', {
    mnemonicAuthFailureReason: failureReason,
  });
}

export function useTriggerDeleteMnemonicAuthenticatorMount() {
  useLogEventOnMount('auth.delete_mnemonic_authenticator.mount', {
    componentType: ComponentType.modal,
  });
}

export function useTriggerDeleteWithBiometricsPromptViewed() {
  useLogEventOnMount('auth.delete_with_biometrics_prompt.viewed', {
    componentType: ComponentType.modal,
  });
}

export function useTriggerDeleteWithPasscodeModalViewed() {
  useLogEventOnMount('auth.delete_with_passcode_modal.viewed', {
    componentType: ComponentType.modal,
  });
}

type AuthState = {
  hasMnemonic: boolean;
  hasSecrets: boolean;
  appLockType: AuthType;
  biometricProtectionType: BiometricsProtectionType | undefined;
  areBiometricsInvalidated: boolean;
  isAuthRequiredForAppAccess: boolean | undefined;
  isAuthRequiredForTx: boolean | undefined;
  availableBiometry: BiometryType | undefined;
  hasSalt: boolean | undefined;
  hasEncryptedMnemonic: boolean | undefined;
  hasPinSalt: boolean | undefined;
  hasBiometricsSalt: boolean | undefined;
  hasEncryptedMnemonicForPin: boolean | undefined;
  hasEncryptedMnemonicForBiometrics: boolean | undefined;
};

type ExtensionUnlockType =
  | 'appAccess'
  | 'transactionAccess'
  | 'multiSecretAppAccess'
  | 'UNKNOWN_UNLOCK_TYPE';
type AuthType = 'pin' | 'biometrics';
type BiometricsProtectionType = 'anyBiometrics' | 'currentBiometrics' | 'legacy';
type BiometryType = 'touchID' | 'faceID' | 'fingerprint' | 'none'; // 'fingerprint' is for Android devices

// eslint-disable-next-line @cbhq/ts-no-enum
export enum MnemonicAccessContext {
  AppUnlock = 'AppUnlock',
  CreateWalletGroup = 'CreateWalletGroup',
  FetchNewAuthTokens = 'FetchNewAuthTokens',
  MultiAccountWalletCreation = 'MultiAccountWalletCreation',
  SignMessage = 'SignMessage',
  SignTransaction = 'SignTransaction',
  RecoveryPhrase = 'RecoveryPhrase',
  CloudBackup = 'CloudBackup',
  DebugMenu = 'DebugMenu',
  ToggleBiometricProtection = 'ToggleBiometricProtection',
  ToggleAppAccess = 'ToggleAppAccess',
  ToggleTxAuth = 'ToggleTxAuth',
  AppLockMethod = 'AppLockMethod',
  SaveMnemonic = 'SaveMnemonic',
  EscapeHatch = 'EscapeHatch',
  AddressMismatch = 'AddressMismatch',
  PrivateKey = 'PrivateKey',
  RecoveryMode = 'RecoveryMode',
  BackupAccount = 'BackupAccount',
}

export function logUserPasswordLockOut(passwordAttempts: number) {
  return logDataEvent('unlock.locked_step.password_error', {
    passwordAttempts: passwordAttempts.toString(10),
    errorType: 'timedLockOut',
  });
}

export function logUserHasLimitedAttemptsRemaining(passwordAttempts: number) {
  return logDataEvent('unlock.locked_step.password_error', {
    passwordAttempts: passwordAttempts.toString(10),
    errorType: 'triesRemaining',
  });
}

export function logExtensionUnlockButtonPressed() {
  logEvent('unlock.locked_step.unlock_btn', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logUserHasDbAccountsDuringOnboarding({
  dbAccountsDuringOnboardingLength,
  onboardingMode,
  context,
}: {
  dbAccountsDuringOnboardingLength: number;
  onboardingMode?: 'restore' | 'create';
  context: 'onboardMnemonicAccount' | 'landing' | 'restoreWallet';
}) {
  logDataEvent('auth.has_accounts_during_onboarding', {
    dbAccountsDuringOnboardingLength,
    onboardingType: onboardingMode,
    context,
  });
}

// getAuthenticationState start

/**
 * Logs at the start of an individual call to get auth state.
 */
export function logGetAuthStateStart({ cid, context }: { cid: string; context: 'unknown' }) {
  logDataEvent('auth.get_auth_state.start', { cid, context });
}

/**
 * Logs on the success of an individual call to get auth state.
 */
export function logGetAuthStateSuccess({
  authState,
  cid,
  context,
}: {
  authState: AuthState;
  cid: string;
  context: 'unknown';
}) {
  const isAuthStateValid = getAuthStateIsValid(authState);

  logEvent(
    // used to be auth.state.report
    'auth.get_auth_state.success',
    {
      action: ActionType.unknown,
      appLockType: authState.appLockType,
      areBiometricsInvalidated: authState.areBiometricsInvalidated,
      availableBiometry: authState.availableBiometry,
      biometricProtectionType: authState.biometricProtectionType,
      cid,
      componentType: ComponentType.unknown,
      context,
      hasEncryptedMnemonic: authState.hasEncryptedMnemonic,
      hasMnemonic: authState.hasMnemonic,
      hasSalt: authState.hasSalt,
      hasSecrets: authState.hasSecrets,
      isAuthRequiredForAppAccess: authState.isAuthRequiredForAppAccess,
      isAuthRequiredForTx: authState.isAuthRequiredForTx,
      isAuthStateValid,
    },
    AnalyticsEventImportance.high,
    true,
  );
}

/**
 * Logs on the failure of an individual call to get auth state.
 */
export function logGetAuthStateFailure({
  cid,
  context,
  errorMessage,
}: {
  cid: string;
  context: 'unknown';
  errorMessage: string;
}) {
  logDataEvent('auth.get_auth_state.failure', { cid, context, errorMessage });
}

/**
 * Logs once at the start of the auth state retrying process.
 * Note: If getAuthenticationState does not fail during recoil initialization, retrying will not occur.
 */
export function logAuthStateRetryStart() {
  logDataEvent('auth.auth_state_retry.start', {});
}

/**
 * Logs once after retry attempts result in a successful auth state.
 */
export function logAuthStateRetrySuccess({
  waitTime,
  numRetryAttempts,
}: {
  waitTime: number;
  numRetryAttempts: number;
}) {
  logDataEvent('auth.auth_state_retry.success', {
    data: { waitTime, numRetryAttempts },
  });
}

/**
 * Logs once after we've reached the time limit for allowed retry
 * attempts without success.
 */
export function logAuthStateRetryFailure({
  waitTime,
  numRetryAttempts,
}: {
  waitTime: number;
  numRetryAttempts: number;
}) {
  logDataEvent('auth.auth_state_retry.failure', {
    data: { waitTime, numRetryAttempts },
  });
}

/**
 * If getAuthenticationState fails during recoil initialization, RootStack will
 * render this component while auth state is being retried.
 */
export function useLogAuthStateFallbackViewed() {
  useLogEventOnMount('auth.auth_state_fallback.viewed', {
    componentType: ComponentType.page,
  });
}

/**
 * If auth state retrying times out, the loading screen will render
 * this error component.
 */
export function logAuthStateTimeoutViewed() {
  logDataEvent('auth.auth_state_timeout.viewed', {});
}

// getAuthenticationState end

// logUpdateAuthStat start

// {@link} should match the UpdateAuthStateContext in useUpdateAuthState
type UpdateAuthStateContext = 'onMount' | 'onForeground' | 'debug' | 'saltRecovery';

export function logUpdateAuthStateStart({
  cid,
  context,
}: {
  cid: string;
  context: UpdateAuthStateContext;
}) {
  logDataEvent('auth.update_auth_state.start', {
    cid,
    context,
  });
}

export function logUpdateAuthStateSuccess({
  cid,
  context,
}: {
  cid: string;
  context: UpdateAuthStateContext;
}) {
  logDataEvent('auth.update_auth_state.success', {
    cid,
    context,
  });
}

export function logUpdateAuthStateFailure({
  cid,
  context,
}: {
  cid: string;
  context: UpdateAuthStateContext;
}) {
  logDataEvent('auth.update_auth_state.failure', {
    cid,
    context,
  });
}

export function logUpdateAuthStateAbort({
  cid,
  context,
}: {
  cid: string;
  context: UpdateAuthStateContext;
}) {
  logDataEvent('auth.update_auth_state.abort', {
    cid,
    context,
  });
}

// logUpdateAuthState end

// logNativeStorageMetadata start

export function logNativeStorageMetadata({
  keychainItems,
  userDefaultsKeys,
  bundleIdentifier,
  sharedPreferencesItems,
  keystoreAliases,
}: {
  keychainItems?: Record<string, string>[];
  userDefaultsKeys?: string;
  bundleIdentifier?: string;
  sharedPreferencesItems?: Record<string, string>[];
  keystoreAliases?: string;
}) {
  logDataEvent('auth.native_storage_metadata', {
    data: {
      keychainItems,
      userDefaultsKeys,
      bundleIdentifier,
      sharedPreferencesItems,
      keystoreAliases,
    },
  });
}

export function logGetNativeStorageMetadataStart() {
  logDataEvent('auth.get_native_storage_metadata.start', {});
}

export function logGetNativeStorageMetadataSuccess() {
  logDataEvent('auth.get_native_storage_metadata.success', {});
}

export function logGetNativeStorageMetadataFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.get_native_storage_metadata.failure', {
    data: { failureReason, errorMessage },
  });
}
// logNativeStorageMetadata end

// logRecoverMnemonicSalt start

export function logRecoverMnemonicSaltStart() {
  logDataEvent('auth.recover_mnemonic_salt.start', {});
}

type RecoverMnemonicSaltSuccessContext = 'recovered' | 'unneeded';

export function logRecoverMnemonicSaltSuccess({
  context,
}: {
  context: RecoverMnemonicSaltSuccessContext;
}) {
  logDataEvent('auth.recover_mnemonic_salt.success', {
    context,
  });
}

export function logRecoverMnemonicSaltFailure({
  failureReason = 'Unknown error',
  errorMessage = 'Unknown error',
}: AuthFailureProps) {
  logDataEvent('auth.recover_mnemonic_salt.failure', {
    data: { failureReason, errorMessage },
  });
}

// logRecoverMnemonicSalt end
