import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { logEvent } from 'cb-wallet-analytics/utils/log';
import { useRecentSearches } from 'cb-wallet-data/stores/Search/hooks/useRecentSearches';
import { RecentSearch, SearchEntity } from 'cb-wallet-data/stores/Search/types';
import { SearchEntitySubTitles } from 'cb-wallet-data/stores/Search/util';
import { useRouter } from 'next/router';
import { AssetAvatar } from 'wallet-cds-web/components/AssetAvatar';
import { DAppAvatar } from 'wallet-cds-web/components/DAppAvatar';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { CellSpacing } from '@cbhq/cds-common';
import { ListCell } from '@cbhq/cds-web/cells/ListCell';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { TextHeadline, TextTitle3 } from '@cbhq/cds-web/typography';
import { ActionType, ComponentType } from '@cbhq/client-analytics';
import { useSearchContext } from ':dapp/providers/SearchProvider';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';
const messages = defineMessages({
  title: {
    defaultMessage: 'Recent',
    description: 'Title for recent searches'
  },
  clearLabel: {
    defaultMessage: 'Clear',
    description: 'Label for clearing all searches'
  }
});
const showPointerOnHover = "s1f27hdz";
const outerCellSpacing: CellSpacing = {
  spacingHorizontal: 2
};
export function RecentSearches() {
  const {
    formatMessage
  } = useIntl();
  const {
    recentSearches,
    deleteAllRecentSearches
  } = useRecentSearches();
  const router = useRouter();
  const {
    toggleSearchOff
  } = useSearchContext();
  const handlePress = useCallback(({
    id,
    assetName,
    navigationPath,
    searchEntity
  }: RecentSearch<SearchEntity>) => {
    return () => {
      logEvent('global_search.recent_search.clicked', {
        action: ActionType.click,
        componentType: ComponentType.button,
        searchResultName: assetName,
        searchResultType: searchEntity
      });
      if (navigationPath) {
        if (searchEntity === SearchEntity.SEARCH_ENTITY_DAPP) {
          router.push(`${RoutesEnum.DAPPS}/${id}/${assetName}`);
        } else {
          router.push(navigationPath);
        }
      }
      toggleSearchOff();
    };
  }, [router, toggleSearchOff]);
  const renderRecentSearchItem = useCallback((searchItem: RecentSearch<SearchEntity>) => {
    const {
      key,
      assetName,
      assetImageSrc,
      searchEntity,
      assetNetworkBadge,
      assetCurrencyCode
    } = searchItem;
    let mediaComponent;
    switch (searchEntity) {
      case SearchEntity.SEARCH_ENTITY_ASSET:
        mediaComponent = <AssetAvatar testID="recent-search-asset-avatar" assetSrc={assetImageSrc} assetCurrencyCode={assetCurrencyCode} networkBadgeSrc={assetNetworkBadge} assetSize="xl" />;
        break;
      case SearchEntity.SEARCH_ENTITY_DAPP:
        mediaComponent = <DAppAvatar testID="recent-search-dapp-avatar" alt="dapp-avatar" size="xl" src={assetImageSrc} />;
        break;
      case SearchEntity.SEARCH_ENTITY_NFT_COLLECTIONS:
        mediaComponent = <DAppAvatar testID="recent-search-nft-avatar" alt="dapp-avatar" size="xl" src={assetImageSrc} />;
        break;
      case SearchEntity.SEARCH_ENTITY_ENS:
        mediaComponent = <ProfileAvatar testID="recent-search-ens-avatar" size="xl" avatar={assetImageSrc} />;
        break;
      default:
        mediaComponent = <Avatar testID="recent-search-generic-avatar" borderColor="primary" alt="generic-avatar" size="l" src={assetImageSrc} shape="square" />;
    }
    return <ListCell key={key} title={assetName} description={SearchEntitySubTitles[searchEntity]} media={mediaComponent} onPress={handlePress(searchItem)} outerSpacing={outerCellSpacing} />;
  }, [handlePress]);
  const recentSearchItems = useMemo(() => recentSearches.map(renderRecentSearchItem), [recentSearches, renderRecentSearchItem]);
  if (!recentSearches.length) {
    return null;
  }
  return <VStack spacingTop={3} height="100%">
      <HStack spacingHorizontal={2} justifyContent="space-between" spacingBottom={2}>
        <TextTitle3 as="p">{formatMessage(messages.title)}</TextTitle3>
        <TextHeadline as="p" testID="recent-seaches-clear-button" onClick={deleteAllRecentSearches} color="primary" className={showPointerOnHover}>
          {formatMessage(messages.clearLabel)}
        </TextHeadline>
      </HStack>
      <VStack overflow="auto" testID="global-search-recent-searches-list">
        {recentSearchItems}
      </VStack>
    </VStack>;
}

require("./RecentSearches.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./RecentSearches.tsx");