import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import {
  createPaginationFunction,
  PaginationQueryParams,
} from 'cb-wallet-data/persistence/pagination';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxOrUserOp, TxOrUserOpDMO } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { filterFulfilled } from 'cb-wallet-data/utils/typeormHelpers';
import { getPlatformName } from 'cb-wallet-metadata/metadata';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import { In, Not, TOO_MANY_PARAMS_QUERY_THRESHOLD } from '@cbhq/typeorm';

import { TxState } from './models/TxState';

export enum TransactionsRepositoryName {
  v2 = 'tx_or_userop_history',
}

export function repository() {
  return tryGetRepository<TxOrUserOpDMO>(TransactionsRepositoryName.v2);
}

type TransactionPaginationOptions = {
  walletIndex: bigint;
  accountId: string;
  offsetKey: string;
  pageSize: number;
  // This is a temporary flag to support the pagination experiment
  skipPagination?: boolean;
};

export async function getTransactionsPage({
  pageSize,
  offset,
  options,
}: PaginationQueryParams<TransactionPaginationOptions>): Promise<TxOrUserOp[]> {
  const platform = getPlatformName();
  // eslint-disable-next-line
  let query: any = {
    order: {
      createdAt: 'DESC',
    },
    where: {
      txHash: Not(''),
    },
  };

  const skipPagination = options.skipPagination || false;

  // sqlite clients can specify a limit and offset
  if (!skipPagination && (platform === 'ios' || platform === 'android')) {
    query = {
      ...query,
      skip: offset,
      where: {
        ...query.where,
        accountId: options.accountId,
        walletIndex: options.walletIndex.toString(),
      },
    };

    if (pageSize !== undefined) {
      query.take = pageSize;
    }
  }

  // If pagination is disabled or we're not on a mobile platform, we'll query the full
  // database
  const transactions = await repository().find(query);
  const results = transactions.map(TxOrUserOp.fromDMO).filter((tx) => !tx.deleted);
  return results;
}

export const transactionsPagination = createPaginationFunction<
  TxOrUserOp,
  TransactionPaginationOptions
>({
  query: getTransactionsPage,
});

/**
 * @deprecated
 */
export async function retrieveTransactions(): Promise<TxOrUserOp[]> {
  const transactions = await repository().find({
    where: {
      txHash: Not(''),
    },
    order: {
      createdAt: 'DESC',
    },
  });

  return transactions.map(TxOrUserOp.fromDMO).filter((tx) => !tx.deleted);
}

export async function getTransactionsTableSize() {
  return repository().count();
}

// TODO this function and type can be removed when we remove address_history_transactions killswitch
type RetrieveTransactionsByHashParams = {
  hash: string;
  blockchainSymbol: AllPossibleBlockchainSymbol;
};

export async function retrieveTransactionsMatchingHash({
  hash,
  blockchainSymbol,
}: RetrieveTransactionsByHashParams): Promise<TxOrUserOp[]> {
  return retrieveTransactionsMatchingHashes({
    hashes: [hash],
    blockchainSymbol,
  });
}

type RetrieveTransactionsMatchingHashesParams = {
  hashes: string[];
  blockchainSymbol: AllPossibleBlockchainSymbol;
};

export async function getTransactionsForWalletIds(walletIds: string[]): Promise<TxOrUserOp[]> {
  const transactions = await repository().find({
    where: {
      walletId: In(walletIds),
    },
  });

  return transactions.map(TxOrUserOp.fromDMO);
}

export async function retrieveTransactionsMatchingHashes({
  hashes,
  blockchainSymbol,
}: RetrieveTransactionsMatchingHashesParams): Promise<TxOrUserOp[]> {
  // Ensure that EVM transactions have the correct hash format
  const formattedHashes =
    blockchainSymbol === 'ETH'
      ? hashes.map((hash) => {
          return hash.startsWith('0x') ? hash : `0x${hash}`;
        })
      : hashes;

  // we split up selects in multiple batches to avoid Sqlite's
  // "too many parameters" problem when passing in multiple hashes
  const batchedQueries: Promise<TxOrUserOpDMO[]>[] = [];
  chunk(formattedHashes, TOO_MANY_PARAMS_QUERY_THRESHOLD).forEach(
    function createTransactionsQueryBatch(predicateChunk) {
      const indices = repository().find({
        where: {
          txHash: In(predicateChunk),
        },
      });

      if (indices) batchedQueries.push(indices);
    },
  );
  const transactions = await Promise.all(batchedQueries);
  return flatten(transactions).map(TxOrUserOp.fromDMO);
}

export async function retrievePendingTransactions() {
  const transactions = await repository().find({
    where: {
      state: TxState.PENDING.valueOf(),
    },
  });

  return transactions.map(TxOrUserOp.fromDMO);
}

export async function retrievePendingTransactionsByChain(blockchain: string) {
  const transactions = await repository().find({
    where: {
      state: TxState.PENDING.valueOf(),
      blockchainStr: blockchain,
    },
  });

  return transactions.map(TxOrUserOp.fromDMO);
}

export async function retrievePendingRelayTransactions({
  type,
  sortDirection,
}: {
  type?: 'sponsored' | 'gasless';
  sortDirection?: 'ASC' | 'DESC';
}): Promise<TxOrUserOp[]> {
  const pendingTransactions = await repository().find({
    where: {
      state: TxState.PENDING.valueOf(),
      deleted: false,
    },
  });

  const filterFunc = (tx: TxOrUserOp) => {
    if (type === 'sponsored') {
      return tx.isSponsored();
    }

    if (type === 'gasless') {
      return tx.isGaslessSwap();
    }
    // if type is not narrowed down, return all known
    // relay transactions
    return tx.isSponsored() || tx.isGaslessSwap();
  };

  const sortFunc = (a: TxOrUserOp, b: TxOrUserOp) => {
    if (sortDirection === 'ASC') {
      return b.createdAt.getTime() - a.createdAt.getTime();
    }
    if (sortDirection === 'DESC') {
      return a.createdAt.getTime() - b.createdAt.getTime();
    }
    // return default order from db call
    return 0;
  };

  return pendingTransactions.map(TxOrUserOp.fromDMO).filter(filterFunc).sort(sortFunc);
}

export async function persistTxOrUserOp(tx: TxOrUserOp): Promise<string | string[]> {
  return persistTxOrUserOps([tx]);
}

export async function persistTxOrUserOps(txs: TxOrUserOp[]): Promise<string | string[]> {
  if (txs.length === 0) {
    return [];
  }

  const promises = await Promise.allSettled(
    repository().batchUpsert(
      txs.map((t) => t.asDMO),
      ['txOrUserOpHash', 'accountId', 'walletIndex'],
    ),
  );

  return filterFulfilled(promises);
}

/**
 * Clear all transactions for a specified repository (v1 or v2), for
 * the purpose of db migration/repair. Do not use for any other reason.
 */
export async function clearTransactionsOfRepository(
  repositoryName: TransactionsRepositoryName,
): Promise<void> {
  const repositoryToUse = () => tryGetRepository(repositoryName);
  return repositoryToUse().clear();
}

export async function getTransactionsForAccount(accountId: string): Promise<TxOrUserOp[]> {
  // TODO: with accountId as column we can just more efficiently query here with an indexed accountId
  const transactions = (await repository().find()).filter(
    (tx) => Wallet.propsFromId(tx.walletId).accountId === accountId,
  );

  return transactions.map(TxOrUserOp.fromDMO);
}

/**
 * Delete transactions in bulk
 *
 * Takes transaction ids as an input parameter and deletes them from the database.
 * repository method is only invoked if the transactionIds array is not empty.
 *
 */
export async function deleteTransactions(transactionIds: string[]): Promise<void> {
  if (transactionIds.length) {
    await repository().delete(transactionIds);
  }
}

export async function getTransactionIdsForBlockchainAndNetwork(
  symbol: AllPossibleBlockchainSymbol,
  network: Network,
): Promise<string[]> {
  const rows = await repository().find({
    where: {
      blockchainStr: symbol,
      networkStr: network.rawValue,
    },
  });

  return rows.reduce(function filterOutDeletedAndMapIds(acc, row) {
    if (row.deleted) {
      return acc;
    }

    acc.push(row.id);
    return acc;
  }, [] as string[]);
}

/**
 * Soft-delete transactions in bulk
 *
 * Takes transactions as input and upserts them after marking them deleted.
 */
export async function softDeleteTransactions(txs: TxOrUserOp[]): Promise<void> {
  const softDeletedTransactions = txs.map(function markTxSoftDeleted(tx) {
    const dmo = tx.asDMO;
    dmo.deleted = true;
    return dmo;
  });

  await Promise.allSettled(repository().batchUpsert(softDeletedTransactions, ['id']));
}
