import { ImageLoaderProps } from 'next/image';

// All
// NextImage Loader expects a default export
// eslint-disable-next-line import/no-default-export
export default function cloudinaryLoader({ src, width, quality }: ImageLoaderProps) {
  if (!src.startsWith('https://')) {
    return src;
  }
  const params = ['f_auto', 'c_limit', `w_${width}`, `q_${quality || 'auto'}`];
  return `https://res.cloudinary.com/coin-nft/image/fetch/${params.join(',')}/${src}`;
}
