import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { isSCWSupportedChainId } from 'cb-wallet-data/scw/util/supportedNetworks';

import { onrampServiceAuthedConfig } from '../config';
import { OnrampNetwork } from '../types/OnrampNetwork';
import { OnrampRequestSharedType } from '../types/OnrampRequest';

import { useOnrampAuthedGet } from './useOnrampAuthedGet';
import { useOnrampGeoLocation } from './useOnrampGeoLocation';

const STALE_TIME_IN_MS = 30 * 1000;

export type OnrampNetworksResponse = {
  networks: OnrampNetwork[];
};

type UseOnrampNetworksProps = {
  endpoint?: 'supportedNetworks' | 'mgxNetworks';
} & OnrampRequestSharedType;

export function useOnrampNetworks({
  fiatCode,
  countryCode,
  subdivisionCode,
  enabled = true,
  endpoint = 'supportedNetworks',
  isConnected,
  includeUTXOs,
  returnScwChainsOnly,
}: UseOnrampNetworksProps) {
  const getSupportedNetworks = useOnrampAuthedGet<OnrampNetworksResponse>(endpoint);

  const { data, isLoading, isInitialLoading } = useQuery({
    queryKey: getQueryKey({
      enabled,
      endpoint,
      fiatCode,
      countryCode,
      isConnected,
      includeUTXOs,
      subdivisionCode,
      returnScwChainsOnly,
    }),
    queryFn: async () =>
      getSupportedNetworks({
        countryCode,
        subdivisionCode,
        fiatCode,
      }),
    enabled,
    staleTime: STALE_TIME_IN_MS,
    notifyOnChangeProps: ['data'],
    placeholderData: { networks: [] },
    select: function selectData(response) {
      return selectOnrampNetworksData({
        response,
        enabled,
        includeUTXOs,
        returnScwChainsOnly,
      });
    },
  });

  return useMemo(
    () => ({ data: data ?? [], isLoading, isInitialLoading }),
    [data, isInitialLoading, isLoading],
  );
}

export function useOnrampMgxNetworks(props: UseOnrampNetworksProps) {
  return useOnrampNetworks({
    ...props,
    endpoint: 'mgxNetworks',
  });
}

export function useQueryOnrampNetworks({
  endpoint = 'supportedNetworks',
}: Pick<UseOnrampNetworksProps, 'endpoint'>) {
  const queryClient = useQueryClient();
  const { countryCode, subdivisionCode } = useOnrampGeoLocation();
  const getSupportedNetworks = useOnrampAuthedGet<OnrampNetworksResponse>(endpoint);

  return useCallback(
    async ({
      fiatCode,
      isConnected,
      includeUTXOs,
      returnScwChainsOnly,
    }: OnrampRequestSharedType) => {
      // always use the authed config when isConnected is explicitly set to true
      // this could happen when isConnected is determined by async action (function call) instead of sync action (hook)
      const options = isConnected
        ? { ...onrampServiceAuthedConfig, withRetailToken: true }
        : undefined;

      const response = await queryClient.fetchQuery({
        queryKey: getQueryKey({
          enabled: true,
          endpoint,
          fiatCode,
          countryCode,
          isConnected,
          includeUTXOs,
          subdivisionCode,
          returnScwChainsOnly,
        }),
        queryFn: async function queryOnrampPaymentMethods() {
          return getSupportedNetworks(
            {
              countryCode,
              subdivisionCode,
              fiatCode,
            },
            options,
          );
        },
        staleTime: STALE_TIME_IN_MS,
      });

      return selectOnrampNetworksData({
        response,
        enabled: true,
        includeUTXOs,
        returnScwChainsOnly,
      });
    },
    [countryCode, endpoint, getSupportedNetworks, queryClient, subdivisionCode],
  );
}

export function getQueryKey({
  enabled,
  fiatCode,
  endpoint,
  countryCode,
  isConnected,
  includeUTXOs,
  subdivisionCode,
  returnScwChainsOnly,
}: Pick<
  UseOnrampNetworksProps,
  | 'enabled'
  | 'endpoint'
  | 'fiatCode'
  | 'countryCode'
  | 'isConnected'
  | 'subdivisionCode'
  | 'includeUTXOs'
  | 'returnScwChainsOnly'
>) {
  return [
    `payment-providers/v1/${endpoint}`,
    enabled,
    fiatCode,
    countryCode,
    isConnected,
    includeUTXOs,
    subdivisionCode,
    returnScwChainsOnly,
  ];
}

export function selectOnrampNetworksData({
  enabled,
  response,
  includeUTXOs,
  returnScwChainsOnly,
}: {
  enabled: boolean;
  includeUTXOs?: boolean;
  returnScwChainsOnly?: boolean;
  response: OnrampNetworksResponse | undefined;
}) {
  if (!enabled || !response?.networks) {
    return [];
  }

  return response.networks.filter(
    (network) =>
      (includeUTXOs || network.chainId !== 0) &&
      (!returnScwChainsOnly || isSCWSupportedChainId(network.chainId)),
  );
}
