/* eslint camelcase: 0 */

import { CB_API_URL } from 'cb-wallet-env/env';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { getClientType } from 'cb-wallet-metadata/metadata';

import {
  DEVICE_SUBJECT_TYPE,
  ExperimentsAPIResponse,
  ExperimentsResponse,
  ReleaseEnvironment,
  WALLET_USER_SUBJECT_TYPE,
} from '../types';

type FetchWalletUserExperimentsArgs = {
  releaseEnvironment: ReleaseEnvironment;
  userId: string | undefined;
  version: string;
  markHasReceivedFirstAuthedExperimentsResult?: () => void;
};

/**
 * Parses the raw API response from the ListSplitTestGroups endpoint and ensures an object is returned with a an array
 * of experiment groups of zero or more length.
 *
 * The response object returned from the API can sometimes omit the `groups` property if it is empty so this function
 * allows our consuming code to avoid needing to constantly check if it exists.
 *
 * @see https://github.cbhq.net/data/experiment-service/blob/70286788a85fb7ed079e7f666c3a255a0b41108c/protos/coinbase/experiment/api.pb.go#L541-L547
 */
export function parseExperimentsAPIResponse(
  response?: ExperimentsAPIResponse,
): ExperimentsResponse {
  return {
    groups: response && Array.isArray(response.groups) ? response.groups : [],
  };
}

export const EXPERIMENTS_API_URL = `${CB_API_URL}/api/v3/coinbase.experiment.ExperimentService/ListSplitTestGroups`;

export async function fetchWalletUserExperiments({
  userId,
  releaseEnvironment,
  version,
  markHasReceivedFirstAuthedExperimentsResult,
}: FetchWalletUserExperimentsArgs): Promise<ExperimentsResponse> {
  // The userId can be undefined, instead making the network call which will fail, we'll return an empty
  // ExperimentsResponse.
  if (!userId) {
    return { groups: [] };
  }

  const params = {
    subject_id: userId,
    client: {
      type: getClientType(),
      version,
    },
    subject_type: WALLET_USER_SUBJECT_TYPE,
    is_employee: releaseEnvironment !== 'production',
  };
  const encoded = Buffer.from(JSON.stringify(params), 'utf-8').toString('base64');

  return getJSON<ExperimentsAPIResponse>(
    EXPERIMENTS_API_URL,
    { q: encoded },
    {
      isThirdParty: true,
      authenticated: false,
    },
  )
    .then(function HandleFetchExperimentResponse(resp) {
      if (userId) {
        markHasReceivedFirstAuthedExperimentsResult?.();
      }

      return parseExperimentsAPIResponse(resp);
    })
    .catch(function HandleFetchExperimentError(err: Error) {
      if (userId) {
        markHasReceivedFirstAuthedExperimentsResult?.();
      }

      throw err;
    });
}

type FetchDeviceExperimentsArgs = {
  releaseEnvironment: ReleaseEnvironment;
  deviceId: string | undefined;
  version: string;
};

export async function fetchDeviceExperiments({
  deviceId,
  releaseEnvironment,
  version,
}: FetchDeviceExperimentsArgs): Promise<ExperimentsResponse> {
  // The deviceId can be undefined, instead making the network call which will fail, we'll return an empty
  // ExperimentsResponse.
  if (!deviceId) {
    return { groups: [] };
  }

  const params = {
    subject_id: deviceId,
    client: {
      type: getClientType(),
      version,
    },
    subject_type: DEVICE_SUBJECT_TYPE,
    is_employee: releaseEnvironment !== 'production',
  };
  const encoded = Buffer.from(JSON.stringify(params), 'utf-8').toString('base64');

  const response = await getJSON<ExperimentsAPIResponse>(
    EXPERIMENTS_API_URL,
    { q: encoded },
    {
      isThirdParty: true,
      authenticated: false,
    },
  );

  return parseExperimentsAPIResponse(response);
}
