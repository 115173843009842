import { JSXElementConstructor, ReactElement } from 'react';
import { renderToString } from 'react-dom/server';

type IFrameType = React.DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>;

type IFramePortalProps = {
  children: ReactElement<any, string | JSXElementConstructor<any>> | null;
  background?: string;
} & Omit<IFrameType, 'ref' | 'src' | 'srcDoc'> & { preview?: boolean };

export function IFramePortal({
  children = null,
  preview = false,
  background,
  ...iframeProps
}: IFramePortalProps) {
  if (children === null) {
    return null;
  }

  const childrenHtml = renderToString(children);

  const mediaHtml = `
    <html>
      <head>
        <style>
          video::-webkit-media-controls-fullscreen-button {
            display: none;
          }
          video::-webkit-media-controls-toggle-closed-captions-button {
            display: none;
          }
          ${
            preview &&
            `
            video::-webkit-media-controls { 
              display: none;
            }
            `
          }
        </style>
      </head>
      <body style=${background ? `"margin:0;background:${background}"` : 'margin:0;'}>
        ${childrenHtml}
      </body>
    </html>
  `;

  // NOTE: It's a requirement from security that we load all NFT media using srcDoc,
  // and that the sandbox attribute must be present.
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe {...iframeProps} srcDoc={mediaHtml} sandbox="" />
  );
}
