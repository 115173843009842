import dynamic from 'next/dynamic';

import { useGlobalDrawer } from ':dapp/components/GlobalDrawer/GlobalDrawerProvider';

import { CashoutFallback } from './CashoutFallback';

const CashoutSidebar = dynamic(
  async () => import('./CashoutSidebar').then((cmp) => cmp.CashoutSidebar),
  {
    loading: CashoutFallback,
    ssr: false,
  },
);

export function CashoutSidebarDrawer() {
  const { closeDrawer } = useGlobalDrawer();
  return <CashoutSidebar onClose={closeDrawer} />;
}
