import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import compact from 'lodash/compact';
import { NavigationIconName } from '@cbhq/cds-web';

import { PRODUCTION_RELEASE } from ':dapp/config/env';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  ocsLabel: {
    defaultMessage: 'Onchain Summer',
    description: 'Tab button label to navigate to the Onchain Summer page',
  },
  assetsLabel: {
    defaultMessage: 'Assets',
    description: 'Tab button label to navigate to the Assets tab',
  },
  homeLabel: {
    defaultMessage: 'Home',
    description: 'Tab button label to navigate to the Home tab',
  },
  messagesLabel: {
    defaultMessage: 'Messages',
    description: 'Tab button label to navigate to the Messages tab',
  },
  questsLabel: {
    defaultMessage: 'Quests',
    description: 'Tab button label to navigate to the Quests tab',
  },
  mintsLabel: {
    defaultMessage: 'Mints',
    description: 'Tab button label to navigate to the Mints tab',
  },
  settingsLabel: {
    defaultMessage: 'Settings',
    description: 'Tab button label to navigate to the Settings tab',
  },
  appsLabel: {
    defaultMessage: 'Apps',
    description: 'Tab button label to navigate to the Apps tab',
  },
  debugLabel: {
    defaultMessage: 'Debug',
    description: 'Tab button label to navigate to the Debug tab',
  },
});

export type SidebarItemsType = {
  title: string;
  route: string;
  icon: NavigationIconName;
  base?: string;
  onPress?: () => void;
};

export function useSideNavbarConfig() {
  const { formatMessage } = useIntl();
  const isDappPageEnabled = useIsFeatureEnabled('dapps_page');
  const isQuestsEnabled = useIsFeatureEnabled('quests');
  const isOcsPagesEnabled = useIsFeatureEnabled('ocs_pages');
  const isHomeEnabled = useIsFeatureEnabled('explore_pages');

  const sidebarItems: SidebarItemsType[] = compact([
    isOcsPagesEnabled
      ? {
          title: formatMessage(messages.ocsLabel),
          icon: 'light',
          route: RoutesEnum.OCS,
          base: RoutesEnum.OCS,
        }
      : null,
    isHomeEnabled
      ? {
          title: formatMessage(messages.homeLabel),
          icon: 'home',
          route: RoutesEnum.HOME_ROOT,
          base: RoutesEnum.HOME_ROOT,
        }
      : null,
    {
      title: formatMessage(messages.assetsLabel),
      icon: 'chartPie',
      route: RoutesEnum.ASSETS,
      base: RoutesEnum.ASSETS,
    },
    isDappPageEnabled
      ? {
          title: formatMessage(messages.appsLabel),
          icon: 'planet',
          route: RoutesEnum.DAPPS,
        }
      : null,
    isQuestsEnabled
      ? {
          title: formatMessage(messages.questsLabel),
          icon: 'earn',
          route: RoutesEnum.QUESTS,
        }
      : null,
    !PRODUCTION_RELEASE
      ? {
          title: formatMessage(messages.debugLabel),
          icon: 'hiddenEye',
          route: RoutesEnum.DEBUG,
        }
      : null,
  ]);

  return sidebarItems;
}
