import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Hide assets',
    description: 'The hide assets setting category title',
  },
  description: {
    defaultMessage: 'View hidden assets',
    description: 'The hide assets setting category description',
  },
  pageHeading: {
    defaultMessage: 'Hidden assets',
    description: 'The hide assets page heading',
  },
});
