import { useMemo } from 'react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { SettingCategoryConfig } from 'cb-wallet-data/stores/SettingsRedesign/types';

import { useHasScwAccounts } from ':dapp/hooks/Scw/useHasScwAccounts';
import { useSettingsPageConfig } from ':dapp/hooks/useSettingsPageConfig';

import { useCoinbaseConnectConfig } from './useCoinbaseConnectConfig';
import { useDisplayCategoryConfig } from './useDisplayCategoryConfig';
import { useHelpCategoryConfig } from './useHelpCategoryConfig';
import { useHideAssetsConfig } from './useHideAssetsConfig';
import { usePrivacyPolicyConfig } from './usePrivacyPolicyConfig';
import { useSmartWalletSettingsConfig } from './useSmartWalletSettingsConfig';
import { useTermsOfServiceConfig } from './useTermsOfServiceConfig';

export function useSettingConfigs(): SettingCategoryConfig[] {
  const hasScwAccounts = useHasScwAccounts();
  const displayConfig = useDisplayCategoryConfig();
  const hideAssetsConfig = useHideAssetsConfig();
  const isHideAssetsEnabled = useIsFeatureEnabled('hide_unhide_assets');
  const helpConfig = useHelpCategoryConfig();
  const termsOfServiceConfig = useTermsOfServiceConfig();
  const privacyPolicyConfig = usePrivacyPolicyConfig();
  const settingsPageConfig = useSettingsPageConfig();
  const smartWalletSettingsConfig = useSmartWalletSettingsConfig();
  const coinbaseConnectConfig = useCoinbaseConnectConfig();
  const isConnectToCoinbaseEnabled = useIsFeatureEnabled('connect_to_coinbase');

  return useMemo(() => {
    const maybeSmartWalletSettingsConfig = hasScwAccounts ? [smartWalletSettingsConfig] : [];
    const maybeCoinbaseConnectConfig = isConnectToCoinbaseEnabled ? [coinbaseConnectConfig] : [];
    const maybeHideAssetsConfig = isHideAssetsEnabled ? [hideAssetsConfig] : [];

    const config = [
      settingsPageConfig,
      ...maybeSmartWalletSettingsConfig,
      displayConfig,
      ...maybeHideAssetsConfig,
      ...maybeCoinbaseConnectConfig,
      helpConfig,
      termsOfServiceConfig,
      privacyPolicyConfig,
    ];

    return config;
  }, [
    hasScwAccounts,
    smartWalletSettingsConfig,
    isConnectToCoinbaseEnabled,
    coinbaseConnectConfig,
    isHideAssetsEnabled,
    hideAssetsConfig,
    settingsPageConfig,
    displayConfig,
    helpConfig,
    termsOfServiceConfig,
    privacyPolicyConfig,
  ]);
}
