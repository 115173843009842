import { useCallback, useMemo } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

export type SessionInfo = {
  user_uuid: string;
  email: string;
};

// eslint-disable-next-line no-process-env
const ENDPOINT = `${process.env.USM_URL}/session`;
export const USM_SESSION_INFO_QUERY_KEY = 'usmSessionInfo';

export function useUSMSessionInfo() {
  const getGetUSMSessionInfo = useGetUSMSessionInfo();
  const { data, refetch, isLoading } = useQuery([USM_SESSION_INFO_QUERY_KEY], async () =>
    getGetUSMSessionInfo(),
  );
  return useMemo(
    () => ({
      sessionInfo: data,
      refetch,
      isLoading,
    }),
    [data, isLoading, refetch],
  );
}

export function useGetUSMSessionInfo() {
  return useCallback(async function getUSMSessionInfo() {
    return getJSON<SessionInfo>(ENDPOINT, {}, { isThirdParty: true, authenticated: false }).catch(
      function catchError(error: ErrorOrAny) {
        if (error?.status === 401) {
          // A 401 is a valid response when the user is not authed with USM
          return undefined;
        }
        throw error;
      },
    );
  }, []);
}
