/* eslint-disable no-process-env */
import { getApplicationMetadata } from 'cb-wallet-metadata/metadata';
import { PlatformName } from '@cbhq/client-analytics';

export function getReleaseStage() {
  const { platform } = getApplicationMetadata();

  if (platform === PlatformName.extension) {
    return process.env.RELEASE_ENVIRONMENT ?? 'local';
  }

  if (platform === PlatformName.web) {
    return process.env.NEXT_PUBLIC_RELEASE_STAGE ?? 'local';
  }

  return process.env.RELEASE_STAGE ?? 'development';
}
