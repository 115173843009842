/* eslint-disable react/no-array-index-key */
import { ListCellFallback } from '@cbhq/cds-web/cells';
import { Box, Fallback, HStack, VStack } from '@cbhq/cds-web/layout';

import { SwapSidebarContentWrapper } from '../Swap/SwapSidebarContentWrapper';

export const NO_OF_FALLBACKS = 5;

export function ReceiveStepFallback() {
  return (
    <SwapSidebarContentWrapper>
      <VStack testID="receive-step-fallback" height={640} width="100%" gap={3} spacing={3}>
        <Box spacingHorizontal={0.5}>
          <Fallback height={40} width={40} shape="squircle" />
        </Box>
        <VStack gap={2}>
          <HStack justifyContent="space-between" spacingHorizontal={0.5}>
            <Fallback height={40} width={120} shape="circle" />
            <Fallback height={40} width={120} shape="circle" />
          </HStack>
          <Box spacingTop={2} spacingHorizontal={0.5}>
            <Fallback height={40} width={100} percentage shape="circle" />
          </Box>
          <VStack>
            {[...Array(NO_OF_FALLBACKS)].map((_, index) => (
              <Box offsetStart={1.5} key={index}>
                <ListCellFallback title description detail subdetail media="avatar" />
              </Box>
            ))}
          </VStack>
        </VStack>
      </VStack>
    </SwapSidebarContentWrapper>
  );
}
