import { Persistable } from 'cb-wallet-data/persistence/Database.interface';
import { Column, Entity, PrimaryColumn } from '@cbhq/typeorm';

type SecretsId = 'secrets-id';
export const SECRETS_ID: SecretsId = 'secrets-id';

// Naming this plural because this is a single string of encrypted secrets.
// Storing all the secrets into a single string to preserve atomicity without
// using database transactions.
@Entity('secrets')
export class SecretsDMO {
  // should always be SECRETS_ID
  @PrimaryColumn('varchar')
  id!: string;

  @Column('datetime')
  createdAt!: Date;

  // Blob string of encrypted secrets with this model
  // { [ACCOUNT_ID]: {mnemonic: ENCRYPTED_MNEMONIC, salt: SALT, passwordIv: PASSWORD_IV}> }
  @Column('varchar')
  value!: string;
}

export type SecretsConstructorOptions = {
  id?: undefined;
  createdAt?: undefined;
  value: string;
};

/**
 * Secrets
 */
export class Secrets implements Persistable<SecretsDMO> {
  readonly id: SecretsId;
  readonly createdAt: Date;
  readonly value: string;

  get asDMO(): SecretsDMO {
    return {
      id: this.id,
      createdAt: this.createdAt,
      value: this.value,
    };
  }

  static fromDMO(options: SecretsDMO): Secrets {
    return new Secrets(options);
  }

  constructor({ value, createdAt }: SecretsConstructorOptions | SecretsDMO) {
    // TODO WALL-40807 [DL] Validate Platform in Secrets constructor
    this.id = SECRETS_ID; // there will always be one and only record
    this.createdAt = createdAt ?? new Date();
    this.value = value;
  }
}
