import { AmountInputLoadingFallback } from 'wallet-cds-web/components/AmountInput/AmountInputLoadingFallback';
import { AssetSelectorGroupLoadingFallback } from 'wallet-cds-web/components/AssetSelectorGroup/AssetSelectorGroupLoadingFallback';
import { Divider, VStack } from '@cbhq/cds-web/layout';

export function CashoutFallback() {
  return (
    <VStack width="100%" justifyContent="flex-start">
      <VStack
        testID="cashout-fallback"
        height={525}
        width="100%"
        gap={2}
        spacing={3}
        spacingTop={5}
        justifyContent="space-between"
      >
        <VStack gap={2} spacingTop={5}>
          <AmountInputLoadingFallback />
          <AssetSelectorGroupLoadingFallback />
        </VStack>
      </VStack>
      <Divider width="100%" />
    </VStack>
  );
}
