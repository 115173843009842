import { useCallback } from 'react';
import { triggerDappProviderUserIdBackfill } from 'cb-wallet-analytics/accounts';
import { PossibleAccountBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { observabilityClient } from 'cb-wallet-data/stores/Observability/classes/ObservabilityClient';
import { useCreateWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useCreateWalletGroups';

import { Account } from '../models/Account';
import { AccountType } from '../models/AccountTypes';
import {
  ProcessWalletConnectionResponseError,
  ProcessWalletConnectionResponseErrorType,
} from '../types';

import { useAccounts } from './useAccounts';
import { useGetAccountById } from './useGetAccountById';
import { useSaveAndSetAccount } from './useSaveAndSetAccount';

type CreateAccountAndWalletGroupForAddressParams = {
  provider: string;
  chain: PossibleAccountBlockchainSymbol;
  address: string;
  providerUserId?: string;
};

export function useCreateAccountAndWalletGroupForDapp() {
  const saveAndSetAccount = useSaveAndSetAccount();
  const createWalletGroups = useCreateWalletGroups();
  const getAccountById = useGetAccountById();
  const accountsLength = useAccounts().length;

  return useCallback(
    async function createAccountForAddress({
      provider,
      chain,
      address,
      providerUserId,
    }: CreateAccountAndWalletGroupForAddressParams) {
      try {
        const account = new Account({
          type: AccountType.DAPP_PROVIDER,
          primaryAddress: address.toLowerCase(), // Some wallets return checksummed addresses while others don't
          primaryAddressChain: chain,
          provider,
          dappProviderUserId: providerUserId,
        });

        const isCoinbaseWallet =
          provider === 'com.coinbase.keys' || provider === 'com.coinbase.wallet';
        const shouldSetUserId = !!providerUserId && isCoinbaseWallet;

        const possibleDuplicateAccount = getAccountById(account.id);
        if (possibleDuplicateAccount) {
          // The account already exists, we can skip
          if (possibleDuplicateAccount.provider === provider) {
            // If the existing account does not have a dappProviderUserId, attempt to backfill here
            if (!possibleDuplicateAccount.dappProviderUserId && !!providerUserId) {
              triggerDappProviderUserIdBackfill({ userId: providerUserId });
              const possibleDuplicateAccountWithUserId = new Account({
                ...possibleDuplicateAccount,
                dappProviderUserId: providerUserId,
              });

              await saveAndSetAccount(possibleDuplicateAccountWithUserId);

              // Setting the Amplitude userId only if the duplicate was the first imported account
              if (shouldSetUserId && accountsLength === 1) {
                observabilityClient.setUserId({ userId: providerUserId });
              }

              return { account: possibleDuplicateAccountWithUserId, duplicate: true };
            }

            return { account: possibleDuplicateAccount, duplicate: true };
          }

          // If the user is trying to import an account that already exists, but with a different provider, we throw an error

          throw new ProcessWalletConnectionResponseError(
            ProcessWalletConnectionResponseErrorType.ADDRESS_ALREADY_IMPORTED,
            'Account already exists, cannot import duplicate addresses',
          );
        }

        await saveAndSetAccount(account);

        await createWalletGroups([
          {
            accountId: account.id,
            walletIndex: 0n,
          },
        ]);

        // Amplitude userId should be set to the first imported account
        if (shouldSetUserId && accountsLength === 0) {
          observabilityClient.setUserId({ userId: providerUserId });
        }

        return { account };
      } catch (e) {
        if (e instanceof ProcessWalletConnectionResponseError) {
          throw e;
        }
        cbReportError({
          error: e as Error,
          context: 'dapp-account-creation',
          severity: 'error',
          isHandled: false,
          metadata: {
            provider,
            chain,
          },
        });
      }
    },
    [accountsLength, createWalletGroups, getAccountById, saveAndSetAccount],
  );
}
