import {
  triggerSyncTransactionsFail,
  triggerSyncTransactionsStart,
  triggerSyncTransactionsSuccess,
} from 'cb-wallet-analytics/data/Transactions';
import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { saveLastSyncedTxHashOnTxUpdate } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import {
  chainComponentsFromNetworkRawValue,
  Network,
} from 'cb-wallet-data/stores/Networks/models/Network';
import { deduplicateAndPersist } from 'cb-wallet-data/stores/Transactions/methods/deduplicateAndPersist';
import { reportTxsConfirmed } from 'cb-wallet-data/stores/Transactions/methods/reportTransactionsConfirmed';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { processPendingRelayedTransactions } from 'cb-wallet-data/stores/Transactions/sponsored/processPendingRelayedTransactions';
import { getErrorMessage } from 'cb-wallet-data/utils/getErrorMessage';
import { PERF_MARKS, TX_HISTORY_MARKS } from 'cb-wallet-data/utils/perf-constants';
import { endPerfMark, startPerfMark } from 'cb-wallet-data/utils/perfMark';
import { StoreKey } from 'cb-wallet-store/models/StoreKey';
import { Store } from 'cb-wallet-store/Store';

const fullSyncPageSize = 50n;
const updatePageSize = 10n;

type SyncTxHistoryParams = {
  updatePaginatedTransactions?: (updatedTransactions: TxOrUserOp[]) => void;
};

/**
 * @deprecated
 * Transaction syncing is being moved to address history listener.
 */
export abstract class TxHistorySyncing {
  constructor(protected currencyCode: CurrencyCode, protected network: Network) {}

  abstract isSyncingTxsKey: StoreKey<boolean>;
  abstract hasSyncedTxsKey: StoreKey<boolean>;

  abstract getTransactions(): Promise<TxOrUserOp[]>;

  protected transactionHistoryVersion = 'v1';
  protected maximumPages = 20n;

  get isSyncingTxs(): boolean {
    return Store.get(this.isSyncingTxsKey) === true;
  }

  set isSyncingTxs(newValue: boolean) {
    Store.set(this.isSyncingTxsKey, newValue);
  }

  get hasSyncedTxs(): boolean {
    return Store.get(this.hasSyncedTxsKey) === true;
  }

  set hasSyncedTxs(newValue: boolean) {
    Store.set(this.hasSyncedTxsKey, newValue);
  }

  get perPage(): bigint {
    return this.hasSyncedTxs ? updatePageSize : fullSyncPageSize;
  }

  async syncTxHistory({ updatePaginatedTransactions }: SyncTxHistoryParams): Promise<void> {
    if (this.isSyncingTxs) return Promise.resolve();

    this.isSyncingTxs = true;

    const syncingTestnetOrCustomNetwork =
      this.network.isTestnet || Boolean(this.network.asChain()?.isCustomNetwork);
    const chainName = this.network.asChain()?.displayName ?? '';
    const perfMarkName = `${PERF_MARKS.tx_history}_${this.transactionHistoryVersion}_${
      this.hasSyncedTxs ? TX_HISTORY_MARKS.update_sync : TX_HISTORY_MARKS.full_sync
    }`;

    const perfMarkOptions = {
      label: chainName.replaceAll(' ', '_').toLowerCase(),
    };

    const eventConfig = {
      transactionHistoryVersion: this.transactionHistoryVersion,
      chainName,
      isFullSync: String(!this.hasSyncedTxs),
    };

    triggerSyncTransactionsStart(eventConfig);

    if (!syncingTestnetOrCustomNetwork) {
      startPerfMark(perfMarkName, perfMarkOptions);
    }
    try {
      // get all transactions from the network
      const transactions = await this.getTransactions();

      if (transactions.length) {
        const updatedTransactions = await deduplicateAndPersist(
          transactions,
          this.currencyCode.rawValue as AllPossibleBlockchainSymbol,
        );

        if (updatePaginatedTransactions && updatedTransactions.length) {
          updatePaginatedTransactions(updatedTransactions);
        }

        await reportTxsConfirmed(
          transactions,
          this.currencyCode.rawValue as AllPossibleBlockchainSymbol,
        );

        saveLastSyncedTxHashOnTxUpdate(updatedTransactions);
      }

      this.hasSyncedTxs = true;
      this.isSyncingTxs = false;

      const chain = this.network.asChain();
      const { chainPrefix } = chainComponentsFromNetworkRawValue(this.network.rawValue);

      // Sponsored/Relayed Transactions only available on WaaS supported Ethereum networks
      if (chainPrefix === ETHEREUM_PREFIX && chain?.wacNetworkId) {
        await processPendingRelayedTransactions();
      }

      triggerSyncTransactionsSuccess(eventConfig);
    } catch (error) {
      this.isSyncingTxs = false;
      const err = coerceError(error, 'TxHistorySyncing');
      cbReportError({ context: 'transactions', error: err, severity: 'error', isHandled: false });
      triggerSyncTransactionsFail({ ...eventConfig, errorMessage: getErrorMessage(error) });
    }

    if (!syncingTestnetOrCustomNetwork) {
      endPerfMark(perfMarkName, perfMarkOptions);
    }
  }
}
