import { useCallback } from 'react';

import { CashoutSidebarDrawer } from ':dapp/components/Cashout/CashoutSidebarDrawer';
import { DrawerName } from ':dapp/components/GlobalDrawer/constants';
import { useGlobalDrawerOrNavigate } from ':dapp/components/GlobalDrawer/hooks/useGlobalDrawerOrNavigate';
import { SendAndReceiveDrawer } from ':dapp/components/Send/SendAndReceiveDrawer';
import { SwapSidebarDrawer } from ':dapp/components/Swap/SwapSidebar/SwapSidebarDrawer';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

type UseHandleGabItemPressProps = {
  closeModal: () => void;
};

export function useHandleGabItemPress({ closeModal }: UseHandleGabItemPressProps) {
  const { openDrawerOrNavigate } = useGlobalDrawerOrNavigate();
  return useCallback(
    (drawer: DrawerName) => () => {
      switch (drawer) {
        case DrawerName.Send:
          openDrawerOrNavigate(<SendAndReceiveDrawer />, RoutesEnum.SEND, {
            step: 'send',
          });
          break;
        case DrawerName.Receive:
          openDrawerOrNavigate(<SendAndReceiveDrawer />, RoutesEnum.RECEIVE, {
            step: 'receive',
          });
          break;
        case DrawerName.Swap:
          openDrawerOrNavigate(<SwapSidebarDrawer />, RoutesEnum.SWAP);
          break;
        case DrawerName.Cashout:
          openDrawerOrNavigate(<CashoutSidebarDrawer />, RoutesEnum.CASHOUT);
          break;
        default:
          break;
      }
      closeModal();
    },
    [openDrawerOrNavigate, closeModal],
  );
}
