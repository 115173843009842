import { useEffect, useRef } from 'react';
import { SprigEventTrack } from 'cb-wallet-data/hooks/useSyncIsZeroBalanceWallet';
import { useUserFacingAccountsSortedByCreatedAt } from 'cb-wallet-data/stores/Accounts/hooks/useUserFacingAccountsSortedByCreatedAt';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { newTimer } from 'cb-wallet-data/utils/globalTimer';

import { useAccountBasedAddressHistory } from './useAccountBasedAddressHistory';
import { useBalanceScanningForAccount } from './useBalanceScanningForAccount';
import { useUTXOAddressHistory } from './useUTXOAddressHistory';

type UseAddressHistoryProps = {
  sprigEventTrack?: SprigEventTrack;
  activeWalletGroup?: WalletGroup; // Will prioritize registering associated addresses when present
  isFirstSession?: boolean;
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
  excludeDefiAssets?: boolean;
};

/**
 * Creates address history event listeners, for balances and transactions updates.
 */
export function useAddressHistory({
  sprigEventTrack,
  activeWalletGroup,
  isFirstSession = false,
  skipBalanceRefresh = false,
  skipTransactionRefresh = false,
  excludeDefiAssets = false,
}: UseAddressHistoryProps) {
  // useUserFacingAccountsSortedByCreatedAt provides display order context for analytics reporting.
  // Omitted rollup accounts aren't needed for balance scanning or UTXO derivation.
  const userFacingAccountsSortedByCreatedAt = useUserFacingAccountsSortedByCreatedAt();
  const walletGroups = useWalletGroups();

  const createUTXOAddressHistory = useUTXOAddressHistory({
    skipBalanceRefresh,
    skipTransactionRefresh,
  });

  const createAccountBasedAddressHistory = useAccountBasedAddressHistory({
    sprigEventTrack,
    activeWalletGroup,
    skipBalanceRefresh,
    skipTransactionRefresh,
    excludeDefiAssets,
  });

  // Ensures each operation only executes once on app load
  // Not using useOnMount, so each useEffect can wait until any required state is hydrated.
  const didBalanceScan = useRef(false);
  const didCreateUTXOEventListeners = useRef(false);
  const didCreateAccountBasedEventListeners = useRef(false);

  // Will check local storage, to see if scanning the current account is needed.
  const maybeBalanceScanForAccount = useBalanceScanningForAccount();

  // Starts a timer that tracks how long balances/transactions import take
  useEffect(
    function startPerformanceTimers() {
      if (isFirstSession) {
        newTimer('addressHistory.ImportStart');
      }
    },
    [isFirstSession],
  );

  /**
   * Scans unused indexes for each account and fills in missing wallet groups.
   * @see {useBalanceScanningForAccount}
   */
  useEffect(
    function maybeBalanceScanAndCreateWalletGroups() {
      if (didBalanceScan.current) return;

      const isRequiredStateHydrated = Boolean(userFacingAccountsSortedByCreatedAt.length);
      if (!isRequiredStateHydrated) return;

      userFacingAccountsSortedByCreatedAt.forEach((account: Account, index: number) => {
        if (account.supportsMultiWallet) {
          maybeBalanceScanForAccount(account.id, index);
        }
      });

      didBalanceScan.current = true;
    },
    [maybeBalanceScanForAccount, userFacingAccountsSortedByCreatedAt],
  );

  /**
   * UTXO: Once accounts have been hydrated into recoil state, create the UTXO
   * address history listeners once.
   */
  useEffect(
    function maybeCreateUTXOHistoryListeners() {
      if (didCreateUTXOEventListeners.current) return;

      const isRequiredStateHydrated = Boolean(userFacingAccountsSortedByCreatedAt.length);
      if (!isRequiredStateHydrated) return;

      createUTXOAddressHistory();
      didCreateUTXOEventListeners.current = true;
    },
    [
      createUTXOAddressHistory,
      userFacingAccountsSortedByCreatedAt,
      skipBalanceRefresh,
      skipTransactionRefresh,
    ],
  );

  /**
   * ETH + SOL: Once wallet groups have been hydrated into recoil state, create the
   * account-based address history listener once.
   */
  useEffect(
    function maybeCreateAccountBasedHistoryListeners() {
      if (didCreateAccountBasedEventListeners.current) return;

      const isRequiredStateHydrated = Boolean(walletGroups.length);
      if (!isRequiredStateHydrated) return;

      createAccountBasedAddressHistory();
      didCreateAccountBasedEventListeners.current = true;
    },
    [createAccountBasedAddressHistory, walletGroups, skipBalanceRefresh, skipTransactionRefresh],
  );
}
