/* eslint-disable @typescript-eslint/no-explicit-any */
import { setErrorHandler } from 'cb-wallet-data/errors/reportError';
import { callDefaultPersistQueryClient } from 'cb-wallet-data/QueryProvider';
import { signingAndDerivationMethods } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/signingAndDerivation';
import { signingAndDerivationMethodsForDappProviderAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/signingAndDerivationForAccount';
import { throwUnimplementedError } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/throwUnimplementedError';
import { throwUnimplementedErrorWithAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/throwUnimplementedErrorWithAccount';
import { AccountType } from 'cb-wallet-data/stores/Accounts/models/AccountTypes';
import { setCheckForAllowedUsageOfActiveWalletGroupId } from 'cb-wallet-data/stores/WalletGroups/hooks/useWarnDevOfActiveWalletGroupIdUsage';
import { isE2eTest } from 'cb-wallet-env/env';
import { setApplicationMetadata } from 'cb-wallet-metadata/metadata';
import getConfig from 'next/config';
import {
  deriveLegacyAddressFromXpubKey,
  deriveSegwitAddressFromXpubKey,
} from 'wallet-engine-signing';
import { PlatformName } from '@cbhq/client-analytics';

import { IS_DEV } from ':dapp/config/env';
import { signAndSubmitETH1559Transaction } from ':dapp/datalayer/web3/signingMethods/signAndSubmitETH1559Transaction';
import { signAndSubmitETH2930Transaction } from ':dapp/datalayer/web3/signingMethods/signAndSubmitETH2930Transaction';
import { signAndSubmitETHLegacyTransaction } from ':dapp/datalayer/web3/signingMethods/signAndSubmitETHLegacyTransaction';
import { signAndSubmitSolanaTransaction } from ':dapp/datalayer/web3/signingMethods/signAndSubmitSolanaTransaction';
import { signAndSubmitSolanaVersionedTransaction } from ':dapp/datalayer/web3/signingMethods/signAndSubmitSolanaVersionedTransaction';
import { signEthereumMessage } from ':dapp/datalayer/web3/signingMethods/signEthereumMessage';
import { checkForAllowedUsageOfActiveWalletGroupId } from ':dapp/utils/checkForAllowedUsageOfActiveWalletGroupId';

import { bugsnagErrorReporter } from './bugsnag';

// 1. It provides a function to be called to report errors
if (!IS_DEV && !isE2eTest) {
  setErrorHandler(bugsnagErrorReporter);
}

setApplicationMetadata({
  version: getConfig().publicRuntimeConfig.version,
  platform: PlatformName.web,
});

// requestIdleCallback polyfill for Safari
// https://caniuse.com/requestidlecallback
if (typeof window !== 'undefined') {
  (window as any).requestIdleCallback =
    (window as any).requestIdleCallback ||
    function timeout(cb: (deadLine: IdleDeadline) => void) {
      const start = Date.now();

      return setTimeout(function callback() {
        cb({
          didTimeout: false,
          timeRemaining: function timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1);
    };

  (window as any).cancelIdleCallback =
    (window as any).cancelIdleCallback ||
    function clearTimeout(id: number) {
      clearTimeout(id);
    };
}

signingAndDerivationMethods.setAll({
  deriveEthereumPublicKeyFromSeed: throwUnimplementedError('deriveEthereumPublicKeyFromSeed'),
  deriveEthereumAddressFromPublicKey: throwUnimplementedError('deriveEthereumAddressFromPublicKey'),
  signEthereumMessageFromSeed: throwUnimplementedError('signEthereumMessageFromSeed'),
  deriveLegacyAddressFromXpubKey,
  deriveSegwitAddressFromXpubKey,
  signLedger1559Transaction: throwUnimplementedError('signLedger1559Transaction'),
  signLedgerTransaction: throwUnimplementedError('signLedgerTransaction'),
});

signingAndDerivationMethodsForDappProviderAccount.setAll({
  signAndSubmitETH1559Transaction,
  signAndSubmitETH2930Transaction,
  signAndSubmitETHLegacyTransaction,
  signEthereumMessage,
  // NOTE: we probably want to implement this method eventually.
  // The reason is that scw signing takes as input chain id,
  // any usage of signEthereumMessage could result in failures if
  // scw popup signs on the wrong chain.  Instead, we should switch
  // chain before calling to the scw popup.
  signEthereumMessageScw: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signEthereumMessageScw',
  ),
  signAndSubmitSolanaTransaction,
  signAndSubmitSolanaVersionedTransaction,
  signAndSubmitETHUserOp: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signAndSubmitETHUserOp',
  ),
  partialSignAndSubmitSolanaTransaction: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'partialSignAndSubmitSolanaTransaction',
  ),
  secp256k1PublicKey: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'secp256k1PublicKey',
  ),
  ed25519PublicKey: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'ed25519PublicKey',
  ),
  publicKeySolana: throwUnimplementedErrorWithAccount(AccountType.DAPP_PROVIDER, 'publicKeySolana'),
  xpubKey: throwUnimplementedErrorWithAccount(AccountType.DAPP_PROVIDER, 'xpubKey'),
  signETH712Message: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signETH712Message',
  ),
  signETH712TransferAuthorization: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signETH712TransferAuthorization',
  ),
  signSolanaMessage: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signSolanaMessage',
  ),
  signAndSubmitUTXOTransaction: throwUnimplementedErrorWithAccount(
    AccountType.DAPP_PROVIDER,
    'signAndSubmitUTXOTransaction',
  ),
});

setCheckForAllowedUsageOfActiveWalletGroupId(checkForAllowedUsageOfActiveWalletGroupId);

callDefaultPersistQueryClient();
