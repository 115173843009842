import { logFilteredPoisonousTransaction } from 'cb-wallet-analytics/transactions/Transactions';
import { CurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';
import Decimal from 'decimal.js';

/**
 * Filters out harmful transactions from an array of paginated transactions.
 * This is to prevent phishing attacks where an attacker creates a transaction
 * with a recipient or sender address that closely resembles a previous legitimate
 * recipient or sender address.
 *
 * The attacks works as follows:
 *
 * 1. The user sends crypto to / (receives crypto from) a legitimate address, e.g., 0xabc...123.
 * 2. The attacker creates a transaction which emits a log, placing a transaction
 *    in the user's history indicating that the user sent/received some crypto to/from a similar,
 *    but different address, say 0xabc...123', where the middle part is different.
 * 3. The user sees the second transaction in their history, copies the address,
 *    and sends funds there, not realizing that it's not the same as the original
 *    address.
 */
export function filterPoisonousTransactions(
  transactions: TxOrUserOp[],
  currencyFormatter: CurrencyFormatter,
): TxOrUserOp[] {
  const truncatedAddressToInfo = new Map<
    string,
    { fullAddress: string; transaction: TxOrUserOp }
  >();
  let safeTransactions: TxOrUserOp[] = [];
  const MINIMUM_AMOUNT_THRESHOLD = new Decimal(0.1);

  // Process transactions from oldest to newest for efficiency. Though we are not sure with paginated
  // transactions, older transaction being the safe one is the more probable scenario.
  for (let i = transactions.length - 1; i >= 0; i--) {
    const transaction = transactions[i];
    const targetAddress = getTargetAddress(transaction);
    const targetTruncatedAddress = getTruncatedAddress(targetAddress);

    if (!targetTruncatedAddress) {
      // not a send/receive transaction, considered safe
      safeTransactions.push(transaction);
      continue;
    }

    const existingInfo = truncatedAddressToInfo.get(targetTruncatedAddress);

    if (!existingInfo) {
      // First occurrence of this truncated address, considered safe. Store the transaction info.
      truncatedAddressToInfo.set(targetTruncatedAddress, {
        fullAddress: targetAddress,
        transaction,
      });
      safeTransactions.push(transaction);
      continue;
    }

    if (existingInfo.fullAddress === targetAddress) {
      // Same full address as before, it's safe
      safeTransactions.push(transaction);
      continue;
    }

    // Full address doesn't match, compare the fiat values
    const newTxFiatValue = getTxFiatAmount(transaction, currencyFormatter);
    const existingTxFiatValue = getTxFiatAmount(existingInfo.transaction, currencyFormatter);

    if (
      newTxFiatValue.greaterThan(existingTxFiatValue) &&
      newTxFiatValue.greaterThan(MINIMUM_AMOUNT_THRESHOLD)
    ) {
      // Remove the previous transactions with existing full address, add the new one and update the map
      safeTransactions = removeAndLogUnsafeTransactions(safeTransactions, existingInfo.fullAddress);

      truncatedAddressToInfo.set(targetTruncatedAddress, {
        fullAddress: targetAddress,
        transaction,
      });
      safeTransactions.push(transaction);
    } else {
      logFilteredPoisonousTransaction({
        txHash: transaction.txHash,
        network: transaction.network.rawValue,
      });
    }
  }

  // Reverse the array to maintain the original order
  // push + reverse is more efficient than unshift
  safeTransactions.reverse();

  return safeTransactions;
}

function getTargetAddress(transaction: TxOrUserOp): string {
  return (
    (transaction.isSent ? transaction.toAddress : transaction.fromAddress)?.toLowerCase() ?? ''
  );
}

function removeAndLogUnsafeTransactions(
  safeTransactions: TxOrUserOp[],
  addressToRemove: string,
): TxOrUserOp[] {
  return safeTransactions.filter(function removeAndLogUnsafeTxns(tx) {
    const safeTx = getTargetAddress(tx) !== addressToRemove;
    if (!safeTx) {
      logFilteredPoisonousTransaction({
        txHash: tx.txHash,
        network: tx.network.rawValue,
      });
    }
    return safeTx;
  });
}

export function getTxFiatAmount(
  transaction: TxOrUserOp,
  currencyFormatter: CurrencyFormatter,
): Decimal {
  return (
    currencyFormatter.fiatValue({
      currencyCode: transaction.currencyCode,
      decimals: transaction.tokenDecimal,
      value: transaction.amount,
      contractAddress: transaction.contractAddress,
      network: transaction.network,
      blockchain: transaction.blockchain,
      digitsToFixed: 2,
    }) ?? new Decimal(0)
  );
}
