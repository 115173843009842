import { BaseWalletAdapter as SolanaWalletConnector } from '@solana/wallet-adapter-base';
import { Connector as EthereumWalletConnector } from '@wagmi/core';
import {
  PossibleAccountBlockchainSymbol,
  PossibleUTXOBlockchainSymbol,
} from 'cb-wallet-data/chains/blockchains';
import { ScwOnboardMode } from 'cb-wallet-data/scw/types';
import { WalletConnectionResponse } from 'cb-wallet-data/stores/Accounts/models/WalletConnectionResponse';

import { CoinbaseWalletConnectorManager } from './CoinbaseWalletConnectorManager';
import { WalletConnectorManager } from './WalletConnectorManager';

type CoinbaseWalletDappProvider = {
  connect: () => Promise<WalletConnectionResponse>;
  disconnect: () => Promise<void>;
  isConnected: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    coinbaseWallet: {
      dappProvider: CoinbaseWalletDappProvider;
    };
  }
}

export enum WalletProviderNetwork {
  Ethereum = 'ethereum',
  Solana = 'solana',
  Bitcoin = 'bitcoin',
  Litecoin = 'litecoin',
  Dogecoin = 'dogecoin',
}

export type PossibleNetworkWalletConnector = EthereumWalletConnector | SolanaWalletConnector;

export type WalletProviderNetworkInfo = {
  id: WalletProviderNetwork;
  name: string;
  enabled: boolean;
  image: string;
  symbol: PossibleAccountBlockchainSymbol | PossibleUTXOBlockchainSymbol;
  connector: PossibleNetworkWalletConnector | null;
};

export type WalletProviderInfo = {
  id: string;
  networks: WalletProviderNetworkInfo[];
  image: string;
  name: string;
  enabled?: boolean;
  recommended?: boolean;
  connectorManager: WalletConnectorManager | CoinbaseWalletConnectorManager;
};

export enum WalletConnectionErrorType {
  UNKNOWN = 'unknown',
  CONNECTOR_ALREADY_CONNECTED = 'connector-already-connected',
  CONNECTOR_INVALID = 'connector-invalid',
  CONNECTOR_NOT_READY = 'connector-not-ready',
  USER_REJECTED = 'user-rejected',
  WALLETCONNECT_CONNECTION_RESET = 'walletconnect-connection-reset',
  CONNECTION_FAILED = 'connection-failed',
  MALFORMED_RESPONSE = 'malformed-response',
  USER_LOGGED_OUT = 'user-logged-out',
}

export type WalletConnectorConnectResponse = {
  error?: WalletConnectionErrorType;
  result?: null | WalletConnectionResponse;
};

export type ConnectWalletOptions = {
  scwOnboardMode?: ScwOnboardMode;
};
