import { ActionType, ComponentType, logMetric, MetricType } from '@cbhq/client-analytics';

import {
  logDataEvent,
  logEvent,
  LogEventDataLayerParams,
  useEventLogger,
  useLogEventOnMount,
} from '../utils/log';
import { EventProperties } from '../utils/types/eventProperties';

export function useAssetsViewed({
  userId,
  accountType,
}: Omit<LogEventDataLayerParams, 'loggingId'>) {
  return useLogEventOnMount('assets.viewed', {
    loggingId: '551c26c2-3508-4228-b65b-0b5fdfef70b2',
    componentType: ComponentType.page,
    userId,
    accountType,
  });
}

// Used in dapp asset tabs

/**
 * Default actions used to log sidebar events in preview pages
 */
export function logAssetsSidebarClick(type: 'view_page' | 'close') {
  return logEvent(`sidebar.${type}.clicked`, {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetTabClicked(tab: string) {
  return logEvent(`assets.${tab}_tab.clicked`, {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsViewed() {
  return logEvent('assets.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logAssetRowClicked(data?: {
  walletId: string;
  asset: string;
  contractAddress?: string;
  network: string;
}) {
  return logEvent('assets.crypto_row.clicked', {
    action: ActionType.click,
    componentType: ComponentType.table,
    ...data,
  });
}

export function logAssetsSidebarViewed() {
  return logEvent('assets.crypto_sidebar.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logTestnetsViewed() {
  return logEvent('assets_testnets.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCollectionClicked() {
  return useEventLogger('assets.nft_collection.clicked', {
    loggingId: 'cac3b971-b123-4bd4-979c-1b22777aa613',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

// drawer opened
export function useCollectionClickedV2() {
  return useEventLogger('assets.nft_collection.clicked_v2', {
    loggingId: 'f7cd682a-9551-491d-a5de-181a44b29a9e',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useNftNuxViewed() {
  useLogEventOnMount('assets.nft_nux.viewed', {
    loggingId: 'b9a13b63-d4fe-400d-b7f4-96b3aaf1f220',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function logCollectionAddCryptoClicked() {
  return logEvent('assets.nft_collection.add_crypto.clicked', {
    loggingId: 'ac4e9682-b519-4910-af61-b85b661ee8d7',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useLogNftNuxViewed() {
  return useEventLogger('assets.nft_nux.viewed', {
    loggingId: 'b9a13b63-d4fe-400d-b7f4-96b3aaf1f220',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogNftNuxV2Viewed() {
  return useEventLogger('assets.nft_nux_v2.viewed', {
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogNftNetworkNuxViewed() {
  useLogEventOnMount('assets.nft_network_nux.viewed', {
    loggingId: '3a2f6f4a-0ea9-4612-8dd1-68d7227d18ad',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogNftTabViewed() {
  return useEventLogger('assets.nft_tab.viewed', {
    loggingId: 'cf475e6e-3a31-4a33-9e9a-42ebd898516b',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useNftTabViewedV2() {
  useLogEventOnMount('assets.nft_tab.viewed_v2', {
    loggingId: '4cd3bf40-d9f5-438d-b69d-56edb29714ca',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useNftGuideClicked() {
  return useEventLogger('assets.nft_guide.clicked', {
    loggingId: '037b0cb4-83b8-11ec-a8a3-0242ac120002',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logFindMoreNftClicked() {
  logEvent('assets.new_nft_nux.find_more.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useNftListViewed() {
  useLogEventOnMount('assets.nft_list.viewed', {
    loggingId: '119314b6-7f50-4754-b36d-177f3b1760c9',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogNftListViewed() {
  return useEventLogger('assets.nft_list.viewed', {
    loggingId: '119314b6-7f50-4754-b36d-177f3b1760c9',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogNftListViewedV2() {
  return useEventLogger('assets.nft_list.viewed_v2', {
    loggingId: '7ebd3473-d02b-4eff-90cd-b3478859f39c',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useNftTabClicked() {
  return useEventLogger('assets.nft_tab.clicked', {
    loggingId: '48222321-b06d-4170-a86f-e71fb8b964f1',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useLogDeFiListViewed() {
  return useEventLogger('assets.defi_list.viewed', {
    loggingId: 'd14d51ce-511a-4f6a-8537-70748a38f429',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

type LogFilterProps = {
  type: 'viewSetting' | 'network' | 'sort';
  currentView?: string;
};
type LogFilterOptionProps = LogFilterProps & {
  oldValue: string;
  newValue: string;
};

export function logCryptoFilterPressed({ type }: LogFilterProps) {
  logEvent('assets.crypto_list.filter.clicked', {
    action: ActionType.view,
    componentType: ComponentType.tray,
    type,
  });
}
export function logCryptoFilterViewed({ type }: LogFilterProps) {
  logEvent('assets.crypto_list.filter_tray.viewed', {
    action: ActionType.view,
    componentType: ComponentType.tray,
    type,
  });
}

export function logCryptoFilterOptionSelected({ type, oldValue, newValue }: LogFilterOptionProps) {
  logEvent('assets.crypto_list.filter_tray.option.selected', {
    action: ActionType.click,
    componentType: ComponentType.button,
    type,
    oldValue,
    newValue,
  });
}

export function useLogCryptoListViewed(hasTestnetAssets?: boolean) {
  return useEventLogger('assets.crypto_list.viewed', {
    loggingId: 'eb9a02ea-6eea-4d25-a197-93954835a547',
    action: ActionType.view,
    componentType: ComponentType.page,
    hasTestnetAssets,
  });
}

export function useCryptoListEmptyViewed() {
  useLogEventOnMount('assets.crypto_list_empty.viewed', {
    loggingId: '2dc728cb-2fde-4a31-99d2-298a1a31edb5',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useLogCryptoListEmptyViewed() {
  return useEventLogger('assets.crypto_list_empty.viewed', {
    loggingId: '2dc728cb-2fde-4a31-99d2-298a1a31edb5',
    action: ActionType.view,
    componentType: ComponentType.page,
  });
}

export function useCryptoAssetClicked() {
  return useEventLogger('assets.wallet.clicked', {
    loggingId: '9e37f3ad-48cd-404d-8c8e-f16d59db4ba8',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useCryptoTabClicked() {
  return useEventLogger('assets.crypto_tab.clicked', {
    loggingId: 'c467c8a3-afb0-45a8-954b-ca8a57ae854a',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function useTransactionsTabClicked() {
  return useEventLogger('wallet.transactions_tab.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsSendClicked() {
  return logEvent('assets.send.clicked', {
    loggingId: 'f2a31d34-21bc-43ae-acd0-33d64412daf2',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsBuyClicked() {
  return logEvent('assets.buy.clicked', {
    loggingId: 'ce630351-2259-4109-8872-4d3feb3d665f',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsReceiveClicked() {
  return logEvent('assets.receive.clicked', {
    loggingId: '50a00ed0-d8ff-4d81-adae-83d320adb76f',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logADPShareClicked(assetCode: string) {
  logEvent('assets.share.pressed', {
    action: ActionType.click,
    componentType: ComponentType.button,
    asset: assetCode,
  });
}

export function logAssetsSwapClicked() {
  return logEvent('assets.swap.clicked', {
    loggingId: 'e6e220db-f9db-4edb-a993-0d9d97e87b7d',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsBridgeClicked() {
  return logEvent('assets.bridge.clicked', {
    loggingId: 'ee098600-989a-4fa5-93d2-d37ae011b20b',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logAssetsScanClicked() {
  return logEvent('assets.scan.clicked', {
    loggingId: '0e46eb81-c480-46c1-b07b-7332d7bc0a8c',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

type ActionButton = 'buy' | 'swap' | 'bridge' | 'send' | 'receive' | 'cashout';
export function logActionButtonError(actionButton: ActionButton) {
  return () =>
    logMetric({
      metricName: 'assets.action_button_error',
      metricType: MetricType.count,
      value: 1,
      tags: {
        actionButton,
      },
    });
}

type TrayManagerErrorParams = {
  errorMessage: string;
};

export function logTrayManagerError({ errorMessage }: TrayManagerErrorParams) {
  return logDataEvent('assets.assets_tray.error', {
    loggingId: '55ca1676-58f5-4d5d-8836-96f427aa9721',
    errorMessage,
  });
}

export type ZeroBalanceEventContext =
  | 'create_wallet_complete'
  | 'initial_balance_load'
  | 'balance_update'
  | 'zero_fiat_balance'
  | 'daily'
  | 'experiment_mounted';

export function logZeroBalance(
  properties: {
    isZeroBalanceWallet?: boolean;
    context?: ZeroBalanceEventContext;
    message?: string;
    lastToCurrentFireDeltaInSecond?: number;
  } = {},
) {
  return logDataEvent('zero_balance', {
    loggingId: '5c378901-5b8f-41c8-8ed2-fd1b68b226c6',
    ...properties,
  });
}

export function logAssetsCashoutClicked({ isWidgetExperience }: { isWidgetExperience: boolean }) {
  return logEvent('assets.cashout.clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    isWidgetExperience,
  });
}

export function logZeroNFTs() {
  return logDataEvent('zero_NFTs', {
    loggingId: 'e9aba9c7-2a66-40d7-b4de-154f4212e495',
  });
}

type BalanceUpdateProperties = Pick<
  EventProperties,
  | 'lastBalanceUpdateTxHash'
  | 'walletId'
  | 'walletGroupId'
  | 'walletsLength'
  | 'blockchain'
  | 'currencyCode'
  | 'networkName'
  | 'chainName'
  | 'chainId'
  | 'tokenType'
  | 'contractAddress'
  | 'isStartBalanceZero'
  | 'isCollectionUpdate'
  | 'cachedBalance'
  | 'refreshedBalance'
  | 'cachedTokenCount'
  | 'refreshedTokenCount'
>;

export function logBalanceUpdated({
  walletId,
  walletGroupId,
  walletsLength,
  blockchain = '',
  currencyCode = '',
  networkName = '',
  chainName = '',
  chainId = '',
  tokenType = '',
  contractAddress = '',
  isStartBalanceZero,
  isCollectionUpdate,
  cachedBalance,
  refreshedBalance,
  cachedTokenCount,
  refreshedTokenCount,
  lastBalanceUpdateTxHash,
}: BalanceUpdateProperties) {
  return logDataEvent('balance_update', {
    loggingId: '51fdab59-4eec-4767-aacb-e112f2770010',
    walletId,
    walletGroupId,
    walletsLength,
    blockchain,
    currencyCode,
    networkName,
    chainName,
    chainId,
    tokenType,
    contractAddress,
    isStartBalanceZero,
    isCollectionUpdate,
    cachedBalance,
    refreshedBalance,
    cachedTokenCount,
    refreshedTokenCount,
    lastBalanceUpdateTxHash,
    action: ActionType.change,
    componentType: ComponentType.table,
  });
}

export function logCryptoBalancesCached({ walletsLength }: Pick<EventProperties, 'walletsLength'>) {
  return logDataEvent('cryptos.balances_cached', {
    loggingId: '1c421225-931e-4cf5-ab0b-0410f389fa67',
    action: ActionType.change,
    componentType: ComponentType.table,
    walletsLength,
  });
}

export function logBalancesHaveSynced({ elapsedTime }: Pick<EventProperties, 'elapsedTime'>) {
  return logDataEvent('assets.balances_synced', {
    loggingId: 'fb2be60d-8dc5-4338-8582-afc32d90773d',
    elapsedTime,
  });
}

export function logCryptoTabAddCryptoClicked() {
  return logEvent('assets.crypto_tab.add_crypto.clicked', {
    loggingId: 'aa1ea6ae-0f27-4d24-993f-153d91122662',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

/**
 * Terms Of Service update
 */

export function useTOSUpdateViewed() {
  useLogEventOnMount('assets.tos_update.viewed', {
    loggingId: 'c2721c0b-2895-44ea-aed3-680007f637a6',
    action: ActionType.view,
    componentType: ComponentType.page,
    tosVersion: 'november_2022',
  });
}

export function logTOSUpdateAcceptClicked() {
  return logEvent('assets.tos_update.accepted', {
    loggingId: '2e8cd43c-6fd9-4e66-96f8-606313b25a09',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSUpdateCloseClicked() {
  return logEvent('assets.tos_update.closed', {
    loggingId: '9c3c8eca-b15f-4e97-9ddb-36a1fe155d0f',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSUpdateCloseTrayClicked() {
  return logEvent('assets.tos_update.closed_tray', {
    loggingId: '862787cd-3ff4-44ed-90e7-02000050859a',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSUpdateSignoutTrayClicked() {
  return logEvent('assets.tos_update.signout_tray', {
    loggingId: '74390424-1cdf-4576-9931-adc2732b967d',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function useTOSBackupSecretViewed() {
  useLogEventOnMount('assets.tos_backup_mnemonic.viewed', {
    loggingId: '10ea11c7-79ad-477c-b8cb-6e6895f908ac',
    action: ActionType.view,
    componentType: ComponentType.page,
    tosVersion: 'november_2022',
  });
}

export function logTOSBackupSecretDoneClicked() {
  return logEvent('assets.tos_backup_mnemonic.done', {
    loggingId: 'e691c276-e08f-4484-bfcc-1029d4513f1f',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSBackupMnemonicCancelClicked() {
  return logEvent('assets.tos_backup_mnemonic.canceled', {
    loggingId: 'fb19d8d4-4759-4f99-8e52-77d381ba37d7',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSBackupMnemonicDeleteClicked() {
  return logEvent('assets.tos_backup_mnemonic.delete', {
    loggingId: '681158cf-c322-4d80-8ae5-18a046aa9eef',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSBackupMnemonicDeleted() {
  return logEvent('assets.tos_backup_mnemonic.deleted', {
    loggingId: '480de8b4-4263-4441-8199-a15d6442f604',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function useTOSSignoutConfirmationViewed() {
  useLogEventOnMount('assets.tos_signout_confirmation.viewed', {
    loggingId: 'c57f60ac-eb6b-4d9d-9fe1-ecf3879476d7',
    action: ActionType.view,
    componentType: ComponentType.page,
    tosVersion: 'november_2022',
  });
}

export function logTOSSignoutConfirmationClicked() {
  return logEvent('assets.tos_signout_confirmation.signout', {
    loggingId: '937651ba-e9d7-4f4b-aafd-2f57a29a12d6',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logTOSSignoutConfirmationBackClicked() {
  return logEvent('assets.tos_signout_confirmation.go_back', {
    loggingId: '6763146a-a16c-498a-afa2-2595be03850a',
    action: ActionType.click,
    componentType: ComponentType.button,
    tosVersion: 'november_2022',
  });
}

export function logGaslessPurchaseEntryButtonClicked() {
  return logEvent('assets.gasless_purchase.entry', {
    loggingId: '37f8c58a-4745-4601-bc65-730cedd9843a',
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

type UsePendingAssetViewedProps = { currencyCode: string };

export function usePendingAssetViewed({ currencyCode }: UsePendingAssetViewedProps) {
  useLogEventOnMount('assets.pending_asset.viewed', {
    loggingId: 'c571716f-d20b-4522-84c7-3d973d6ec961',
    action: ActionType.view,
    componentType: ComponentType.page,
    currencyCode,
  });
}

export function logAnnouncementCardCarouselError() {
  return logEvent('assets.announcement_card_carousel.error', {
    loggingId: '63c5228b-c69b-4354-b6fa-b186f757f3cb',
    action: ActionType.unknown,
    componentType: ComponentType.banner,
  });
}

export function logDuplicateAssetRemoved(removedAssetsCount: number) {
  return logMetric({
    metricName: 'assets.duplicate_removed',
    metricType: MetricType.count,
    value: removedAssetsCount,
  });
}
