import { useCallback, useEffect } from 'react';
import { EthereumWalletConfiguration as ethConfig } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SolanaWalletConfiguration as solConfig } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useOverridableKillSwitch } from 'cb-wallet-data/hooks/KillSwitches/useOverridableKillSwitch';
import { getKillSwitchByPlatform } from 'cb-wallet-data/hooks/KillSwitches/utils/getKillSwitchByPlatform';
import { useIsTestnetsEnabled } from 'cb-wallet-data/hooks/Testnets/useIsTestnetsEnabled';
import { SprigEventTrack } from 'cb-wallet-data/hooks/useSyncIsZeroBalanceWallet';
import { useFirstCreatedAccount } from 'cb-wallet-data/stores/Accounts/hooks/useFirstCreatedAccount';
import { getAddressesForBlockchain } from 'cb-wallet-data/stores/Addresses/database';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { createLastSyncedTxHashLookupFn } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { useGetTxHistoryVersion } from 'cb-wallet-data/stores/Transactions/hooks/useGetTxHistoryVersion';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { WalletGroup } from 'cb-wallet-data/stores/WalletGroups/models/WalletGroup';
import { setIndexesToSync } from 'cb-wallet-data/stores/Wallets/hooks/useZeroBalanceTracking';
import { blockchainIsRefreshedMapAtom } from 'cb-wallet-data/stores/Wallets/state';
import { PERF_MARKS, TX_HISTORY_MARKS } from 'cb-wallet-data/utils/perf-constants';
import { startPerfMark } from 'cb-wallet-data/utils/perfMark';
import { useSetRecoilState } from 'recoil';
import { EthereumAddressConfig, HistoryEventError } from 'wallet-engine-signing/history';

import { ethereumAddressHistoryListener, solanaAddressHistoryListener } from '../listeners';
import { deriveAccountBasedAddressConfigs } from '../utils/deriveAccountBasedAddressConfigs';

import { useUpdateEthAddressHistory } from './useUpdateEthAddressHistory';
import { useUpdateSolAddressHistory } from './useUpdateSolAddressHistory';
import { useUpdateTransactions } from './useUpdateTransactions';

// Gives time for the app to refresh active address balances and update, before
// starting any background refreshing on the inactive addresses.
// Note: Only used if the app passes in an active wallet group to prioritize.
export const ACCOUNT_BASED_INACTIVE_BALANCE_DELAY = 2000;

type UseAccountBasedAddressHistoryProps = {
  sprigEventTrack?: SprigEventTrack;
  activeWalletGroup?: WalletGroup; // Will prioritize registering associated addresses when present
  skipBalanceRefresh?: boolean;
  skipTransactionRefresh?: boolean;
  excludeDefiAssets?: boolean;
};

export function useAccountBasedAddressHistory({
  skipBalanceRefresh,
  skipTransactionRefresh,
  sprigEventTrack,
  activeWalletGroup,
  excludeDefiAssets = false,
}: UseAccountBasedAddressHistoryProps) {
  const walletGroups = useWalletGroups();
  const setBlockchainIsRefreshedMap = useSetRecoilState(blockchainIsRefreshedMapAtom);
  const firstCreatedAccount = useFirstCreatedAccount();

  const isTestnetsEnabled = useIsTestnetsEnabled();

  const updateEthAddressHistory = useUpdateEthAddressHistory({
    sprigEventTrack,
  });
  const updateSolAddressHistory = useUpdateSolAddressHistory(sprigEventTrack);
  const updateTransactions = useUpdateTransactions();
  const getTxHistoryVersion = useGetTxHistoryVersion();

  const isEthereumNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_eth'),
  );
  const isSolanaNudgeKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_balance_nudge_blockchain_sol'),
  );
  const isSolanaDASAPIKilled = useOverridableKillSwitch(
    getKillSwitchByPlatform('kill_das_api_blockchain_sol'),
  );
  const isWebsocketNudgeEnabled = useIsFeatureEnabled('solana_websocket_nudges');

  useEffect(function cleanupAccountBasedAddressHistoryListeners() {
    return function cleanupAddressHistoryListenersOnUmount() {
      ethereumAddressHistoryListener.resetHistoryListener();
      solanaAddressHistoryListener.resetHistoryListener();
    };
  }, []);

  return useCallback(
    async function createAccountBasedAddressHistory() {
      ethereumAddressHistoryListener.addEventListener('balance', updateEthAddressHistory);
      ethereumAddressHistoryListener.addEventListener('transactions', updateTransactions);
      ethereumAddressHistoryListener.addEventListener('error', (ev: HistoryEventError) => {
        cbReportError({
          error: ev.error,
          context: 'address_history_error',
          isHandled: false,
          severity: 'error',
        });
      });

      solanaAddressHistoryListener.addEventListener('balance', updateSolAddressHistory);
      solanaAddressHistoryListener.addEventListener('transactions', updateTransactions);
      solanaAddressHistoryListener.addEventListener('error', (ev: HistoryEventError) => {
        cbReportError({
          error: ev.error,
          context: 'address_history_error',
          isHandled: false,
          severity: 'error',
        });
      });

      // Some addresses included from db may not correspond to user-created wallet groups.
      // Those addresses w/no wallet groups are used for balance scanning (auto wallet group
      // creation) on import, and we don't want to balance fetch for those on app load.
      const [ethAddressesFromDb, solAddressesFromDb] = await Promise.all([
        getAddressesForBlockchain({
          blockchain: ethConfig.blockchain,
        }),
        getAddressesForBlockchain({
          blockchain: solConfig.blockchain,
        }),
      ]);

      // Matches addresses to wallet groups, and separates by active vs inactive.
      // - Active addresses will be prioritized and registered first, with inactive registered after a delay.
      // - If no active wallet group is given, all addresses are active.
      const { ETH: ethAddresses, SOL: solAddresses } = separateAddressesToRegister({
        walletGroups,
        activeWalletGroup, // optional
        ethAddresses: ethAddressesFromDb,
        solAddresses: solAddressesFromDb,
      });

      if (ethAddresses.active.length || solAddresses.active.length) {
        setBlockchainIsRefreshedMap((curr) => ({
          ...curr,
          [ETHEREUM_SYMBOL]: ethAddresses.active.length ? false : curr[ETHEREUM_SYMBOL],
          [SOLANA_SYMBOL]: solAddresses.active.length ? false : curr[SOLANA_SYMBOL],
        }));
      }

      const getLastSyncedTxHashByNetwork = await createLastSyncedTxHashLookupFn();

      // Add addresses for active wallet group, to fetch balance results first.
      if (ethAddresses.active.length) {
        const activeEthAddressConfigs = (
          await Promise.all(
            ethAddresses.active.map(async function deriveEthAddressConfigs(addr) {
              return deriveAccountBasedAddressConfigs({
                blockchainSymbol: ETHEREUM_SYMBOL,
                primaryAddress: addr.address,
                walletIndex: addr.index,
                accountId: addr.accountId,
                isNudgeKilled: isEthereumNudgeKilled,
                isDASAPIKilled: true,
                isWebsocketNudgeKilled: true,
                addressesForBlockchain: ethAddressesFromDb,
                getTxHistoryVersion,
                getLastSyncedTxHashByNetwork,
                networkSelection: isTestnetsEnabled ? 'allNetworks' : 'mainnetsAndCustomNetworks',
                excludeDefiAssets,
              });
            }),
          )
        ).flat();

        ethereumAddressHistoryListener.addAddresses(activeEthAddressConfigs, {
          skipBalanceRefresh,
          skipTransactionRefresh,
        });
        startTxSyncingPerfMarks(activeEthAddressConfigs as EthereumAddressConfig[]);
      }

      if (solAddresses.active.length) {
        const activeSolAddressConfigs = (
          await Promise.all(
            solAddresses.active.map(async function deriveSolAddressConfigs(addr) {
              return deriveAccountBasedAddressConfigs({
                blockchainSymbol: SOLANA_SYMBOL,
                primaryAddress: addr.address,
                walletIndex: addr.index,
                accountId: addr.accountId,
                isNudgeKilled: isSolanaNudgeKilled,
                isDASAPIKilled: isSolanaDASAPIKilled,
                isWebsocketNudgeKilled: !isWebsocketNudgeEnabled,
                addressesForBlockchain: solAddressesFromDb,
                getTxHistoryVersion,
                getLastSyncedTxHashByNetwork,
              });
            }),
          )
        ).flat();

        solanaAddressHistoryListener.addAddresses(activeSolAddressConfigs, {
          skipBalanceRefresh,
          skipTransactionRefresh,
        });
      }

      // Tracking for zero balance logic, which utilizes first account per Product requirement.
      const walletIndexes = walletGroups
        .filter((walletGroup) => walletGroup.accountId === firstCreatedAccount?.id)
        .map((walletGroup) => walletGroup.walletIndex);

      setIndexesToSync('ETH', walletIndexes);
      setIndexesToSync('SOL', walletIndexes);

      // After delay, add inactive addresses, to prioritize fetching active addresses first.
      // If no active wallet group was provided, there won't be any inactive addresses to register.
      if (ethAddresses.inactive.length || solAddresses.inactive.length) {
        setTimeout(async function addInactiveAddresses() {
          if (ethAddresses.inactive.length) {
            const inactiveEthAddressConfigs = (
              await Promise.all(
                ethAddresses.inactive.map(async function deriveEthAddressConfigs(addr) {
                  return deriveAccountBasedAddressConfigs({
                    blockchainSymbol: ETHEREUM_SYMBOL,
                    primaryAddress: addr.address,
                    walletIndex: addr.index,
                    accountId: addr.accountId,
                    isNudgeKilled: isEthereumNudgeKilled,
                    isDASAPIKilled: true,
                    isWebsocketNudgeKilled: true,
                    addressesForBlockchain: ethAddressesFromDb,
                    getTxHistoryVersion,
                    getLastSyncedTxHashByNetwork,
                    networkSelection: isTestnetsEnabled
                      ? 'allNetworks'
                      : 'mainnetsAndCustomNetworks',
                    excludeDefiAssets,
                  });
                }),
              )
            ).flat();

            ethereumAddressHistoryListener.addAddresses(inactiveEthAddressConfigs, {
              skipBalanceRefresh,
              skipTransactionRefresh,
            });
            startTxSyncingPerfMarks(inactiveEthAddressConfigs as EthereumAddressConfig[]);
          }

          if (solAddresses.inactive.length) {
            const inactiveSolAddressConfigs = (
              await Promise.all(
                solAddresses.inactive.map(async function deriveSolAddressConfigs(addr) {
                  return deriveAccountBasedAddressConfigs({
                    blockchainSymbol: SOLANA_SYMBOL,
                    primaryAddress: addr.address,
                    walletIndex: addr.index,
                    accountId: addr.accountId,
                    isNudgeKilled: isSolanaNudgeKilled,
                    isDASAPIKilled: isSolanaDASAPIKilled,
                    isWebsocketNudgeKilled: !isWebsocketNudgeEnabled,
                    addressesForBlockchain: solAddressesFromDb,
                    getTxHistoryVersion,
                    getLastSyncedTxHashByNetwork,
                  });
                }),
              )
            ).flat();

            solanaAddressHistoryListener.addAddresses(inactiveSolAddressConfigs, {
              skipBalanceRefresh,
              skipTransactionRefresh,
            });
          }
        }, ACCOUNT_BASED_INACTIVE_BALANCE_DELAY);
      }
    },
    [
      activeWalletGroup,
      isEthereumNudgeKilled,
      isSolanaNudgeKilled,
      firstCreatedAccount?.id,
      updateEthAddressHistory,
      updateSolAddressHistory,
      setBlockchainIsRefreshedMap,
      walletGroups,
      getTxHistoryVersion,
      updateTransactions,
      isTestnetsEnabled,
      skipBalanceRefresh,
      skipTransactionRefresh,
      isSolanaDASAPIKilled,
      isWebsocketNudgeEnabled,
      excludeDefiAssets,
    ],
  );
}

function startTxSyncingPerfMarks(ethAddressConfigs: EthereumAddressConfig[]) {
  ethAddressConfigs.forEach(function setTxPerfMarks({
    chainId,
    txHistoryVersion,
    lastSyncedTxHash,
  }) {
    const perfMarkName = `${PERF_MARKS.tx_history}_${txHistoryVersion}_${
      lastSyncedTxHash ? TX_HISTORY_MARKS.update_sync : TX_HISTORY_MARKS.full_sync
    }`;

    const chain = Network.fromChainId({ chainId }).asChain();
    const chainName = chain?.displayName ?? '';

    const perfMarkOptions = {
      label: chainName.replaceAll(' ', '_').toLowerCase(),
    };

    const isCustomNetworkOrTestnet =
      chain?.isCustomNetwork || chain?.isTestnet || txHistoryVersion === 'etherscan';

    if (!isCustomNetworkOrTestnet) {
      startPerfMark(perfMarkName, perfMarkOptions);
    }
  });
}

type SeparateAddressesToRegisterParams = {
  walletGroups: WalletGroup[];
  activeWalletGroup?: WalletGroup; // optional
  ethAddresses: Address[];
  solAddresses: Address[];
};

type AddressesToRegister = {
  /**
   * Active addresses - Registered with no delay.
   * - Associated w/active wallet group when given.
   * - All addresses when no active wallet group is given.
   */
  active: Address[];
  /**
   * Inactive addresses - Registered with delay, to prioritize active addresses first.
   * - Only used when active wallet group is given.
   */
  inactive: Address[];
};

type AddressesToRegisterByBlockchain = {
  ETH: AddressesToRegister;
  SOL: AddressesToRegister;
};

/**
 * Given a set of wallet groups and addresses, they'll be matched up and separated
 * by active vs inactive, based on whether an active wallet group was given.
 */
export function separateAddressesToRegister({
  walletGroups,
  activeWalletGroup, // optional
  ethAddresses,
  solAddresses,
}: SeparateAddressesToRegisterParams): AddressesToRegisterByBlockchain {
  return walletGroups.reduce<AddressesToRegisterByBlockchain>(
    function reduceAddressesToRegister(acc, wg) {
      const ethAddrOfWalletGroup = ethAddresses.find(
        (addr) => addr.accountId === wg.accountId && addr.index === wg.walletIndex,
      );

      if (ethAddrOfWalletGroup) {
        // If active wallet group is given, separate active vs inactive addresses.
        // If not, all addresses should be active.
        if (
          !activeWalletGroup ||
          (ethAddrOfWalletGroup.accountId === activeWalletGroup.accountId &&
            ethAddrOfWalletGroup.index === activeWalletGroup.walletIndex)
        ) {
          acc.ETH.active.push(ethAddrOfWalletGroup);
        } else {
          acc.ETH.inactive.push(ethAddrOfWalletGroup);
        }
      }

      const solAddrOfWalletGroup = solAddresses.find(
        (addr) => addr.accountId === wg.accountId && addr.index === wg.walletIndex,
      );

      if (solAddrOfWalletGroup) {
        // If active wallet group is given, separate active vs inactive addresses.
        // If not, all addresses should be active.
        if (
          !activeWalletGroup ||
          (solAddrOfWalletGroup.accountId === activeWalletGroup.accountId &&
            solAddrOfWalletGroup.index === activeWalletGroup.walletIndex)
        ) {
          acc.SOL.active.push(solAddrOfWalletGroup);
        } else {
          acc.SOL.inactive.push(solAddrOfWalletGroup);
        }
      }

      return acc;
    },
    {
      ETH: {
        active: [],
        inactive: [],
      },
      SOL: {
        active: [],
        inactive: [],
      },
    },
  );
}
