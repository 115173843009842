import { useEffect, useMemo, useRef, useState } from 'react';
import { getTransactionsTableSize } from 'cb-wallet-data/stores/Transactions/database';

// The size of the transactions table has historically been correlated to performance
// degradation. This hook surfaces the table size so it can be use on our observability
// platforms like amplitude and datadog.
export function useTransactionsTableSize() {
  const [transactionsTableSize, setTransactionsTableSize] = useState(0);
  const hasFetchedTransactionsTableSize = useRef(false);

  useEffect(
    function setTransactionsTag() {
      async function setTransactionsTagInnerFn() {
        if (!hasFetchedTransactionsTableSize.current) {
          const txTableSize = await getTransactionsTableSize();
          setTransactionsTableSize(txTableSize);
          hasFetchedTransactionsTableSize.current = true;
        }
      }
      setTransactionsTagInnerFn();
    },
    [hasFetchedTransactionsTableSize],
  );

  return useMemo(() => transactionsTableSize, [transactionsTableSize]);
}
