import { useUSMSessionInfo } from 'cb-wallet-data/USM/hooks/useUSMSessionInfo';

export function useIsConnectedToCoinbase() {
  const { isLoading, sessionInfo } = useUSMSessionInfo();

  return {
    isLoading,
    isConnected: isLoading ? null : Boolean(sessionInfo),
  };
}
