import { fromNetworkRawValue as fromEthNetworkRawValue } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import {
  EthereumChain,
  localhostTestnetChainIds,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { NetworkCustomization } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/CustomNetwork';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { fromNetworkRawValue as fromSolNetworkRawValue } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import {
  AllPossibleBlockchainSymbol,
  blockchainConfigurations,
} from 'cb-wallet-data/chains/blockchains';
import { isSCWSupportedNetwork } from 'cb-wallet-data/scw/util/supportedNetworks';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import { Address } from 'cb-wallet-data/stores/Addresses/models/Address';
import { checkNetworkIsReachable } from 'cb-wallet-data/stores/CustomNetworks/checkNetworkIsReachable';
import type { GetLastSyncedTxHashByNetwork } from 'cb-wallet-data/stores/LastSyncedTxHash/utils';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { GetTxHistoryVersion } from 'cb-wallet-data/stores/Transactions/hooks/useGetTxHistoryVersion';
import { getWalletsOfBlockchainRecord } from 'cb-wallet-data/stores/Wallets/database';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { EthereumAddressConfig, SolanaAddressConfig } from 'wallet-engine-signing/history';
import { AddressConfig } from 'wallet-engine-signing/history/historyByChain';

export type NetworkSelection = 'allNetworks' | 'mainnetsAndCustomNetworks' | 'testnetNetworks';

/**
 * For a given blockchain and address string, derive the address configs
 * for address history listener.
 *
 * Although this function doesn't use any wallets or wallet groups directly,
 * practically speaking, it will derive all of the necessary address configs
 * for a given account-based blockchain (i.e. ETH or SOL) of a wallet group.
 */

export async function deriveAccountBasedAddressConfigs({
  blockchainSymbol,
  primaryAddress,
  walletIndex,
  accountId,
  addressesForBlockchain,
  isNudgeKilled,
  isWebsocketNudgeKilled,
  isDASAPIKilled,
  getTxHistoryVersion,
  getLastSyncedTxHashByNetwork,
  networkSelection,
  excludeDefiAssets = false,
}: {
  blockchainSymbol: 'ETH' | 'SOL';
  primaryAddress: string;
  walletIndex: bigint;
  accountId: Account['id'];
  addressesForBlockchain: Address[];
  isNudgeKilled: boolean;
  isWebsocketNudgeKilled: boolean;
  isDASAPIKilled: boolean;
  getTxHistoryVersion: GetTxHistoryVersion;
  getLastSyncedTxHashByNetwork: GetLastSyncedTxHashByNetwork;
  networkSelection?: NetworkSelection;
  excludeDefiAssets?: boolean;
}) {
  const config = blockchainConfigurations[blockchainSymbol];
  let networkSettings = config.networkSetting[networkSelection || 'allNetworks'];

  // SCW accounts use a subset of ETH chains, see getSCWSupportedChainIds.
  if (Account.isSCWAccount(accountId)) {
    networkSettings = networkSettings.filter(({ network }) => isSCWSupportedNetwork(network));
  }

  const addressConfigs: AddressConfig[] = [];
  const addressesByNetwork = addressesForBlockchain.reduce(function sortAddressesByNetwork(
    acc,
    address,
  ) {
    const network = address.network;
    const networkAddresses = acc[network.rawValue] ?? [];
    networkAddresses.push(address);
    acc[network.rawValue] = networkAddresses;
    return acc;
  },
  {} as Record<string, Address[]>);

  await Promise.all(
    networkSettings.map(async function reduceAddressConfigs(networkSetting) {
      const network = networkSetting.network;
      const fullNetworkConfig = getNetworkConfig({ blockchainSymbol, network });
      const chainId = BigInt(fullNetworkConfig?.chainId ?? 0n);

      if (fullNetworkConfig) {
        const isNudgeEnabled = Boolean(!isNudgeKilled && fullNetworkConfig.isNudgeSupported);
        const isDASAPIEnabled = Boolean(!isDASAPIKilled);
        const isWebsocketNudgeEnabled = Boolean(!isWebsocketNudgeKilled);
        const isLocalhost = localhostTestnetChainIds.includes(fullNetworkConfig.chainId);

        // Wallet ID may not exist yet, until we find balance results.
        // Passed as context for handling refresh results.
        const walletId = Wallet.generateId({
          blockchain: config.blockchain,
          currencyCode: config.currencyCodeForNetwork(network),
          network,
          selectedIndex: walletIndex,
          accountId,
        });

        if (fullNetworkConfig.isCustomNetwork) {
          const walletsForBlockchain = await getWalletsOfBlockchainRecord(blockchainSymbol);
          const existingWallet = walletsForBlockchain[walletId];
          // When a custom network is created the wallet record is created alongside.
          // If it doesn't exist then don't try to create an address config for it.
          if (!existingWallet) {
            return;
          }
        }

        const { apiVersion, isEnabled: isTxHistoryServiceEnabled } = getTxHistoryVersion(
          network.asChain(),
        );

        const txHistoryVersion = isTxHistoryServiceEnabled && apiVersion ? apiVersion : 'etherscan';
        const latestTxHashForNetwork = getLastSyncedTxHashByNetwork({
          blockchain: config.blockchain,
          accountId,
          walletIndex,
          network,
        });

        type AddressConfig = Partial<EthereumAddressConfig | SolanaAddressConfig> & {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          blockchainSymbol: any;
        };

        // TODO need to split up ethereum and solana address config creation
        const addressConfig: AddressConfig = {
          address: primaryAddress,
          blockchainSymbol,
          isNudgeEnabled,
          chainId,
          baseAssetCurrencyCode: fullNetworkConfig.baseAssetCurrencyCode!,
          rpcUrl: fullNetworkConfig.rpcUrl,
          context: { walletId, accountId, walletIndex },
          wacNetworkId: fullNetworkConfig.wacNetworkId,
          txHistoryVersion,
          lastSyncedTxHash: latestTxHashForNetwork?.txHash ?? undefined,
          excludeDefiAssets,
        };

        if (blockchainSymbol === 'ETH') {
          (addressConfig as EthereumAddressConfig).etherscanLikeApiKey = (
            fullNetworkConfig as EthereumChain
          ).etherscanLikeApiKey;
          (addressConfig as EthereumAddressConfig).etherscanCompatibleTxHistoryApi = (
            fullNetworkConfig as EthereumChain
          ).etherscanCompatibleTxHistoryApi;
          (addressConfig as EthereumAddressConfig).chainProxyTarget =
            (network.asChain() as EthereumChain)?.chainProxyTarget ?? '';

          if (network.asChain()?.isCustomNetwork) {
            const addressesForNetwork = addressesByNetwork[network.rawValue] ?? [];

            const importedTokenContractAddresses = addressesForNetwork
              .filter(
                ({ contractAddress, index, accountId: addressAccountId }) =>
                  Boolean(contractAddress) &&
                  index === walletIndex &&
                  addressAccountId === accountId,
              )
              .map(({ contractAddress }) => contractAddress) as string[];

            (addressConfig as EthereumAddressConfig).erc20ContractAddresses =
              importedTokenContractAddresses;
            (addressConfig as EthereumAddressConfig).txHistoryVersion = 'etherscan';
          }
        }

        if (blockchainSymbol === 'SOL') {
          (addressConfig as SolanaAddressConfig).isWebsocketNudgeEnabled = isWebsocketNudgeEnabled;
          (addressConfig as SolanaAddressConfig).isDASAPIEnabled = isDASAPIEnabled;
          (addressConfig as SolanaAddressConfig).txHistoryVersion = 'v3';
        }

        // Only subscribe localhost network if hardhat server is running
        if (isLocalhost) {
          try {
            const isReachable = await checkNetworkIsReachable({
              rpcUrls: [fullNetworkConfig.rpcUrl],
            } as NetworkCustomization);
            if (!isReachable) {
              return;
            }
          } catch (e) {
            return;
          }
        }

        addressConfigs.push(addressConfig as EthereumAddressConfig | SolanaAddressConfig);
      }
    }),
  );

  return addressConfigs;
}

export function getNetworkConfig({
  blockchainSymbol,
  network,
}: {
  blockchainSymbol: AllPossibleBlockchainSymbol;
  network: Network;
}) {
  switch (blockchainSymbol) {
    case SOLANA_SYMBOL:
      return fromSolNetworkRawValue(network.rawValue);
    case ETHEREUM_SYMBOL:
      return fromEthNetworkRawValue(network.rawValue);
    default:
      return undefined;
  }
}
