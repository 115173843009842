import { useEffect, useMemo } from 'react';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import {
  MIAMI_THEME_COLORS,
  miamiThemeColorConfigs,
} from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { useWalletAvatarsPreference } from 'cb-wallet-data/stores/User/hooks/useWalletAvatarsPreference';
import { AvatarMap } from 'wallet-cds-web/assets/ProfileAvatarSVGs/Avatars';
import { paletteValueToRgbaString } from '@cbhq/cds-common/palette/paletteValueToRgbaString';
import { AvatarPixelSize, AvatarSize, PaletteBorder, useSpectrum } from '@cbhq/cds-web';
import { Box } from '@cbhq/cds-web/layout';

type AvatarScaleMapType = Record<AvatarSize, AvatarPixelSize>;
export const AvatarSizeMap: AvatarScaleMapType = {
  xxxl: 56,
  xxl: 48,
  xl: 40,
  l: 32,
  m: 24,
  s: 16,
};

type AvatarProps = {
  size?: AvatarSize;
  address: string | undefined;
  profileAvatarAddress?: Account['primaryAddress'];
  alt?: string;
  profileAvatarSize?: AvatarSize;
  borderColor?: PaletteBorder;
  testID?: string;
  avatar?: string;
  initialAvatarColorIndex?: number;
};

export function AddressAvatar({
  size = 'l',
  address = '',
  initialAvatarColorIndex = -1,
}: AvatarProps) {
  const sizeNum = AvatarSizeMap[size];
  const spectrum = useSpectrum();
  const [walletAvatars, setWalletAvatars] = useWalletAvatarsPreference();

  const addressInLowerCase = address?.toLowerCase();

  useEffect(
    function assignRandomAvatar() {
      // if the wallet address is not found in the map, set it to a random avatar
      // Also assign a random avatar only if user is part of the experiment
      if (walletAvatars && !walletAvatars[addressInLowerCase]) {
        setWalletAvatars((prev) => ({
          ...prev,
          [addressInLowerCase]: {
            avatarIndex: Math.floor(Math.random() * 56),
            avatarBackgroundColor:
              // if the initialAvatarColorIndex is not provided, set it to a random color
              MIAMI_THEME_COLORS[
                initialAvatarColorIndex === -1
                  ? Math.floor(Math.random() * MIAMI_THEME_COLORS.length)
                  : initialAvatarColorIndex
              ],
          },
        }));
      }
    },
    [setWalletAvatars, address, walletAvatars, addressInLowerCase, initialAvatarColorIndex],
  );

  const avatarIndex = walletAvatars[address?.toLowerCase()]?.avatarIndex ?? 0;
  const avatarColor = walletAvatars[address?.toLowerCase()]?.avatarBackgroundColor ?? 'blue';

  const avatarBackground = useMemo(() => {
    const value = miamiThemeColorConfigs[avatarColor].primary;

    return paletteValueToRgbaString(value, spectrum);
  }, [avatarColor, spectrum]);

  const Avatar = AvatarMap[avatarIndex as keyof typeof AvatarMap];

  return (
    <Box
      testID="wallet-avatar"
      borderRadius="roundedFull"
      background="secondary"
      dangerouslySetBackground={avatarBackground}
    >
      <Avatar size={sizeNum} key={avatarIndex} />
    </Box>
  );
}
