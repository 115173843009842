import {
  CombinedThemeColorPreference,
  ThemeColorPreference,
} from 'cb-wallet-data/stores/ThemeColors/themeColorConfigs';
import { atomLocalStorageEffect } from 'cb-wallet-data/utils/atomLocalStorageEffect';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { atom } from 'recoil';

import { themeColorDefault } from './hooks/useThemeColor';
import { AppearanceSpectrum } from './types/AppearanceSpectrum';

export const StoreKeys_privacyMode = new LocalStorageStoreKey<string>('privacyMode');
export const StoreKeys_appearance = new LocalStorageStoreKey<AppearanceSpectrum>('appearance');
export const StoreKeys_themeColor = new LocalStorageStoreKey<ThemeColorPreference>(
  'rootThemeColorPreference',
);
export const StoreKeys_viewedTOSUpdate = new LocalStorageStoreKey<boolean>('viewedTOSUpdate');

export const privacyModeAtom = atom({
  key: 'privacyMode',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_privacyMode)],
});

export const StoreKeys_hasSentUserActivatedEvent = new LocalStorageStoreKey<boolean>(
  'hasSentUserActivatedEvent',
);

export const hasSentUserActivatedEventAtom = atom({
  key: 'hasSentUserActivatedEvent',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_hasSentUserActivatedEvent)],
});

export const appearanceModeAtom = atom<AppearanceSpectrum>({
  key: 'appearance',
  default: Store.get<AppearanceSpectrum>(StoreKeys_appearance) ?? 'dark',
  effects: [atomLocalStorageEffect(StoreKeys_appearance)],
});

export const themeColorAtom = atom<CombinedThemeColorPreference>({
  key: 'themeColor',
  default: Store.get<CombinedThemeColorPreference>(StoreKeys_themeColor) ?? themeColorDefault,
  effects: [atomLocalStorageEffect(StoreKeys_themeColor)],
});

export const viewedTOSUpdateAtom = atom<boolean>({
  key: 'viewedTOSUpdate',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_viewedTOSUpdate)],
});

export const StoreKeys_areSmallBalanceWalletsHidden = new LocalStorageStoreKey<boolean>(
  'StoreKeys_areSmallBalanceWalletsHidden',
);

export const areSmallBalanceWalletsHiddenAtom = atom<boolean>({
  key: 'areSmallBalanceWalletsHidden',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_areSmallBalanceWalletsHidden)],
});

export const StoreKeys_areNoQuoteAssetsHiddenExperimentAtom = new LocalStorageStoreKey<boolean>(
  'StoreKeys_areNoQuoteAssetsHiddenExperimentAtom',
);

export const areNoQuoteAssetsHiddenExperimentAtom = atom<boolean>({
  key: 'areNoQuoteAssetsHiddenExperiment',
  default: false,
  effects: [atomLocalStorageEffect(StoreKeys_areNoQuoteAssetsHiddenExperimentAtom)],
});

export const StoreKeys_permissionsPromptDismissDate = new LocalStorageStoreKey<Date | undefined>(
  'permissionsPromptDismissDate',
);

export const permissionsPromptDismissDateAtom = atom<Date | undefined>({
  key: 'permissionsPromptDismissDateAtom',
  default: undefined,
  effects: [atomLocalStorageEffect(StoreKeys_permissionsPromptDismissDate)],
});

export const StoreKeys_areStickyTabsEnabled = new LocalStorageStoreKey<boolean>(
  'StoreKeys_areStickyTabsEnabled',
);

export const areStickyTabsEnabledAtom = atom<boolean>({
  key: 'areStickyTabsEnabled',
  default: true,
  effects: [atomLocalStorageEffect(StoreKeys_areStickyTabsEnabled)],
});

export const StoreKeys_lastTabPressed = new LocalStorageStoreKey<string>(
  'StoreKeys_lastTabPressed',
);

export const lastTabPressedAtom = atom<string>({
  key: 'lastTabPressed',
  default: 'HomeTab',
  effects: [atomLocalStorageEffect(StoreKeys_lastTabPressed)],
});

export type LocationDebugOverrides = {
  countryCode?: string;
  subdivisionCode?: string;
  isLocationOverrideEnabled?: boolean;
};

const StoreKeys_locationDebugOverrides = new LocalStorageStoreKey<LocationDebugOverrides>(
  'locationDebugOverrides',
);

export const locationDebugOverridesAtom = atom<LocationDebugOverrides>({
  key: 'locationDebugOverridesAtom',
  default: {
    countryCode: '',
    subdivisionCode: '',
    isLocationOverrideEnabled: false,
  },
  effects: [atomLocalStorageEffect(StoreKeys_locationDebugOverrides)],
});
