import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

import { ReceiveStepFallback } from ':dapp/components/Receive/ReceiveStepFallback';

import { useGlobalDrawer } from '../GlobalDrawer/GlobalDrawerProvider';

import { SendStepFallback } from './SendStepFallback';

const SendFlow = dynamic(async () => import('./SendFlow').then((mod) => mod.SendFlow), {
  loading: SendStepFallback,
  ssr: false,
});

const ReceiveFlow = dynamic(
  async () => import(':dapp/components/Receive/ReceiveFlow').then((mod) => mod.ReceiveFlow),
  {
    loading: ReceiveStepFallback,
    ssr: false,
  },
);

export type SendReceiveStep = 'send' | 'receive';

export function SendAndReceiveDrawer() {
  // TODO: pass query params to send flow to initialize the flow
  const { closeDrawer, options } = useGlobalDrawer();
  const [stepName, setStepName] = useState<SendReceiveStep>(options?.step ?? 'send');

  /* istanbul ignore next */
  const handleStepSwitch = useCallback(() => {
    setStepName((prev) => (prev === 'send' ? 'receive' : 'send'));
  }, []);

  if (stepName === 'receive') {
    return <ReceiveFlow onClose={closeDrawer} onSwitch={handleStepSwitch} />;
  }

  return <SendFlow onClose={closeDrawer} onSwitch={handleStepSwitch} />;
}
