/* eslint-disable no-console */
// Wraps bugsnag notify (or any error reporting system) into a ReportError fn

import { ErrorSeverity, MiscMetadata } from '@cbhq/error-vitals-web';

import { getErrorHash } from './getErrorHash';

export function createErrorReporter(notify: NotifyFunction): ReportErrorFn {
  return (passedErrorData: ErrorData): ReportedErrorInfo => {
    // Guard against errorData being the wrong type (catch statements have an "any")
    let errorData: ErrorData;
    if (passedErrorData instanceof Error) {
      errorData = {
        error: passedErrorData,
        context: 'unknown',
        severity: 'error',
        isHandled: false,
      };

      console.warn(
        CONSOLE_PREFIX,
        CONSOLE_STYLE,
        'reportError called incorrectly (reportError(error) rather than reportError({error}))',
      );
    } else if (typeof passedErrorData === 'object') {
      errorData = passedErrorData;
      if (!(errorData.error instanceof Error)) {
        console.warn(
          CONSOLE_PREFIX,
          CONSOLE_STYLE,
          'reportError called incorrectly with something other than an error object',
          passedErrorData,
        );

        errorData.metadata = {
          ...errorData.metadata,
          originalErrorData: JSON.stringify(errorData.error),
        };
        errorData.error = new Error('Unspecified error.');
      }
    } else {
      errorData = {
        error: new Error(`Unspecified error.`),
        context: 'unknown',
        severity: 'error',
        metadata: {
          originalErrorData: JSON.stringify(passedErrorData),
        },
        isHandled: false,
      };

      console.warn(CONSOLE_PREFIX, CONSOLE_STYLE, 'reportError called incorrectly');
    }

    const { error } = errorData;

    const errorHash = getErrorHash(error);
    if (shouldReport(error)) notify(errorData, errorHash);

    return { errorHash };
  };
}

export function shouldReport(error: Error | (Error & { reportError: false })): boolean {
  return !('reportError' in error) || error.reportError;
}

export type ReportedErrorInfo = {
  errorHash?: string;
};

// Context for dapp errors to be added here
export type DappErrorContext =
  | 'dapp_error'
  | 'dapp-csp-violation'
  | 'dapp-load'
  | 'dapp-account-creation'
  | 'wallet-connection-error'
  | 'web3-provider-connection'
  | 'ensure-providers-current-chain'
  | 'wallet-disconnect-error'
  | 'dapp-provider-account-deletion'
  | 'kill-switch'
  | 'dapp-ocs-error';

// These are explicitly typed because we send context as a tag to datadog, and
// we need to ensure the cardinality of the tag does not explode
export type ErrorContext =
  | 'global_error'
  | 'offline_global_error'
  | 'app_error'
  | 'deeplink_handler_error'
  | 'bridge_deeplink_error'
  | 'route_error'
  | 'offline_route_error'
  | 'component_error'
  | 'address_history_error'
  | 'rx_error'
  | 'analytics'
  | 'http_error'
  | 'escape-hatch-error'
  | 'ledger-error'
  | 'core-vitals'
  | 'solana-error'
  | 'query_cache_error'
  | 'query_persist_error'
  | 'notifications-error'
  | 'wallet-notifications-error'
  | 'authentication-error'
  | 'kill-switches'
  | 'storage'
  | 'legacy_upgrade_failure'
  | 'utxo_balance_scanning'
  | 'utxo_address_creation'
  | 'transaction_history'
  | 'scanTx_http_error'
  | 'onboarding-fullscreen-error'
  | 'coinbase_connect'
  | 'is_bip32_wallet'
  | 'getCryptoCurrencies_http_error'
  | 'rn_prefetch_img'
  | 'dataMigrationRunner'
  | 'dataMigrationRunner.migrateResult'
  | 'add-wallet'
  | 'usdc_reward'
  | 'did_error'
  | 'user_profiles'
  | 'offboarding'
  // TODO: TODO (rafael-camara): It should be replaced in favor of "transactions"
  | 'sign_and_submit_transaction_error'
  | 'sign_and_relay_error'
  | 'mnemonic_error'
  | 'mnemonic_migration_error'
  | 'gas_fee_error'
  // TODO (rafael-camara): It should be replaced in favor of "bridge"
  | 'bridge-error'
  /**
   * AK Surfaces
   */
  | 'multi-account'
  | 'app-load'
  | 'instant-onboarding'
  | 'accounts-datalayer'
  | 'onboarding'
  /** end */
  // TODO (rafael-camara): It should be replaced in favor of "swap"
  | 'swap-error'
  | 'experimentation'
  | 'assets'
  | 'assetdetail'
  | 'buy'
  | 'ethereum'
  | 'stellar'
  | 'ripple'
  | 'data_layer'
  | 'networks'
  | 'stake'
  | 'transactions'
  | 'sign-message'
  | 'transactiondetail'
  | 'wallets'
  | 'swap'
  | 'bridge'
  | 'send'
  | 'service_worker'
  | 'unknown'
  | 'connect_dapp_modal'
  | 'utxo_transaction_history'
  | 'utxo_balance_history_event'
  | 'swap_adjustable_slippage'
  | 'nft_accept_bid'
  | 'asset_http_error'
  | 'exchange_rates'
  | 'secret'
  | 'blockheight_syncing'
  | 'receive'
  | 'messaging'
  | 'sponsored_tx'
  | 'screenshot'
  | 'nft_buy'
  | 'wallet_engine'
  | 'collectible_asset_audio_player'
  | 'gasless_swap_tx'
  | 'walletlink'
  | 'announcements'
  | 'onramp'
  | 'onramp_deeplink'
  | 'onramp_tx_submit'
  | 'onramp_cbpay'
  | 'onramp_payment_methods'
  | '2fa'
  | 'account_backup'
  | 'performance-vitals'
  | 'navigation'
  | DappErrorContext
  | 'mnemonic_account_deletion'
  | 'delete_associated_account_db_records'
  | 'delete_associated_networks_db_records'
  | 'hydrate_associated_account_db_records'
  | 'explore'
  | 'translations'
  | 'referral_program'
  | 'web3-provider-connection'
  | 'tracing'
  | 'nfc_content'
  | 'tracing'
  | 'quests'
  | 'feature-management'
  // SCW Specific Error Contexts
  | 'scw-error'
  | 'defi-portfolio'
  | 'global_search'
  // Cashout Specific
  | 'cashout'
  // Global Tray Provider
  | 'global-overlay-provider'
  // web3 specific
  | 'web3-dialogs'
  | 'mint'
  | 'create_nft'
  | 'edit_nft'
  // slice specific
  | 'slice_query'
  | 'wallet_card'
  | 'transaction_confirmation';

export type ErrorData = {
  error: Error;
  stack?: string;
  errorInfo?: React.ErrorInfo;
  metadata?: MiscMetadata;
  context: ErrorContext;
  severity: ErrorSeverity;
  isHandled: boolean;
  isUserVisible?: boolean;
};

export type NotifyFunction = (errorData: ErrorData, errorHash: string) => void;
export type ReportErrorFn = (error: ErrorData) => ReportedErrorInfo;

function getConsoleMethod(severity: ErrorSeverity) {
  switch (severity) {
    case 'debug':
      return console.debug;
    case 'warning':
      return console.warn;
    default:
      return console.error;
  }
}

export const CONSOLE_PREFIX = `%c Error Reporting:`;
export const CONSOLE_STYLE = `color:red;`;

export function consoleNotify(errorData: ErrorData) {
  const { severity } = errorData;
  const consoleLevel = getConsoleMethod(severity);

  // eslint-disable-next-line no-process-env
  const targetEnv = process.env.TARGET_ENV;
  if (!targetEnv || targetEnv === 'local') {
    consoleLevel(CONSOLE_PREFIX, CONSOLE_STYLE, errorData);
  } else {
    // eslint-disable-next-line no-param-reassign
    errorData.stack = errorData.error.stack;
    consoleLevel(JSON.stringify(errorData));
  }
}

let errorHandler = createErrorReporter(consoleNotify);

/**
 * Consumers of libs/data must call setErrorHandler to supply a function which is expected to report errors to e.g. Bugsnag.
 *
 * @param newErrorHandler The function to be called in order to report an error.
 */
export function setErrorHandler(newErrorHandler: ReportErrorFn): void {
  errorHandler = newErrorHandler;
}

const FCM_WARNINGS = [
  'MISSING_INSTANCEID_SERVICE',
  'SERVICE_NOT_AVAILABLE',
  'TOO_MANY_REGISTRATIONS',
  'AUTHENTICATION_FAILED',
  'PHONE_REGISTRATION_ERROR',
];

export function cbReportError(args: ErrorData): ReportedErrorInfo {
  try {
    if (FCM_WARNINGS.some((errorStr: string) => args.error?.message?.includes(errorStr))) {
      return errorHandler({ ...args, severity: 'warning' });
    }
    return errorHandler(args);
  } catch (error) {
    console.error(CONSOLE_PREFIX, CONSOLE_STYLE, 'Error reporting error', error);
    return {};
  }
}

export function reportComponentError(args: Omit<ErrorData, 'context'>) {
  return cbReportError({
    ...args,
    context: 'component_error',
  });
}

export type ErrorWithCause = Error & {
  cause: unknown;
};

export function coerceError(e: Error | unknown, description: string): Error | ErrorWithCause {
  let err;
  if (e instanceof Error) {
    err = e;
  } else {
    err = new Error(`${description} threw non-error`);
    (err as ErrorWithCause).cause = e;
  }
  return err;
}

export function coerceErrorOrUndefined(
  e: Error | unknown,
  description: string,
): Error | ErrorWithCause | undefined {
  if (e === undefined) return undefined;
  return coerceError(e, description);
}
