import { useCallback } from 'react';
import { triggerConnectedWalletsMenuCopyClick } from 'cb-wallet-analytics/wallet-connection';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { useWithFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useWithFeatureEnabled';
import { useBasicPublicProfileForAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/useBasicPublicProfileForAddresses';
import { hasCookie } from 'cookies-next';
import Decimal from 'decimal.js';
import Image from 'next/image';
import { FormattedAddressWithDomain } from 'wallet-cds-web/components/FormattedAddressWithDomain';
import { COOKIE_HAS_L1_WARNING_SEEN } from 'wallet-cds-web/components/L1EthWarningScreen/hooks/useSetL1WarningCookie';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { useClipboard } from 'wallet-cds-web/hooks/useClipboard';
import { useScaleConditional } from '@cbhq/cds-common/scale/useScaleConditional';
import { listHeight } from '@cbhq/cds-common/tokens/cell';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { Cell } from '@cbhq/cds-web/cells/Cell';
import { CellDetail } from '@cbhq/cds-web/cells/CellDetail';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

import { useWalletProvidersMap } from ':dapp/connection/hooks/useWalletProviders';
import { COINBASE_SMART_WALLET_ID } from ':dapp/connection/wallet-providers/coinbase-wallet';

type ConnectedWalletsMenuRowProps = {
  address: string;
  balance: Decimal;
  provider: string;
  addressChain: string;
  domain?: string;
  avatar?: string;
  copyEnabled?: boolean;
  hideCopy?: boolean;
  onShowL1WarningModal?: (address: string) => void;
};

export function ConnectedWalletsMenuRow({
  address,
  balance,
  addressChain,
  provider,
  hideCopy,
  onShowL1WarningModal,
  copyEnabled = true,
}: ConnectedWalletsMenuRowProps) {
  const { fiatValueString } = useCurrencyFormatter();
  const copy = useClipboard();

  const minHeight = useScaleConditional(listHeight);

  const walletProviderMap = useWalletProvidersMap();
  const walletProvider = walletProviderMap[provider];
  const showL1WarningModal =
    walletProvider?.id === COINBASE_SMART_WALLET_ID && !hasCookie(COOKIE_HAS_L1_WARNING_SEEN);
  const handleOnL1WarningModalOpen = useCallback(() => {
    onShowL1WarningModal?.(address);
  }, [address, onShowL1WarningModal]);

  const handleAddressCopy = useCallback(() => {
    copy(address);
    triggerConnectedWalletsMenuCopyClick({
      walletProviderID: walletProvider?.id,
      walletProviderName: walletProvider?.name,
    });
  }, [address, copy, walletProvider]);
  const basicPublicProfile = useBasicPublicProfileForAddresses([address]);
  const pfpAvatar = useWithFeatureEnabled(basicPublicProfile[address].avatar, 'wallet_pfp');

  return (
    <Cell
      detail={<CellDetail detail={fiatValueString(balance)} subdetail={addressChain} />}
      media={<ProfileAvatar size="l" alt={address} address={address} avatar={pfpAvatar} />}
      minHeight={minHeight}
      priority="end"
      accessory={
        !hideCopy ? (
          <IconButton
            transparent
            testID={`connected-wallets-menu-row-${address}`}
            name="copy"
            onPress={showL1WarningModal ? handleOnL1WarningModalOpen : handleAddressCopy}
            disabled={!copyEnabled}
          />
        ) : undefined
      }
    >
      <VStack>
        <TextHeadline as="div" overflow="truncate">
          <FormattedAddressWithDomain address={address} />
        </TextHeadline>
        {walletProvider && (
          <TextBody
            as="div"
            color="foregroundMuted"
            overflow="truncate"
            testID="connected-wallet-provider-info"
          >
            <Box alignItems="center">
              <Image src={walletProvider.image} alt={walletProvider.name} width={16} height={16} />
              <TextBody spacingStart={1} color="foregroundMuted" as="span">
                {walletProvider.name}
              </TextBody>
            </Box>
          </TextBody>
        )}
      </VStack>
    </Cell>
  );
}
