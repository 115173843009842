export class CurrencyCode {
  readonly code: string;
  readonly rawValue: string;

  constructor(code: string) {
    this.code = code.toUpperCase();
    this.rawValue = this.code;
  }

  static isEqual(c1: CurrencyCode, c2: CurrencyCode): boolean {
    return c1.rawValue === c2.rawValue;
  }

  get value(): string {
    return this.rawValue;
  }

  static USD = new CurrencyCode('USD');

  static EUR = new CurrencyCode('EUR');

  static CNY = new CurrencyCode('CNY');

  static GBP = new CurrencyCode('GBP');

  static CAD = new CurrencyCode('CAD');

  static USDC = new CurrencyCode('USDC');

  static EURC = new CurrencyCode('EURC');

  static USDT = new CurrencyCode('USDT');

  static RUB = new CurrencyCode('RUB');

  static BTC = new CurrencyCode('BTC');

  static ETH = new CurrencyCode('ETH');

  static WETH = new CurrencyCode('WETH');

  static CETH = new CurrencyCode('CETH');

  static WBTC = new CurrencyCode('WBTC');

  static CWBTC = new CurrencyCode('CWBTC');

  static MATIC = new CurrencyCode('MATIC');
  static POL = new CurrencyCode('POL');

  static SOL = new CurrencyCode('SOL');

  static XDAI = new CurrencyCode('XDAI');

  static AVAX = new CurrencyCode('AVAX');

  static RETH = new CurrencyCode('RETH');
}
